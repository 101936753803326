import { TInvoiceFormLineItem, TInvoicesValues } from "@inv/types";
import dayjs from "dayjs";
import { GQL } from "@binale-tech/shared";

export const emptyLineItem: TInvoiceFormLineItem = { productsServices: "", quantity: 1, price: 0, tax: 0, discount: 0 };
export const initialValues: TInvoicesValues = {
    id: null,
    contactId: null,
    countryCode: null,
    city: null,
    address: null,
    zipCode: null,
    street: null,
    invoiceNumber: null,
    customerNumber: null,
    date: dayjs().format("DD.MM.YYYY"),
    documentTitle: null,
    introductionText: null,
    deliveryDate: null,
    serviceDate: null,
    servicePeriodDays: null,
    servicePeriodMonths: null,
    currencyCode: GQL.ICurrencyCode.Eur,
    isTaxIncluded: true,
    lineItems: [{ ...emptyLineItem }],
    paymentTerm: null,
    description: null,
};
