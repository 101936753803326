import React, { FC, memo, useContext } from "react";
import { InvoiceForm } from "@inv/components/InvoiceForm";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { useParams } from "react-router-dom";

const Module: FC = () => {
    const { id } = useParams();
    const { invoicesMap } = useContext(InvoicesDataContext);

    const initialValue = invoicesMap.get(id);

    if (!initialValue) {
        return null;
    }

    return <InvoiceForm initialValues={initialValue} />;
};

export default memo(Module);
