import React, { type FC, useContext, useMemo } from "react";
import { IHeaderParams } from "ag-grid-community";
import { ViewColumnsControl } from "@inv/components/ViewColumnsControl";
import {
    InvoiceTableContext,
    InvoiceTableControlContext,
} from "@inv/modules/InvoiceTableModule/context/InvoiceTableProvider";
import { InvoiceFormColumnsConfig } from "@inv/modules/InvoiceTableModule/config/invoiceFormColumnsConfig";
import { InvoiceColumnsTranslate } from "./constants";
import { InvoiceColumns, TInvoicesValues } from "@inv/types";
import { Form } from "antd";

export const SettingsColumns: FC<IHeaderParams> = () => {
    const { columnsConfig, isOpenSettingsCol } = useContext(InvoiceTableContext);
    const { setColumnsConfig, setIsOpenSettingsCol } = useContext(InvoiceTableControlContext);
    const form = Form.useFormInstance<TInvoicesValues>();
    const isTaxIncluded = Form.useWatch(["isTaxIncluded"], form);

    const requiredTableCols = useMemo(() => {
        return isTaxIncluded
            ? InvoiceFormColumnsConfig.RequiredTableCols
            : [...InvoiceFormColumnsConfig.RequiredTableCols, InvoiceColumns.TAX];
    }, [isTaxIncluded]);

    return (
        <ViewColumnsControl
            columnsConfig={columnsConfig}
            setColumnsConfig={setColumnsConfig}
            columnTranslations={InvoiceColumnsTranslate}
            setIsOpenSettingsCol={setIsOpenSettingsCol}
            isOpenSettingsCol={isOpenSettingsCol}
            requiredColumns={requiredTableCols}
        />
    );
};
