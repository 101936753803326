import { Container } from "@app/components/shared/appearance/page";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import React, { FC, useContext, useMemo, useState } from "react";
import { GenericRecord } from "../../../../scripts/models";
import styles from "@dms/modules/DocumentTableModule/DocumentTable.module.scss";
import { GridOptions, ICellRendererParams, IHeaderParams } from "ag-grid-community";
import type { ColDef, ColGroupDef } from "ag-grid-community/dist/types/core/entities/colDef";
import { GenericRecordColumns } from "@app/columns/ColumnConfig";
import { GenericRecordProperties, TableProperties } from "../../../../scripts/core/Product";
import { ExportPreprocessor } from "../../../../scripts/exporters/ExportPreprocessor";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { FormattedMessage } from "react-intl";
import { Button, Flex, Popconfirm } from "antd";
import { CaretDownOutlined, CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { baseOptions } from "@app/components/shared/AgGridTable/options/baseOptions";
import TableUtils from "../../../../scripts/core/TableUtils";

type CellActionsProps = { onEdit: () => void; onDelete: () => void };

type Props = {
    templates: GenericRecord[];
    actions?: {
        onEdit: (v: GenericRecord) => void;
        onDelete: (v: GenericRecord) => void;
    };
    onSelect?: (v: GenericRecord) => void;
};

const HeaderActions: React.FC<IHeaderParams<GenericRecord>> = () => {
    return (
        <div>
            <FormattedMessage id={"app.fields.actions"} />
        </div>
    );
};

const CellActions: React.FC<ICellRendererParams<GenericRecord> & CellActionsProps> = ({ onEdit, onDelete }) => {
    return (
        <Flex gap={5} align={"center"} style={{ height: "100%" }}>
            <Button icon={<EditOutlined />} shape={"circle"} onClick={onEdit} />
            <Popconfirm
                onConfirm={onDelete}
                title={<FormattedMessage id="app.confirmation.header" />}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
            >
                <Button icon={<DeleteOutlined />} shape={"circle"} />
            </Popconfirm>
        </Flex>
    );
};

export const TemplatesTable: FC<Props> = ({ templates, actions, onSelect }) => {
    const { yearConfig } = useContext(CompanyContext);
    const { product } = useContext(TableViewContext);
    const [openedKeys, setOpenedKeys] = useState(new Set());
    const gridOptions = useMemo(
        () => ({
            ...baseOptions,
            defaultColDef: {
                ...baseOptions.defaultColDef,
                filter: "agTextColumnFilter",
                suppressHeaderFilterButton: true,
                cellClass: styles.binaleCell,
                cellRenderer: ({ value }: ICellRendererParams<GenericRecord>) => {
                    return <div>{value ?? <>&nbsp;</>}</div>;
                },
                autoHeight: true,
            },
            rowSelection: onSelect ? { mode: "singleRow" } : undefined,
            onSelectionChanged: event => {
                const [record] = event.api.getSelectedRows();
                if (onSelect) {
                    onSelect(record);
                }
            },
        }),
        [onSelect]
    ) as GridOptions<GenericRecord>;

    const columns: (ColDef<GenericRecord> | ColGroupDef<GenericRecord>)[] = useMemo(() => {
        const defs: (ColDef<GenericRecord> | ColGroupDef<GenericRecord>)[] = [
            {
                field: "key",
                headerName: "",
                width: 50,
                filter: false,
                sortable: false,
                resizable: false,
                suppressMovable: true,
                suppressNavigable: true,
                cellRenderer: (node: ICellRendererParams<GenericRecord>) => {
                    if (node.data.items?.length <= 1) {
                        return null;
                    }
                    return (
                        <Button
                            shape={"circle"}
                            icon={openedKeys.has(node.data.key) ? <CaretDownOutlined /> : <CaretRightOutlined />}
                            onClick={() =>
                                setOpenedKeys(prevState => {
                                    if (prevState.has(node.data.key)) {
                                        prevState.delete(node.data.key);
                                    } else {
                                        prevState.add(node.data.key);
                                    }
                                    return new Set(prevState);
                                })
                            }
                        />
                    );
                },
                cellStyle: () => ({ paddingLeft: 5, paddingRight: 5 }),
            },
        ];
        if (!product) {
            return defs;
        }
        const cols = new GenericRecordColumns(product, yearConfig);
        const excludeColumns = new Set([
            TableProperties.ComputedNr,
            TableProperties.ControlAction,
            TableProperties.ControlLog,
            TableProperties.ComputedModul,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.ComputedOffen,
            GenericRecordProperties.ComputedZA,
            GenericRecordProperties.ComputedStatus,
            GenericRecordProperties.ControlBinding,
            GenericRecordProperties.RecordReview,
            GenericRecordProperties.ComputedSaldo,
            GenericRecordProperties.RecordFalligkeit,
            GenericRecordProperties.ComputedSHInfo,
            GenericRecordProperties.RecordPicture,
        ]);

        const sortColumns = new Set([
            GenericRecordProperties.RecordContact,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,
        ]);

        cols.getColumnConfig()
            .filter(v => !excludeColumns.has(v.key))
            .forEach(col => {
                const getData = (r: GenericRecord) => col.getter({ item: r, key: r.key, children: [], extra: {} });
                return defs.push({
                    headerValueGetter: () => ExportPreprocessor.nodeToString(col.header),
                    valueGetter: v => {
                        const children = TableUtils.getChildren(v.data);
                        return (
                            <Flex vertical>
                                <div>{getData(v.data) || <>&nbsp;</>}</div>
                                {openedKeys.has(v.data.key) &&
                                    children.map((el, idx) => {
                                        return (
                                            <div
                                                key={`${el.key}${idx}`}
                                                style={{
                                                    lineHeight: "20px",
                                                    fontSize: "small",
                                                    paddingLeft: 5,
                                                    // display: "list-item",
                                                    // listStyleType: "decimal",
                                                    // listStylePosition: "inside",
                                                }}
                                            >
                                                {getData(el) || <>&nbsp;</>}
                                            </div>
                                        );
                                    })}
                            </Flex>
                        );
                    },
                    cellStyle: node => {
                        return [
                            GenericRecordProperties.RecordBrutto,
                            GenericRecordProperties.RecordCurrencyOriginalAmount,
                            GenericRecordProperties.ComputedNetto,
                            GenericRecordProperties.ComputedUSt,
                        ].includes(col.key)
                            ? { textAlign: "right" }
                            : undefined;
                    },
                    width: col.width + 20,
                    filter: false,
                    autoHeight: true,
                    sort: col.key === GenericRecordProperties.RecordContact ? "asc" : undefined,
                    sortable: sortColumns.has(col.key),
                    comparator: sortColumns.has(col.key)
                        ? (a, b) => ExportPreprocessor.nodeToString(a).localeCompare(ExportPreprocessor.nodeToString(b))
                        : undefined,
                });
            });
        if (actions) {
            defs.push({
                headerComponent: HeaderActions,
                cellRenderer: CellActions,
                cellRendererParams: (params: ICellRendererParams<GenericRecord>): CellActionsProps => ({
                    onEdit: () => actions.onEdit(params.data),
                    onDelete: () => actions.onDelete(params.data),
                }),
                field: "actions" as any,
                floatingFilter: false,
                resizable: false,
                suppressMovable: true,
                suppressNavigable: true,
                width: 100,
                pinned: "right",
                sortable: false,
                lockPosition: true,
            });
        }
        return defs;
    }, [openedKeys, product, yearConfig, actions]);

    return (
        <Container absolute>
            {(w, h) => (
                <AgGridTable
                    rowData={templates}
                    wrapperStyles={{ height: h, width: w }}
                    columnDefs={columns}
                    gridOptions={gridOptions}
                />
            )}
        </Container>
    );
};
