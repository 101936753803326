import { IDocumentEnriched } from "@dms/types";
import { GQL } from "@binale-tech/shared";
import { canBeZeroFields } from "@dms/configs/constants";

export const convertPartnerField = (document: IDocumentEnriched): GQL.IDocumentContact | null => {
    return Object.values(document?.partner ?? {}).filter(Boolean).length ? document.partner : null;
};

export const convertDocumentToUpdateInput = (document: IDocumentEnriched): GQL.IDocumentUpdateInput => {
    document = { ...document };
    delete (document as unknown as { status: unknown }).status;
    delete (document as Partial<GQL.IDocument>).externalReference;
    delete (document as Partial<GQL.IDocument>).updatedAt;
    delete (document as Partial<GQL.IDocument>).createdAt;
    delete (document as Partial<GQL.IDocument>).createdBy;
    delete (document as Partial<GQL.IDocument>).fileName;
    delete (document as Partial<GQL.IDocument>).fileUrl;
    delete (document as Partial<GQL.IDocument>).previewUrl;
    delete (document as Partial<GQL.IDocument>).hasXRechnung;
    delete document.aiBackgroundProcessingResult;

    document.partner = convertPartnerField(document);

    Object.keys(document).forEach((key: keyof GQL.IDocumentUpdateInput) => {
        if (canBeZeroFields.includes(key)) {
            const value = document[key] || document[key] === 0 ? document[key] : null;
            document[key] = value as never;
        } else {
            const value = document[key] ? document[key] : null;
            document[key] = value as never;
        }
    });

    return { ...document } as any;
};
