import Menu, { MenuProps } from "antd/es/menu";
import React from "react";
import { AppRoutes } from "scripts/routing/routeConstants";
import { Link, useLocation } from "react-router-dom";
import { PdfToolsPaths } from "@pdf-tools/types";
import { InvoicingPaths } from "@inv/types";
import { FormattedMessage } from "react-intl";

type Props = { menuProps: MenuProps };
const ModuleInvoicingLinks: React.FC<Props> = ({ menuProps }) => {
    const { pathname = "" } = useLocation();

    const link = AppRoutes.invoicing + "/" + InvoicingPaths.FORMATS;

    return (
        <Menu
            selectedKeys={[pathname]}
            {...menuProps}
            items={[
                {
                    key: AppRoutes.invoicing,
                    label: (
                        <Link to={AppRoutes.invoicing}>
                            <FormattedMessage id={"app.invoice.invoicesList"} />
                        </Link>
                    ),
                },
                { key: link, label: <Link to={link}>Formats</Link> },
            ]}
        ></Menu>
    );
};

export default React.memo(ModuleInvoicingLinks);
