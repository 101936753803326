export enum OptionsValues {
    UNMAPPED = "unmapped",
    ADD_NEW_BANK = "addNewBank",
}

export enum BankingPaths {
    ROOT = "banks",
    IMPORT = "parse-transaction",
    TRANSACTIONS = "bank-transactions",
}
