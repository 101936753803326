import React, { FC, memo, useCallback, useContext } from "react";
import { GridReadyEvent, ModelUpdatedEvent } from "ag-grid-community";
import { AgGridTable } from "@app/components/shared/AgGridTable";

import { ITableDocument, tableModeEnum } from "@dms/types";
import { DmsAppContext, DmsAppControlContext } from "@dms/types/ContextTypes";
import { useDocumentTableConfig } from "@dms/modules/DocumentTableModule/hooks/useDocumentColumnConfig";
import { useDocumentTableOptions } from "@dms/modules/DocumentTableModule/hooks/useDocumentTableOptions";
import type { SelectionChangedEvent } from "ag-grid-community/dist/types/core/events";

type TProps = {
    dataSource: ITableDocument[];
    activeType: string[];
};

export const DocumentTableModule: FC<TProps> = memo(({ dataSource, activeType }) => {
    const { selectedRowKeys } = useContext(DmsAppContext);
    const { selectRows } = useContext(DmsAppControlContext);
    const gridOptions = useDocumentTableOptions();
    const { columnDefs } = useDocumentTableConfig(tableModeEnum.modal, [activeType[1]]);

    const onGridReady = useCallback(
        ({ api }: GridReadyEvent<ITableDocument>) => api.updateGridOptions({ rowData: dataSource }),
        [dataSource]
    );

    const onModelUpdated = useCallback(
        ({ api }: ModelUpdatedEvent<ITableDocument>) => {
            api.forEachNode(node => node.setSelected(selectedRowKeys.includes(node.data.key)));
        },
        [selectedRowKeys]
    );

    const onSelectionChanged = useCallback(
        (event: SelectionChangedEvent<ITableDocument>) => {
            selectRows(event.api.getSelectedRows().map(v => v.key));
        },
        [selectRows]
    );

    return (
        <AgGridTable
            rowData={dataSource}
            gridOptions={gridOptions}
            tableKey={activeType.join(",")}
            columnDefs={columnDefs}
            onSelectionChanged={onSelectionChanged}
            onGridReady={onGridReady}
            onModelUpdated={onModelUpdated}
        />
    );
});
