import { gql } from "@apollo/client";

export const invoiceSave = gql`
    mutation invoiceSave($input: InvoiceSaveInput!) {
        invoiceSave(input: $input) {
            id
        }
    }
`;
export const invoiceConfirm = gql`
    mutation invoiceConfirm($input: InvoiceConfirmInput!) {
        invoiceConfirm(input: $input) {
            id
            isConfirmed
            invoiceNumber
        }
    }
`;

export const invoiceDelete = gql`
    mutation invoiceDelete($input: InvoiceDeleteInput!) {
        invoiceDelete(input: $input)
    }
`;

export const invoiceApplyCurrencyRate = gql`
    mutation invoiceApplyCurrencyRate($input: InvoiceApplyCurrencyRateInput!) {
        invoiceApplyCurrencyRate(input: $input)
    }
`;
