import React, { type FC, useCallback, useEffect, useState } from "react";
import { Modal, Segmented } from "antd";
import { PdfViewer } from "@ui-components/PdfViewer/PdfViewer";
import { getAppCheckToken } from "../../../scripts/api/firebase/firebase";
import { GQL } from "@binale-tech/shared";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { PreviewInvoice } from "@app/components/recordform/FileUploader/PreviewInvoice";

type PreviewMode = "preview" | "invoice";
export const ModalPdfViewer: FC<{
    document?: GQL.IDocument;
    onClose: () => void;
}> = ({ document, onClose }) => {
    const [appCheckToken, setAppCheckToken] = useState<string>();
    const [viewerUrl, setViewerUrl] = useState<string>();
    const [previewMode, setPreviewMode] = useState<PreviewMode>("preview");

    useEffect(() => {
        if (!document) {
            return;
        }
        getAppCheckToken().then(token => {
            setAppCheckToken(token);
            setViewerUrl(document.fileUrl);
        });
    }, [document]);

    const onChangePreviewMode = useCallback((v: PreviewMode) => {
        setPreviewMode(v);
    }, []);

    if (!document) {
        return null;
    }

    return (
        <Modal
            open={Boolean(document)}
            onCancel={onClose}
            destroyOnClose
            footer={null}
            style={{
                top: "5px",
                padding: "10px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            title={
                <Segmented<PreviewMode>
                    value={previewMode}
                    options={[
                        { label: "PDF", value: "preview" },
                        { label: "E-Rechnung", value: "invoice", disabled: !document.hasXRechnung },
                    ]}
                    onChange={onChangePreviewMode}
                />
            }
            styles={{
                body: {
                    minWidth: "70vw",
                    height: `calc(100vh - 70px)`,
                    backgroundColor: "white",
                    display: "flex",
                },
            }}
        >
            {viewerUrl && previewMode === "preview" && (
                <PdfViewer
                    url={viewerUrl}
                    fileName={DmsUtils.getDownloadName(document)}
                    isModal
                    appCheckToken={appCheckToken}
                />
            )}
            {viewerUrl && previewMode === "invoice" && (
                <div style={{ width: "100%" }}>
                    <PreviewInvoice fileId={document.key} productKey={GQL.IProductKey.Er} />
                </div>
            )}
        </Modal>
    );
};
