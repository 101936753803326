import React, { useContext, useMemo } from "react";
import { ProductFE } from "../../../../scripts/core/Product";
import { CCDComponent } from "../components";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { useFormConfig } from "../hooks/useFormConfig";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";

import { useFormInfoBlock } from "../hooks/useFormInfoBlock";
import { useCheckAutoPaymentConnection } from "../hooks/useCheckAutoPaymentConnection";
import { useFormHandlerRecordAccount } from "../hooks/handlers/useFormHandlerRecordAccount";

export const FormRecordAccount: React.FC = () => {
    const isDisabled = useFormIsRefDisabled();
    const formConfig = useFormConfig();
    const { recordCCLabel } = useFormInfoBlock();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { editableRecord, editableRecordItem, recordItems, recordValidationStates } =
        useContext(RecordFormStateContext);
    const onRecordAccountChange = useFormHandlerRecordAccount();
    const checkAutoBinding = useCheckAutoPaymentConnection();

    const { recordBrutto, recordCategoryCreditor } = editableRecord;
    const label = useMemo(() => {
        if (!formConfig.useSollHabenHints) {
            return recordCCLabel;
        }
        const brutto = recordBrutto;
        const suffix = ProductFE.getSHHint(brutto).konto;
        return (
            <span>
                {recordCCLabel} {suffix}
            </span>
        );
    }, [formConfig.useSollHabenHints, recordBrutto, recordCCLabel]);

    if (!formConfig.recordAccountMode) {
        return null;
    }
    // in case of split/modal we take account from the recordItems got the Konto validation
    const itemKonto = recordItems?.length
        ? recordItems[0].getCategoryCreditor()
        : editableRecordItem.itemCategoryCreditor;
    const validation = recordValidationStates.get(refsHtml.REF_rCATEGORYCREDITOR);
    return (
        <CCDComponent
            labelProps={{ label, fieldError: validation }}
            mode={formConfig.recordAccountMode}
            itemKonto={itemKonto}
            inputRef={refsHtml.REF_rCATEGORYCREDITOR}
            value={recordCategoryCreditor}
            onChange={onRecordAccountChange}
            disabled={isDisabled(refsHtml.REF_rCATEGORYCREDITOR)}
            onBlur={checkAutoBinding}
        />
    );
};
