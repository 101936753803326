import { gql } from "@apollo/client";

export const documentCreate = gql`
    mutation documentCreate($input: DocumentCreateInput!) {
        documentCreate(input: $input)
    }
`;

export const documentsUpdate = gql`
    mutation documentsUpdate($input: [DocumentUpdateInput!]!) {
        documentsUpdate(input: $input)
    }
`;

export const documentsDelete = gql`
    mutation documentsDelete($input: [DocumentDeleteInput!]!) {
        documentsDelete(input: $input)
    }
`;

export const documentsTypeUpdate = gql`
    mutation documentsTypeUpdate($input: [DocumentTypeUpdateInput!]!) {
        documentsTypeUpdate(input: $input)
    }
`;

export const documentSubTypeCreate = gql`
    mutation documentSubTypeCreate($input: DocumentSubTypeCreateInput!) {
        documentSubTypeCreate(input: $input) {
            id
            name
            sortOrder
            icon
        }
    }
`;

export const documentSubTypeUpdate = gql`
    mutation documentSubTypeUpdate($input: DocumentSubTypeUpdateInput!) {
        documentSubTypeUpdate(input: $input) {
            id
            name
            sortOrder
            icon
        }
    }
`;

export const documentSubTypeDelete = gql`
    mutation documentSubTypeDelete($input: DocumentSubTypeDeleteInput!) {
        documentSubTypeDelete(input: $input)
    }
`;

export const bindSubTypeToKb = gql`
    mutation bindSubTypeToKb($input: BindSubTypeInput!) {
        bindSubTypeToKb(input: $input)
    }
`;

export const unbindSubTypeToKb = gql`
    mutation unbindSubTypeToKb($input: BindSubTypeInput!) {
        unbindSubTypeToKb(input: $input)
    }
`;

export const bindSubTypeToBank = gql`
    mutation bindSubTypeToBank($input: BindSubTypeInput!) {
        bindSubTypeToBank(input: $input)
    }
`;

export const unbindSubTypeToBank = gql`
    mutation unbindSubTypeToBank($input: BindSubTypeInput!) {
        unbindSubTypeToBank(input: $input)
    }
`;
