import AnsichtView from "../../../views/productSharedComponents/AnsichtView";
import Menu, { MenuProps } from "antd/es/menu";
import React from "react";
import { AppRoutes, getRoute, ModuleSubGroups } from "scripts/routing/routeConstants";
import { DownOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { Link, useLocation } from "react-router-dom";
import { Modal } from "antd";

type Props = { menuProps: MenuProps; isAZ: boolean };
export const ModuleERLinks: React.FC<Props> = ({ menuProps, isAZ }) => {
    const { pathname = "" } = useLocation();
    const [showSettings, setSettings] = React.useState(false);
    const subGroup = isAZ ? ModuleSubGroups.ErA : ModuleSubGroups.ER;

    const oposRoute = getRoute(AppRoutes.invoiceAnalysisOpos, { subGroup });
    const avisRoute = getRoute(AppRoutes.invoiceAnalysisAvis, { subGroup });
    const falligkeitRoute = getRoute(AppRoutes.invoiceAnalysisFalligkeit, { subGroup });
    const festschreibungRoute = getRoute(AppRoutes.invoiceFestschreibung, { subGroup });
    const title = isAZ ? <FormattedMessage id="app.titles.ER_A" /> : <FormattedMessage id="app.titles.ER.rechnungen" />;
    const menuItems: React.ComponentProps<typeof Menu>["items"] = [
        {
            key: "ERProducts",
            label: (
                <span>
                    {title} <DownOutlined />
                </span>
            ),
            children: [
                {
                    key: getRoute(AppRoutes.invoiceModule, { subGroup: ModuleSubGroups.ER }),
                    label: (
                        <Link to={getRoute(AppRoutes.invoiceModule, { subGroup: ModuleSubGroups.ER })}>
                            <FormattedMessage id="app.titles.ER.rechnungen" />
                        </Link>
                    ),
                },
                {
                    key: getRoute(AppRoutes.invoiceModule, { subGroup: ModuleSubGroups.ErA }),
                    label: (
                        <Link to={getRoute(AppRoutes.invoiceModule, { subGroup: ModuleSubGroups.ErA })}>
                            <FormattedMessage id="app.titles.ER_A" />
                        </Link>
                    ),
                },
            ],
        },
        {
            key: "Tools",
            label: (
                <span>
                    <FormattedMessage id="app.titles.ER.auswertung" /> <DownOutlined />
                </span>
            ),
            children: [
                {
                    key: oposRoute,
                    label: <Link to={oposRoute}>OPOS</Link>,
                },
                {
                    key: avisRoute,
                    label: (
                        <Link to={avisRoute}>
                            <FormattedMessage id="app.titles.ER.za" />
                        </Link>
                    ),
                },
                {
                    key: falligkeitRoute,
                    label: (
                        <Link to={falligkeitRoute}>
                            <FormattedMessage id="app.fields.falligkeit" />
                        </Link>
                    ),
                },
                {
                    key: festschreibungRoute,
                    label: (
                        <Link to={festschreibungRoute}>
                            <FormattedMessage id="app.titles.ER.festschreibung" />
                        </Link>
                    ),
                },
            ],
        },
        {
            key: getRoute(AppRoutes.invoiceTemplates, { subGroup: isAZ ? ModuleSubGroups.ErA : ModuleSubGroups.ER }),
            label: (
                <Link
                    to={getRoute(AppRoutes.invoiceTemplates, {
                        subGroup: isAZ ? ModuleSubGroups.ErA : ModuleSubGroups.ER,
                    })}
                >
                    <FormattedMessage id="app.titles.templates" />
                </Link>
            ),
        },
        {
            key: "settings",
            onClick: () => setSettings(true),
            label: <FormattedMessage id="app.titles.settings" />,
        },
    ];
    return (
        <>
            <Menu selectedKeys={[pathname]} {...menuProps} items={menuItems} />
            <Modal
                open={showSettings}
                onCancel={() => setSettings(false)}
                footer={false}
                width={900}
                focusTriggerAfterClose={false}
                destroyOnClose
            >
                <AnsichtView pk={isAZ ? GQL.IProductKey.ErA : GQL.IProductKey.Er} />
            </Modal>
        </>
    );
};
