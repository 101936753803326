import { GenericItem, GenericRecord } from "../GenericRecord";
import { Bu, Contacts, GQL, Periods, Utils } from "@binale-tech/shared";
import dayjs from "dayjs";
import RecordFormState from "../../../appearance/components/recordform/types/RecordFormState";
import RecordFormUtils from "../../../appearance/components/recordform/utils/RecordFormUtils";
import { CurrencyValue } from "@ui-components/AmountInput/BruttoInput";
import { ContactAccountingConverter } from "./ContactAccountingConverter";
import { Product } from "../../core/Product";
import { BuTimeframe } from "../../context/BuContext";
import { ProductAccessUtils } from "../utils/ProductAccessUtils";
import { IDocumentSuggestion } from "@dms/types";
import { GenericRecordUtils } from "../utils/GenericRecordUtils";
import { PDFDocument } from "pdf-lib";
import { extractAttachments } from "@pdf-tools/tools/extractAttachments";
import { convertXML } from "simple-xml-to-json";
import { getContactVatPrint } from "@app/views/productContacts/form/utils";
import QuickLRU from "quick-lru";
import React from "react";
import { TableItem } from "@app/components/shared/Table/Table";
import { BinaleError } from "../../errors/errors";

const findNodes = (node: Record<string, any>[], key: string): Record<string, any>[] => {
    return node?.filter((node: Record<string, any>) => Object.keys(node ?? {})[0] === key).map(v => v?.[key]) ?? [];
};
const findNode = (node: Record<string, any>[], key: string): Record<string, any> => {
    return findNodes(node, key)[0];
};
const getChild = (node: Record<string, any>[], key: string): Record<string, any>[] =>
    findNode(node, key)?.children ?? [];
const getChilds = (node: Record<string, any>[], key: string): Record<string, any>[][] =>
    findNodes(node, key).map(v => v.children);

type BtValue = {
    BT: number;
    value: string | number;
};
export type XRechnungResponse = {
    generated: {
        record: GenericRecord;
        document: IDocumentSuggestion;
    };
    rawXml: string;
    raw: {
        currency: string;
        paymentReference?: string;
        paymentDescription?: string;
        paymentDueDate?: string;
        paymentType?: string;
        deliveryDate?: string;
        supplier?: {
            name: string;
            addressLines: string[];
            communications: string[];
            IBAN?: string;
            IBANAccountName?: string;
            BIC?: string;
            vatId?: string;
        };
        buyer?: {
            name: string;
            addressLines: string[];
            email: string;
            vatId?: string;
        };
        taxItems: { tax: number; brutto: number; nettoAmount: number; taxAmount: number }[];
        lines: { tax: number; bu: number; netto: number; text: string }[];
    };
};

function htmlDecode(input?: string) {
    if (!input) {
        return undefined;
    }
    const doc = new DOMParser().parseFromString("<div>" + input + "</div>", "text/html");
    return doc.documentElement.textContent;
}

function convert102Date(v: string): string {
    if (!v) {
        return v;
    }
    return `${v.substring(6, 8)}.${v.substring(4, 6)}.${v.substring(0, 4)}`;
}

export const decodePdfInvoiceData = async (
    pdfBytes: Uint8Array | ArrayBuffer,
    pk: GQL.IProductKey,
    contacts: Contacts.Contact[] = []
): Promise<XRechnungResponse> => {
    const pdfDocText = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
    const attachments = extractAttachments(pdfDocText);
    const invoice = attachments.filter(a => a.name.toLowerCase().endsWith(".xml"));
    if (invoice.length !== 1) {
        return undefined;
    }

    const useSplitBF1 = Utils.ModuleUtils.useBelegfeld1Split(pk);
    const rawXml = new TextDecoder().decode(invoice[0].data);
    const decodedXml = convertXML(rawXml);

    const CII = decodedXml?.["rsm:CrossIndustryInvoice"]?.children;

    const exchangedDocument = getChild(CII, "rsm:ExchangedDocument");
    const invoiceNumber = findNode(exchangedDocument, "ram:ID")?.content; // BT-1

    const issueDate = getChild(exchangedDocument, "ram:IssueDateTime");
    const invoiceDate: string = findNode(issueDate, "udt:DateTimeString")?.content; // BT-2;

    const invoiceDateParsed = invoiceDate
        ? {
              year: Number(invoiceDate.substring(0, 4)),
              period: Number(invoiceDate.substring(4, 6)),
              day: Number(invoiceDate.substring(6, 8)),
          }
        : undefined;

    const transaction = getChild(CII, "rsm:SupplyChainTradeTransaction");

    const tradeDelivery = getChild(transaction, "ram:ApplicableHeaderTradeDelivery");
    const actualDelivery = getChild(tradeDelivery, "ram:ActualDeliverySupplyChainEvent");
    const actualDeliveryDateTime = getChild(actualDelivery, "ram:OccurrenceDateTime");
    const deliveryDate: string = convert102Date(findNode(actualDeliveryDateTime, "udt:DateTimeString")?.content); // BT-72;

    const settlement = getChild(transaction, "ram:ApplicableHeaderTradeSettlement");
    const getBu = (tax: number): Bu.Bu => {
        // todo reverse charge
        if (tax === 7) {
            if (pk === GQL.IProductKey.Deb) {
                return Bu.Bu.BU2;
            }
            return Bu.Bu.BU8;
        }
        if (tax === 19) {
            if (pk === GQL.IProductKey.Deb) {
                return Bu.Bu.BU3;
            }
            return Bu.Bu.BU9;
        }
        return Bu.Bu.KU;
    };
    const currency = findNode(settlement, "ram:InvoiceCurrencyCode")?.content ?? "EUR"; // BT-5
    const paymentReference = findNode(settlement, "ram:PaymentReference")?.content; // BT-83

    const taxes = getChilds(settlement, "ram:ApplicableTradeTax"); // BG-23
    const taxItems = taxes
        .map(t => {
            const tax = Number(findNode(t, "ram:RateApplicablePercent")?.content ?? "0"); // BT-119
            const nettoAmount = Number(findNode(t, "ram:BasisAmount")?.content ?? "0") * 100; // BT-116
            const taxAmount = Number(findNode(t, "ram:CalculatedAmount")?.content ?? "0") * 100; // BT-119
            return { tax, nettoAmount, taxAmount, brutto: nettoAmount + taxAmount, bu: getBu(tax) };
        })
        .filter(v => v.nettoAmount !== 0);

    const agreement = getChild(transaction, "ram:ApplicableHeaderTradeAgreement");

    // supplier
    const seller = getChild(agreement, "ram:SellerTradeParty");
    const supplierName = htmlDecode(findNode(seller, "ram:Name")?.content); // BT-27

    const supplierTaxRegistration = getChild(seller, "ram:SpecifiedTaxRegistration");
    const supplierVatId: string = findNode(supplierTaxRegistration, "ram:ID")?.content; // BT-31

    const supplierAddress = getChild(seller, "ram:PostalTradeAddress"); // BG-5
    const supplierAddressLines: string[] = [
        htmlDecode(findNode(supplierAddress, "ram:LineOne")?.content), // BT-35
        htmlDecode(findNode(supplierAddress, "ram:LineTwo")?.content), // BT-36
        [
            htmlDecode(findNode(supplierAddress, "ram:PostcodeCode")?.content), // BT-38
            htmlDecode(findNode(supplierAddress, "ram:CityName")?.content), // BT-37
            htmlDecode(findNode(supplierAddress, "ram:CountryID")?.content), // BT-40
        ]
            .filter(Boolean)
            .join(" "),
    ].filter(Boolean);

    const contactsPointsSet = new Set<string>();
    const tradeContact = getChild(seller, "ram:DefinedTradeContact"); // BG-6
    if (tradeContact) {
        contactsPointsSet.add(htmlDecode(findNode(tradeContact, "ram:PersonName")?.content)); // BT-41
        const contactEmailCommunication = getChild(tradeContact, "ram:EmailURIUniversalCommunication"); // BT-43
        contactsPointsSet.add(htmlDecode(findNode(contactEmailCommunication, "ram:URIID")?.content));
        const contactPhoneCommunication = getChild(tradeContact, "ram:TelephoneUniversalCommunication"); // BT-42
        contactsPointsSet.add(htmlDecode(findNode(contactPhoneCommunication, "ram:CompleteNumber")?.content));
    }

    const supplierCommunication = getChild(seller, "ram:URIUniversalCommunication"); // BT-34
    contactsPointsSet.add(htmlDecode(findNode(supplierCommunication, "ram:URIID")?.content));

    const communications = [...contactsPointsSet].filter(Boolean);

    // buyer
    const buyer = getChild(agreement, "ram:BuyerTradeParty"); // BG-7

    const buyerName = htmlDecode(findNode(buyer, "ram:Name")?.content); // BT-44

    const buyerTaxRegistration = getChild(buyer, "ram:SpecifiedTaxRegistration");
    const buyerVatId: string = findNode(buyerTaxRegistration, "ram:ID")?.content; // BT-48

    const buyerAddress = getChild(buyer, "ram:PostalTradeAddress"); // BG-8
    const buyerAddressLines: string[] = [
        htmlDecode(findNode(buyerAddress, "ram:LineOne")?.content), // BT-50
        htmlDecode(findNode(buyerAddress, "ram:LineTwo")?.content), // BT-51
        [
            htmlDecode(findNode(buyerAddress, "ram:PostcodeCode")?.content), // BT-53
            htmlDecode(findNode(buyerAddress, "ram:CityName")?.content), // BT-52
            htmlDecode(findNode(buyerAddress, "ram:CountryID")?.content), // BT-55
        ]
            .filter(Boolean)
            .join(" "),
    ].filter(Boolean);
    const buyerCommunication = getChild(buyer, "ram:URIUniversalCommunication");
    const buyerEmail = htmlDecode(findNode(buyerCommunication, "ram:URIID")?.content); // BT-49

    // lines
    const lineItems = getChilds(transaction, "ram:IncludedSupplyChainTradeLineItem"); // BG-25
    const lines = lineItems.map(v => {
        const settlement = getChild(v, "ram:SpecifiedLineTradeSettlement");

        const tradeTax = getChild(settlement, "ram:ApplicableTradeTax");
        const percent = Number(findNode(tradeTax, "ram:RateApplicablePercent")?.content ?? "0"); // BT-152

        const summation = getChild(settlement, "ram:SpecifiedTradeSettlementLineMonetarySummation");
        const netto = Number(findNode(summation, "ram:LineTotalAmount")?.content ?? "0") * 100; // BT-131

        const text = htmlDecode(findNode(getChild(v, "ram:SpecifiedTradeProduct"), "ram:Name")?.content); // BT-153
        return { text, netto, tax: percent, bu: getBu(percent) };
    });

    // payment
    const paymentMeans = getChild(settlement, "ram:SpecifiedTradeSettlementPaymentMeans");
    const paymentType = findNode(paymentMeans, "ram:TypeCode")?.content;
    const paymentAccount = getChild(paymentMeans, "ram:PayeePartyCreditorFinancialAccount");
    const paymentInstitution = getChild(paymentMeans, "ram:PayeeSpecifiedCreditorFinancialInstitution");
    const supplierIBAN = findNode(paymentAccount, "ram:IBANID")?.content; // BT-84
    const supplierIBANAccountName = htmlDecode(findNode(paymentAccount, "ram:AccountName")?.content); // BT-85
    const supplierBIC = findNode(paymentInstitution, "ram:BICID")?.content; // BT-86

    const paymentTerms = getChild(settlement, "ram:SpecifiedTradePaymentTerms");
    const paymentDescription = findNode(paymentTerms, "ram:Description")?.content; // BT-20

    const dueDate = getChild(paymentTerms, "ram:DueDateDateTime");
    const paymentDueDate: string = convert102Date(findNode(dueDate, "udt:DateTimeString")?.content); // BT-9;

    const linesBuTexts = new Map<Bu.Bu, Set<string>>();
    lines.forEach(line => {
        if (!linesBuTexts.has(line.bu)) {
            linesBuTexts.set(line.bu, new Set());
        }
        linesBuTexts.get(line.bu).add(line.text);
    });
    const genericItems = taxItems.map(({ bu, brutto }) => {
        return new GenericItem({
            brutto,
            bu,
            text: linesBuTexts.get(bu).size === 1 ? [...linesBuTexts.get(bu)][0] : "Diverse",
            originalAmount: currency !== GQL.ICurrencyCode.Eur ? brutto : undefined,
            belegfeld1: useSplitBF1 ? invoiceNumber : undefined,
        });
    });

    const date = invoiceDateParsed
        ? Periods.constructDate(invoiceDateParsed.year, invoiceDateParsed.period, invoiceDateParsed.day)
        : undefined;

    const getContact = (name: string, vatId: string, IBAN?: string) => {
        for (const c of contacts) {
            const labelName = Contacts.getLabelName(c).toLowerCase();
            if (vatId && vatId === getContactVatPrint(c.legalInfo)) {
                return c;
            }
            if (name) {
                if (name.toLowerCase() === labelName) {
                    return c;
                }
                if ((c.banks ?? []).some(b => b.accountHolder === name)) {
                    return c;
                }
            }

            if (IBAN && (c.banks ?? []).some(b => b.IBAN === IBAN)) {
                return c;
            }
        }
    };

    const getProductContact = () => {
        if (pk === GQL.IProductKey.Deb) {
            return getContact(buyerName, buyerVatId);
        }
        if (pk === GQL.IProductKey.Er || pk === GQL.IProductKey.ErA) {
            return getContact(supplierName, supplierVatId, supplierIBAN);
        }
    };

    const contact = getProductContact();

    const record = new GenericRecord({
        date,
        num: useSplitBF1 ? undefined : invoiceNumber,
        year: invoiceDateParsed.year,
        period: invoiceDateParsed.period,
        day: invoiceDateParsed.day,
        items: genericItems,
        currency: currency !== GQL.ICurrencyCode.Eur ? { rate: 1, code: currency } : undefined,
        partner: contact ? { id: contact.uuid, name: Contacts.getLabelName(contact) } : undefined,
    });
    record.originalAmount = record.getOriginalAmount();

    const suggestion = DmsAccountingConverter.convertRecordToDms(record, pk);
    if (supplierVatId) {
        suggestion.landCode = supplierVatId.substring(0, 2);
        suggestion.UStIdNr = supplierVatId.substring(2);
    }
    if (lines.length > 1) {
        suggestion.description = "";
    }

    return {
        rawXml,
        generated: {
            record,
            document: suggestion,
        },
        raw: {
            taxItems,
            lines,
            currency,
            paymentReference,
            paymentDescription,
            paymentDueDate,
            paymentType,
            deliveryDate,
            supplier: {
                name: supplierName,
                IBAN: supplierIBAN,
                IBANAccountName: supplierIBANAccountName,
                BIC: supplierBIC,
                vatId: supplierVatId,
                addressLines: supplierAddressLines,
                communications,
            },
            buyer: {
                name: buyerName,
                addressLines: buyerAddressLines,
                email: buyerEmail,
                vatId: buyerVatId,
            },
        },
    };
};

const responseCache = new QuickLRU<string, XRechnungResponse>({ maxSize: 100 });

export const fetchAndGetInvoiceData = async (
    fileUrl: string,
    pk: GQL.IProductKey,
    contacts: Contacts.Contact[] = []
) => {
    if (responseCache.has(fileUrl)) {
        return responseCache.get(fileUrl);
    }
    return fetch(fileUrl)
        .then(r => r.arrayBuffer())
        .then(buf => decodePdfInvoiceData(buf, pk, contacts))
        .then(res => {
            // responseCache.set(fileUrl, res);
            return res;
        });
};

type RecordForm = {
    recordDate?: RecordFormState["recordDate"];
    editableRecord: Partial<RecordFormState["editableRecord"]>;
    editableRecordItem: Partial<RecordFormState["editableRecordItem"]>;
    recordItems?: Partial<RecordFormState["recordItems"]>;
};
type FormData = { isUpdating: boolean; yearBound: number; periodBound: number; recordFormData: RecordForm };

export class DmsAccountingConverter {
    constructor(
        protected company: GQL.ICompany,
        protected product: Product,
        protected contacts: Contacts.Contact[],
        protected templates: GenericRecord[],
        protected skr: number,
        protected buTimeframes: BuTimeframe[],
        protected recordGroup: string
    ) {}
    static convertRecordToDms(record: GenericRecord, pk: GQL.IProductKey): IDocumentSuggestion {
        const suggestion: IDocumentSuggestion = {};

        suggestion.documentAmount = record.getBrutto();
        suggestion.partner = record.partner;
        suggestion.documentDate = dayjs(record.date).format("DD.MM.YYYY");

        const text = record.items.find(item => item.text)?.text;
        if (text) {
            suggestion.description = text;
        }
        const belegfeld2 = record.items.find(item => item.belegfeld2)?.belegfeld2;
        if (belegfeld2) {
            suggestion.interneNumber = belegfeld2;
        }
        const recordNum = GenericRecordUtils.getInvoiceNumber(record, pk);
        if (recordNum) {
            suggestion.documentNumber = recordNum;
        }

        if (record.currency) {
            suggestion.currency = {
                originalAmount: record.getOriginalAmount(),
                currencyCode: record.currency.code,
                currencyRate: record.currency.rate,
            };
        }
        return suggestion;
    }

    /**
     * this method generates suggestions for both: record form and dms conversion.
     * Contact data automations must be handled separately
     *
     * @param productKey
     * @param documents
     * @param formData
     */
    static getRecordDocumentsSuggestion(productKey: GQL.IProductKey, documents: GQL.IDocument[], formData?: FormData) {
        if (!documents.length) {
            return {
                editableRecord: {},
                editableRecordItem: {},
            };
        }
        const useItemBelegfeld1 = Utils.ModuleUtils.useBelegfeld1Split(productKey);
        const result: RecordForm = formData?.recordFormData ?? {
            editableRecord: {},
            editableRecordItem: {},
        };

        for (const document of documents) {
            if (document.documentDate) {
                this.updateRecordDate(result, document.documentDate, formData);
            }

            if (!result.editableRecord.recordContact && document.partner) {
                result.editableRecord.recordContact = document.partner;
            }
            if (document.documentNumber) {
                if (useItemBelegfeld1) {
                    if (!result.editableRecordItem.itemBelegfeld1) {
                        result.editableRecordItem.itemBelegfeld1 = document.documentNumber;
                    }
                } else {
                    if (!result.editableRecord.recordNum) {
                        result.editableRecord.recordNum = document.documentNumber;
                    }
                }
            }

            if (!result.editableRecordItem.itemText && document.description) {
                result.editableRecordItem.itemText = document.description;
            }
            if (!result.editableRecordItem.itemBelegfeld2 && document.interneNumber) {
                result.editableRecordItem.itemBelegfeld2 = document.interneNumber;
            }

            if (!result.editableRecord.recordBrutto && document.documentAmount) {
                const currencyValue: CurrencyValue = { amount: document.documentAmount };
                if (document.currency !== GQL.ICurrencyCode.Eur) {
                    currencyValue.originalAmount = document.originalAmount;
                    currencyValue.currency = {
                        code: document.currency,
                        rate: document.currencyRate,
                    };
                }
                Object.assign(result.editableRecord, RecordFormUtils.getFormCurrency(currencyValue));
                result.editableRecordItem.itemBrutto = currencyValue.amount;
                result.editableRecordItem.itemOriginalAmount = currencyValue.originalAmount;
            }
        }
        return result;
    }

    // this is used in dms. We only apply a template here if there is exactly 1 matching template for the contact
    static applyContactTemplateSuggestions(suggestion: RecordForm, templates: GenericRecord[]) {
        if (suggestion.editableRecord.recordContact) {
            const contactId = suggestion.editableRecord.recordContact.id;
            const contactTemplates = templates.filter(({ partner }) => partner.id === contactId);
            const template = contactTemplates.length === 1 ? contactTemplates[0] : undefined;
            const contactSuggestions = ContactAccountingConverter.applyContactTemplateToRecord(template, suggestion);
            Object.assign(suggestion.editableRecord, contactSuggestions.editableRecord);
            if (suggestion.editableRecordItem) {
                Object.assign(suggestion.editableRecordItem, contactSuggestions.editableRecordItem);
            }
        }
    }

    async convertDocumentToRecord(document: GQL.IDocument): Promise<GenericRecord | null> {
        if (!ProductAccessUtils.hasCompanyAccounting(this.company)) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: "No access",
            });
        }
        if (!document) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: "Empty document",
            });
        }

        const productKey = this.product.productKey() as GQL.IProductKey;

        const xRechnungData = document.hasXRechnung
            ? await fetchAndGetInvoiceData(document.fileUrl, productKey, this.contacts)
            : null;

        const getInitialSuggestion = (): RecordForm => {
            const documentSuggestion = DmsAccountingConverter.getRecordDocumentsSuggestion(productKey, [document]);
            if (xRechnungData?.generated?.record) {
                const xRechnungSuggestion: RecordForm = {
                    recordDate: RecordFormUtils.getFormDate(xRechnungData.generated.record.date),
                    editableRecord: GenericRecordUtils.convertRecordToForm(xRechnungData.generated.record),
                    editableRecordItem: undefined,
                };
                if (xRechnungData.generated.record.items.length === 1) {
                    xRechnungSuggestion.editableRecordItem = GenericRecordUtils.convertRecordItemToForm(
                        xRechnungData.generated.record.items[0],
                        this.product
                    );
                } else {
                    xRechnungSuggestion.recordItems = xRechnungData.generated.record.items;
                }

                Object.entries(documentSuggestion.editableRecord).forEach(([key, value]) => {
                    if (
                        ["recordContact", "recordNum"].includes(key) &&
                        !xRechnungSuggestion.editableRecord[key as never]
                    ) {
                        xRechnungSuggestion.editableRecord[key as never] = value as never;
                    }
                });

                // if there is no split
                if (xRechnungSuggestion.editableRecordItem) {
                    Object.entries(documentSuggestion.editableRecordItem).forEach(([key, value]) => {
                        if (
                            !["itemBrutto", "itemOriginalAmount"].includes(key) &&
                            !xRechnungSuggestion.editableRecordItem[key as never]
                        ) {
                            xRechnungSuggestion.editableRecordItem[key as never] = value as never;
                        }
                    });
                }

                return xRechnungSuggestion;
            }
            return documentSuggestion;
        };

        const suggestion = getInitialSuggestion();
        suggestion.editableRecord.recordDocuments = [{ id: document.key, url: document.fileUrl }];
        suggestion.editableRecord.recordDraft = true;

        DmsAccountingConverter.applyContactTemplateSuggestions(suggestion, this.templates);

        if (!suggestion.recordDate) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: "Date is empty",
            });
        }

        const { date, period } = suggestion.recordDate;

        if (!this.company.accountingYears.includes(date.getFullYear())) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: `Accounting for year ${date.getFullYear()} is not configured for this company`,
            });
        }
        if (!suggestion.editableRecord.recordBrutto) {
            throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data", {
                reason: `Brutto amount is empty`,
            });
        }

        suggestion.editableRecord.recordKey = Utils.ModuleUtils.generateKey(date, period, "DmsI");

        const record = RecordFormUtils.constructRecord(
            suggestion as RecordFormState,
            this.product.getConfig(),
            this.skr,
            this.buTimeframes,
            this.recordGroup
        );
        record.items = suggestion.recordItems ?? [
            RecordFormUtils.constructRecordItem(
                suggestion as RecordFormState,
                this.product.getConfig(),
                this.skr,
                this.buTimeframes,
                this.recordGroup
            ),
        ];

        record.calculateBrutto();
        return record;
    }

    protected static updateRecordDate(result: RecordForm, documentDate: string, formData?: FormData) {
        // Check if documentDate exists and can be parsed as a valid date
        const parsedDate = dayjs(documentDate, "DD.MM.YYYY").toDate();
        if (!RecordFormUtils.isDate(parsedDate)) {
            return;
        }

        // Decide based on formData existence and update logic
        if (!formData) {
            result.recordDate = RecordFormUtils.getFormDate(parsedDate);
        } else if (!formData.isUpdating && parsedDate.getFullYear() === formData?.yearBound) {
            this.handlePeriodBoundUpdate(result, parsedDate, formData);
        }
    }

    protected static handlePeriodBoundUpdate(result: RecordForm, date: Date, formData?: FormData) {
        if (!Number.isFinite(formData.periodBound)) {
            result.recordDate = RecordFormUtils.getFormDate(date);
            return;
        }

        const { month, day: strictDay } = Periods.getMonthAndDay(formData.periodBound);
        if (!strictDay && month === date.getMonth()) {
            result.recordDate = RecordFormUtils.getFormDate(date, formData.periodBound);
        }
    }
}
