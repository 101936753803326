import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Flex, Modal, Spin } from "antd";
import styles from "./EditTypeModal.module.scss";
import { DmsAppContext, DmsAppControlContext, DmsDataContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { CloseButton } from "@dms/components/CloseButton/CloseButton";
import { EditSubTypeInput } from "@dms/components/EditTypeModal/components/EditSubTypeInput";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { DmsType, SubTypeActions, TEditingSubType } from "@dms/types";

const titleTranslateKey: Partial<Record<SubTypeActions, Record<TEditingSubType, string>>> = {
    [SubTypeActions.createSubType]: {
        [DmsType.KB]: "app.titles.create_kb",
        [DmsType.Bank]: "app.titles.create_bank",
    },
    [SubTypeActions.updateSubType]: {
        [DmsType.KB]: "app.titles.update_kb",
        [DmsType.Bank]: "app.titles.update_bank",
    },
};

export const EditTypeModal: FC = () => {
    const [value, setValue] = useState<string | undefined>();
    const [success, setSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const { editTypeModalData } = useContext(DmsAppContext);
    const { editTypeModalDataAction } = useContext(DmsAppControlContext);
    const { setTableEnabledColumns, setFilterConfig, deleteTypeConfig } = useContext(DmsUserSettingsContext);
    const { fetchTypes } = useContext(DmsDataContext);
    const { companyGQL } = useContext(CompanyContext);

    const mutator = useGqlMutator();

    const { action, typeKeyPath, isOpen, currentVal } = editTypeModalData;
    const { type } = DmsUtils.getActiveTypeAndSubType(typeKeyPath);

    useEffect(() => {
        setValue(currentVal);
    }, []);

    useEffect(() => {
        setIsLoading(false);
        setIsError(false);
        setSuccess(false);
        if (!value || currentVal === value) {
            return setIsDisabled(true);
        }
        setIsDisabled(false);
    }, [value]);

    const handleClose = () => {
        editTypeModalDataAction({ isOpen: false });
    };

    async function handleConfirm() {
        setIsLoading(true);

        setIsLoading(true);
        await DmsUtils.menuSubTypeAction({
            typeKeyPath,
            action,
            companyId: companyGQL.id,
            mutator,
            fetchTypes,
            setTableEnabledColumns,
            setFilterConfig,
            deleteTypeConfig,
            value,
        }).catch(() => {
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 1000);
        });
        setIsLoading(false);
        setSuccess(true);
        setTimeout(() => {
            handleClose();
        }, 500);
    }

    return (
        <Modal
            open={isOpen}
            closable={false}
            footer={null}
            className={styles.modal}
            styles={{
                body: {
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "450px",
                    maxWidth: "30vw",
                    height: "auto",
                    backgroundColor: "white",
                },
            }}
            title={
                <>
                    <FormattedMessage id={`${titleTranslateKey[action][type as TEditingSubType]}`} />
                </>
            }
        >
            <CloseButton onClose={handleClose} />
            <div className={styles.inputWrapper}>
                <EditSubTypeInput value={value} onChange={arg => setValue(arg)} isError={isError} />
            </div>
            <Flex justify={"flex-end"} align={"center"} gap={10} style={{ marginTop: 20 }}>
                {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
                {isError && !isLoading && <CloseCircleOutlined style={{ fontSize: 24, color: "#ff4d4f" }} />}
                {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                <Button onClick={handleClose}>
                    <FormattedMessage id={`app.button.cancel`} />
                </Button>
                <Button type={"primary"} onClick={handleConfirm} disabled={isDisabled}>
                    <FormattedMessage id={`app.button.confirm`} />
                </Button>
            </Flex>
        </Modal>
    );
};
