import React, { memo, useContext, useMemo, useState } from "react";
import { CopyOutlined, EditOutlined, ExportOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Flex, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { DmsPaths } from "@dms/configs/constants";
import { ITableDocument, tableModeEnum } from "@dms/types";
import { DropdownActions } from "@dms/components/ActionList/DropdownActions";
import { ICellRendererParams } from "ag-grid-community";
import { DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { GQL } from "@binale-tech/shared";
import { ModalPdfViewer } from "@dms/components/ModalPdfViewer/ModalPdfViewer";

interface IProps extends ICellRendererParams<ITableDocument> {
    documentKey: string;
    mode?: tableModeEnum;
}

export const TableActionList = memo<IProps>(function TableActionList({ documentKey, mode, node }) {
    const [title, setTitle] = useState(<FormattedMessage id="app.dms.copyId" />);

    const { selectRow, typeChangeModeToggle } = useContext(DmsAppControlContext);
    const { documents } = useContext(DmsDataContext);
    const [viewerDocument, setViewerDocument] = useState<GQL.IDocument>();

    const document = useMemo(() => documents.find(el => el.key === documentKey), [documentKey, documents]);

    if (!document) {
        return null;
    }
    const modal = <ModalPdfViewer document={viewerDocument} onClose={() => setViewerDocument(undefined)} />;

    if (mode === tableModeEnum.modal) {
        return (
            <Flex align="center" justify="center">
                <Tooltip title={<FormattedMessage id="app.dms.view" />}>
                    <a
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            setViewerDocument(document);
                        }}
                    >
                        <EyeOutlined style={{ fontSize: "16px" }} />
                    </a>
                </Tooltip>
                {modal}
            </Flex>
        );
    }

    const copyIdNumber = async (e: React.MouseEvent<HTMLElement>, id: string) => {
        e.stopPropagation();
        await navigator.clipboard.writeText(id);
        setTitle(<FormattedMessage id="app.dms.idCopied" />);
    };

    const resetTooltip = (open: boolean) => {
        if (!open) {
            setTitle(<FormattedMessage id="app.dms.copyId" />);
        }
    };

    return (
        <Flex
            align="center"
            justify="center"
            gap={4}
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        >
            <Tooltip
                title={title}
                onOpenChange={(open: boolean) => {
                    resetTooltip(open);
                }}
            >
                <Button
                    icon={<CopyOutlined />}
                    type={"text"}
                    shape={"circle"}
                    style={{ padding: 0 }}
                    onClick={async e => {
                        await copyIdNumber(e, document?.key);
                    }}
                />
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.dms.view" />}>
                <Button
                    icon={<EyeOutlined style={{ fontSize: "16px" }} />}
                    // className={styles.actionsItem}
                    type={"text"}
                    shape={"circle"}
                    style={{ padding: 0 }}
                    onClick={() => setViewerDocument(document)}
                />
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.edit" />}>
                <Link
                    to={`/${DmsPaths.ROOT}/${DmsPaths.EDITING}/${document.key}`}
                    state={{ isGroup: false, documentId: document.key }}
                >
                    <Button
                        shape={"circle"}
                        type="text"
                        icon={<EditOutlined style={{ fontSize: "16px" }} />}
                        style={{ padding: 0 }}
                    />
                </Link>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.dms.moveTo" />}>
                <Button
                    icon={<ExportOutlined rotate={180} />}
                    shape={"circle"}
                    type="text"
                    onClick={() => {
                        selectRow(document.key);
                        typeChangeModeToggle(true);
                    }}
                />
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.dms.more" />}>
                <DropdownActions document={document} mode={"table"} node={node} />
            </Tooltip>
            {modal}
        </Flex>
    );
});
