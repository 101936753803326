import React, { FC, useState } from "react";
import { Flex } from "antd";
import { InvoiceFormatsListHeader } from "./components/InvoiceFormatsListHeader/InvoiceFormatsListHeader";
import { InvoiceFormatsList } from "./components/InvoiceFormatsList/InvoiceFormatsList";

export const InvoiceFormatsListLayout: FC = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    return (
        <Flex
            vertical
            style={{
                height: "100%",
            }}
        >
            <InvoiceFormatsListHeader year={year} setYear={setYear} />
            <InvoiceFormatsList year={year} />
        </Flex>
    );
};
