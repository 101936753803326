import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import {
    invoiceSave,
    invoiceDelete,
    invoiceApplyCurrencyRate,
    invoiceConfirm,
} from "../../../scripts/context/mutations/invoicesMutation.graphql";
import { TInvoicesValues } from "@inv/types";
import { GQL } from "@binale-tech/shared";

export class InvoicesApi {
    private static _companyId: string;
    private static _mutator: ReturnType<typeof useGqlMutator>;

    static set companyId(id: string) {
        this._companyId = id;
    }

    static set mutator(mutator: ReturnType<typeof useGqlMutator>) {
        this._mutator = mutator;
    }

    protected static sanitizeInput = (input: TInvoicesValues) => {
        if (!input.customerVat?.vatNumber) {
            delete input.customerVat;
        }
        if (!input.customerTax?.stnr) {
            delete input.customerTax;
        }
    };

    static invoiceSave = async (input: TInvoicesValues) => {
        this.sanitizeInput(input);
        const res = await this._mutator.mutate<"invoiceSave", GQL.IInvoiceSaveInput>({
            mutation: invoiceSave,
            input: { ...input, companyId: this._companyId },
            hideMessages: true,
        });

        return res.invoiceSave?.id;
    };

    static invoiceConfirm = async (input: TInvoicesValues) => {
        this.sanitizeInput(input);
        const res = await this._mutator.mutate<"invoiceConfirm", GQL.IInvoiceConfirmInput>({
            mutation: invoiceConfirm,
            input: { invoice: { ...input, companyId: this._companyId } },
            hideMessages: true,
        });

        return res.invoiceConfirm;
    };

    static invoiceApplyCurrencyRate = async (input: Omit<GQL.IInvoiceApplyCurrencyRateInput, "companyId">) => {
        await this._mutator.mutate<"invoiceApplyCurrencyRate", GQL.IInvoiceApplyCurrencyRateInput>({
            mutation: invoiceApplyCurrencyRate,
            input: { ...input, companyId: this._companyId },
            hideMessages: true,
        });
    };

    static invoiceDelete = async (id: string) => {
        await this._mutator.mutate<"invoiceDelete", GQL.IInvoiceDeleteInput>({
            mutation: invoiceDelete,
            input: { id, companyId: this._companyId },
            hideMessages: true,
        });
    };
}
