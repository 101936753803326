import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { AgGridTable } from "@app/components/shared/AgGridTable";

import {
    CellKeyDownEvent,
    ColumnMovedEvent,
    FilterChangedEvent,
    GetRowIdParams,
    GridApi,
    GridPreDestroyedEvent,
    GridReadyEvent,
    ModelUpdatedEvent,
    PaginationChangedEvent,
    RowClickedEvent,
    SortChangedEvent,
} from "ag-grid-community";
import { Flex } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ITableColumns, ITableDocument, KeysEnum, tableModeEnum, TDmsTableCols, TPaginationPageSize } from "@dms/types";
import { DmsAppContext, DmsAppControlContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { useDocumentTableConfig } from "@dms/modules/DocumentTableModule/hooks/useDocumentColumnConfig";
import { useDocumentTableOptions } from "@dms/modules/DocumentTableModule/hooks/useDocumentTableOptions";
import { SettingsColumns, TableLoader } from "@dms/modules/DocumentTableModule/components";
import { DmsTableCols } from "@dms/modules/DocumentTableModule/consts";
import { GroupButtons } from "@dms/components/GroupButtons/GroupButtons";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import Container from "@app/components/shared/appearance/page/Container";
import { debounce } from "@dms/scripts/helpers";

import "ag-grid-community/styles/ag-theme-balham.css";
import styles from "@dms/modules/DocumentTableModule/DocumentTable.module.scss";
import type { SelectionChangedEvent } from "ag-grid-community/dist/types/core/events";

interface IProps {
    dataSource: ITableDocument[];
}

export const DocumentTableModule: FC<IProps> = ({ dataSource }) => {
    const { userConfig, setFilterConfig, setTableEnabledColumns } = useContext(DmsUserSettingsContext);
    const { activeType, selectedRowKeys, focusedRow } = useContext(DmsAppContext);
    const { selectRows, setFocusedRow } = useContext(DmsAppControlContext);
    const gridApiRef = useRef<AgGridReact>(null);
    const [gridApi, setGridApi] = useState<GridApi>(null);

    const [openSearch, setOpenSearch] = useState(false);

    const { enabledColumns, columnDefs } = useDocumentTableConfig(tableModeEnum.app, activeType);
    const gridOptions = useDocumentTableOptions();

    // console.log({ config: DmsUtils.getFilterConfig(userConfig.filterConfig, activeType) });
    const { sortState, filterState, paginationState } = useMemo(() => {
        const defaultValue: ReturnType<typeof DmsUtils.getFilterConfig> = {
            sortState: null,
            filterState: null,
            paginationState: {
                pageSize: 50,
                currentPage: 0,
            },
        };
        if (!activeType) {
            return defaultValue;
        }

        const config = DmsUtils.getFilterConfig(userConfig.filterConfig, activeType);
        if (config) {
            return config;
        }
        return defaultValue;
    }, [activeType, userConfig.filterConfig]);

    // const updatedGridOptions = useMemo(() => {
    //     return {
    //         ...gridOptions,
    //         paginationPageSize: paginationState?.pageSize ?? gridOptions.paginationPageSize,
    //         animateRows: false,
    //     };
    // }, [gridOptions, paginationState]);

    const onCellKeyDown = useCallback(
        debounce(({ api, node, event }: CellKeyDownEvent<ITableDocument>) => {
            const keyboardEvent = event as KeyboardEvent;
            const currentRowIndex = node.rowIndex;
            const displayedFirstRowIndex = api.getFirstDisplayedRowIndex();
            const displayedLastRowIndex = api.getLastDisplayedRowIndex();

            if (keyboardEvent.key === KeysEnum.ARROW_UP) {
                if (currentRowIndex === displayedFirstRowIndex) {
                    return;
                }

                const prevRowIndex = currentRowIndex - 1;
                if (prevRowIndex >= displayedFirstRowIndex) {
                    const prevNode = api.getDisplayedRowAtIndex(prevRowIndex);
                    if (prevNode) {
                        setFocusedRow(prevNode.data.key);
                    }
                }
            }

            if (keyboardEvent.key === KeysEnum.ARROW_DOWN) {
                if (currentRowIndex === displayedLastRowIndex) {
                    return;
                }

                const nextRowIndex = currentRowIndex + 1;
                if (nextRowIndex <= displayedLastRowIndex) {
                    const nextNode = api.getDisplayedRowAtIndex(nextRowIndex);
                    if (nextNode) {
                        setFocusedRow(nextNode.data.key);
                    }
                }
            }
        }, 100),
        [focusedRow]
    );

    const onSelectionChanged = useCallback(
        (event: SelectionChangedEvent<ITableDocument>) => {
            selectRows(event.api.getSelectedRows().map(v => v.key));
        },
        [selectRows]
    );

    const toggleFloatingFilter = useCallback(() => {
        const { api } = gridApiRef.current;
        const allColumns = api.getColumnDefs();

        if (openSearch) {
            api.setFilterModel({});
        }

        const newColModel = allColumns.map(column => {
            return {
                ...column,
                floatingFilter: !openSearch,
            };
        });
        api.updateGridOptions({ columnDefs: newColModel });
        setOpenSearch(prev => !prev);
    }, [openSearch]);

    const onColumnMoved = useCallback(
        async ({ api, finished }: ColumnMovedEvent) => {
            if (!finished) {
                return;
            }

            const reorderedColumns = api.getColumnState().map(el => el.colId as keyof ITableColumns);

            setTableEnabledColumns(activeType, reorderedColumns);
        },
        [activeType, setTableEnabledColumns]
    );

    // console.log(enabledColumns);

    const onGridReady = useCallback(
        ({ api }: GridReadyEvent<ITableDocument>) => {
            // const t = Date.now();
            // setLoadDataSource(true);
            // if (dataSource.length === 0) {
            //     setLoadDataSource(false);
            // }
            setGridApi(api);
            // console.log("t1", Date.now() - t);

            api.updateGridOptions({
                paginationPageSize: paginationState.pageSize,
            });
            api.paginationGoToPage(paginationState?.currentPage ?? 0);
            // console.log("t2", Date.now() - t);

            if (filterState) {
                api.setFilterModel(filterState);
            }
            // console.log("t3", Date.now() - t);

            if (sortState && sortState.length > 0 && sortState[0].colId !== DmsTableCols.DOCUMENT_DATE) {
                api.applyColumnState({
                    state: sortState,
                    defaultState: { sort: null },
                });
            }
            // console.log("t4", Date.now() - t, sortState);

            const node = api.getRowNode(focusedRow);
            if (node && node.data.key === focusedRow) {
                node.updateData({ ...node.data, isFocused: true });
                api.setFocusedCell(node.rowIndex, DmsTableCols.ACTIONS);
                api.ensureIndexVisible(node.rowIndex, "middle");
                node.setSelected(selectedRowKeys.includes(node.data.key));
            }
            // console.log("t5", Date.now() - t);

            api.forEachNode(row => row.setSelected(selectedRowKeys.includes(row.data.key)));
            // setLoadDataSource(false);
            // console.log("t6", Date.now() - t);
        },
        [dataSource, selectedRowKeys, focusedRow, filterState, sortState, paginationState]
    );

    const onModelUpdated = useCallback(({ api }: ModelUpdatedEvent<ITableDocument>) => {
        // console.log("onModelUpdated");
        // setLoadDataSource(false);
        api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [DmsTableCols.ROW_NUMBER],
        });
    }, []);

    const onSortChanged = useCallback(
        ({ api }: SortChangedEvent<ITableDocument>) => {
            api.refreshCells({
                force: true,
                suppressFlash: true,
                columns: [DmsTableCols.ROW_NUMBER],
            });

            const sortState = api
                .getColumnState()
                .filter(col => col.sort)
                .map(col => ({
                    colId: col.colId as TDmsTableCols,
                    sort: col.sort,
                }));

            // console.log("onSortChanged", sortState);
            setFilterConfig(activeType, {
                sortState,
            });
        },
        [activeType]
    );

    const onFilterChanged = useCallback(
        ({ api }: FilterChangedEvent<ITableDocument>) => {
            // console.log("onFilterChanged");
            setFilterConfig(activeType, {
                filterState: api.getFilterModel(),
            });
        },
        [filterState, activeType]
    );

    const onPaginationChanged = useCallback(
        ({ api }: PaginationChangedEvent<ITableDocument>) => {
            const pageSize = api.paginationGetPageSize() as TPaginationPageSize;
            const currentPage = api.paginationGetCurrentPage();

            // console.log("onPaginationChanged");
            setFilterConfig(activeType, {
                paginationState: {
                    pageSize,
                    currentPage,
                },
            });
        },
        [setFilterConfig, activeType]
    );

    const onRowClicked = useCallback(
        ({ api, node, data }: RowClickedEvent<ITableDocument>) => {
            setFocusedRow(data.key);
            api.forEachNode(row => {
                if (row.data.isFocused) {
                    row.updateData({ ...row.data, isFocused: false });
                }
            });

            node.updateData({ ...data, isFocused: true });
        },
        [focusedRow]
    );

    const onGridPreDestroyed = useCallback((params: GridPreDestroyedEvent) => {
        setGridApi(null);
    }, []);

    const getRowId = useCallback((params: GetRowIdParams<ITableDocument>) => {
        return params.data.key;
    }, []);

    useEffect(() => {
        if (!gridApi) {
            return;
        }

        if (selectedRowKeys.length === 0 && gridApi.getSelectedRows().length !== 0) {
            gridApi.deselectAll();

            // console.log("selectedRowKeys");
            setFilterConfig(activeType, {
                selectedFilter: false,
            });
        }
    }, [gridApi, selectedRowKeys]);

    useEffect(() => {
        setGridApi(null);
    }, [activeType]);

    return (
        <Flex vertical gap={16} style={{ height: "100%", paddingTop: 16, position: "relative" }}>
            <Flex align="center" gap={8} className={styles.columnActions}>
                <SearchOutlined style={{ zIndex: 10 }} onClick={toggleFloatingFilter} />
                {enabledColumns ? (
                    <SettingsColumns
                        activeType={activeType}
                        enabledColumns={enabledColumns}
                        className={styles.columnSetting}
                    />
                ) : null}
            </Flex>

            <div className={styles.select}>
                {selectedRowKeys.length > 1 ? <GroupButtons documentData={dataSource} /> : null}
            </div>
            <Container absolute flex>
                {(w, h) => (
                    <AgGridTable
                        ref={gridApiRef}
                        wrapperStyles={{ height: h }}
                        rowData={dataSource}
                        gridOptions={gridOptions}
                        columnDefs={columnDefs}
                        tableKey={activeType.join(",")}
                        onGridPreDestroyed={onGridPreDestroyed}
                        onColumnMoved={onColumnMoved}
                        onModelUpdated={onModelUpdated}
                        onCellKeyDown={onCellKeyDown}
                        getRowId={getRowId}
                        onGridReady={onGridReady}
                        onSortChanged={onSortChanged}
                        onRowClicked={onRowClicked}
                        onSelectionChanged={onSelectionChanged}
                        onFilterChanged={onFilterChanged}
                        onPaginationChanged={onPaginationChanged}
                        // loading={loadDataSource}
                        loadingOverlayComponent={TableLoader}
                    />
                )}
            </Container>
        </Flex>
    );
};
