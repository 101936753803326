import { IMessagesType } from "../intl/translations/de";

export class BinaleError extends Error {
    readonly translationKey?: keyof IMessagesType;
    readonly values?: Record<string, string>;

    constructor(message: string, translationKey: keyof IMessagesType, values?: Record<string, string>) {
        super(message);
        this.translationKey = translationKey;
        this.values = values;
    }
}
