import { gql, useApolloClient } from "@apollo/client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GQL } from "@binale-tech/shared";
import { CompanyContext } from "../../scripts/context/CompanyContext";

export const getInvoiceNumberFormats = gql`
    query getInvoiceNumberFormats($companyId: ID!, $year: Int!) {
        invoiceNumberFormats(companyId: $companyId, year: $year) {
            id
            year
            companyId
            numberLen
            number
            prefix
            suffix
            description

            createdAt
            updatedAt
            createdBy {
                uid
            }
        }
    }
`;

type FormatApi = {
    formatsList: GQL.IInvoiceNumberFormat[];
    refetch: () => void;
};
export const useInvoiceFormats = (year: number): FormatApi => {
    const client = useApolloClient();
    const { companyGQL } = useContext(CompanyContext);
    const [formats, setFormats] = useState<GQL.IInvoiceNumberFormat[]>([]);
    const refetch = useCallback(() => {
        if (!companyGQL?.id) {
            return;
        }
        client
            .query<Pick<GQL.IQuery, "invoiceNumberFormats">>({
                fetchPolicy: "network-only",
                query: getInvoiceNumberFormats,
                variables: {
                    companyId: companyGQL?.id,
                    year,
                },
            })
            .then(res => {
                setFormats(res.data.invoiceNumberFormats);
            });
    }, [client, year, companyGQL?.id]);
    useEffect(() => {
        refetch();
    }, [refetch]);
    return useMemo(() => ({ formatsList: formats, refetch }), [formats, refetch]);
};
