import React, { ComponentProps, FC, useContext, useEffect, useRef, useState } from "react";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { Button, Descriptions, Empty, Flex, Modal, Space, Spin, Tooltip } from "antd";
import { GQL } from "@binale-tech/shared";
import { FormattedMessage } from "react-intl";
import { formatDefault } from "@dms/scripts/helpers";
import { DownloadOutlined, FileExcelOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ContactsContext } from "../../../../scripts/context/ContactsContext";
import { saveAs } from "file-saver";
import {
    fetchAndGetInvoiceData,
    XRechnungResponse,
} from "../../../../scripts/models/converters/DmsAccountingConverter";

import styles from "./PreviewInvoice.module.scss";

type Props = {
    fileId: string;
    productKey?: GQL.IProductKey;
};

export const PreviewInvoice: FC<Props> = ({ fileId, productKey }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState<XRechnungResponse>(null);
    const { documentsKV } = useContext(DmsDataContext);
    const { contacts } = useContext(ContactsContext);
    const file = documentsKV[fileId];
    const contactsRef = useRef(contacts);

    useEffect(() => {
        contactsRef.current = contacts;
    }, [contacts]);

    useEffect(() => {
        if (!file) {
            return;
        }
        fetchAndGetInvoiceData(file.fileUrl, productKey, contactsRef.current)
            .then(v => setData(v))
            .finally(() => setIsLoading(false));
    }, [file, productKey]);

    if (!file) {
        return null;
    }

    const items: ComponentProps<typeof Descriptions>["items"] = [
        {
            key: "supplier",
            span: 2,
            label: <FormattedMessage id="app.titles.contacts.partner" />,
            children: (
                <div>
                    <Flex justify={"space-between"}>
                        <span>
                            Name <sup>BT-27</sup>
                        </span>
                        {data?.raw?.supplier?.name && <span>{data.raw.supplier.name}</span>}
                    </Flex>
                    <Flex justify={"space-between"}>
                        <span>
                            <FormattedMessage id={"app.fields.vatNb"} /> <sup>BT-31</sup>
                        </span>
                        {data?.raw?.supplier?.vatId && <span>{data.raw.supplier.vatId}</span>}
                    </Flex>
                    <br />
                    <Flex justify={"space-between"}>
                        <div>
                            <div>
                                <b>
                                    <FormattedMessage id={"app.fields.address"} />{" "}
                                </b>
                                <sup>BG-5</sup>
                            </div>
                            {data?.raw?.supplier?.addressLines && (
                                <>
                                    {data.raw.supplier.addressLines.map(s => (
                                        <div key={s}>{s}</div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div>
                            <div>
                                <b>
                                    <FormattedMessage id={"app.titles.communication"} />{" "}
                                </b>
                                <sup>BG-6</sup>
                            </div>
                            {data?.raw?.supplier?.communications && (
                                <>
                                    {data.raw.supplier.communications.map(s => (
                                        <div key={s}>{s}</div>
                                    ))}
                                </>
                            )}
                        </div>
                    </Flex>
                </div>
            ),
        },
        {
            key: "buyer",
            span: 2,
            label: <FormattedMessage id="app.titles.contacts.client" />,
            children: (
                <div>
                    <Flex justify={"space-between"}>
                        <span>
                            Name <sup>BT-44</sup>
                        </span>
                        {data?.raw?.buyer?.name && <span>{data.raw.buyer.name}</span>}
                    </Flex>
                    <Flex justify={"space-between"}>
                        <span>
                            <FormattedMessage id={"app.fields.vatNb"} /> <sup>BT-48</sup>
                        </span>
                        {data?.raw?.buyer?.vatId && <span>{data.raw.buyer.vatId}</span>}
                    </Flex>
                    <br />
                    <Flex justify={"space-between"}>
                        <div>
                            <div>
                                <b>
                                    <FormattedMessage id={"app.fields.address"} />
                                </b>
                                <sup>BG-8</sup>
                            </div>
                            {data?.raw?.buyer?.addressLines && (
                                <>
                                    {data.raw.buyer.addressLines.map(s => (
                                        <div key={s}>{s}</div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div>
                            <div>
                                <b>E-mail</b>
                                <sup>BT-49</sup>
                            </div>
                            {data?.raw?.buyer?.email && <div>{data?.raw?.buyer?.email}</div>}
                        </div>
                    </Flex>
                </div>
            ),
        },
        {
            key: "date",
            span: 1,
            label: <FormattedMessage id={"app.fields.invoice"} />,
            children: data?.generated?.document && (
                <div>
                    <Flex justify={"space-between"}>
                        <span>
                            Nummer <sup>BT-1</sup>
                        </span>
                        <span>{data.generated.document.documentNumber}</span>
                    </Flex>
                    <Flex justify={"space-between"}>
                        <span>
                            <FormattedMessage id={"app.fields.date"} />
                            <sup>BT-2</sup>
                        </span>
                        <span>{data.generated.document.documentDate}</span>
                    </Flex>
                    {data.raw.deliveryDate && (
                        <Flex justify={"space-between"}>
                            <span>
                                <FormattedMessage id={"app.invoice.deliveryDate"} />
                                <sup>BT-72</sup>
                            </span>
                            <span>{data.raw.deliveryDate}</span>
                        </Flex>
                    )}
                </div>
            ),
        },
        {
            key: "payment",
            span: 3,
            label: "Zahlungsdetails",
            children: (
                <div>
                    {data?.raw?.supplier?.IBANAccountName && (
                        <Flex justify={"space-between"}>
                            <span>
                                Kontoinhaber <sup>BT-85</sup>
                            </span>
                            {data.raw.supplier.IBANAccountName}
                        </Flex>
                    )}
                    {data?.raw?.supplier?.IBAN && (
                        <Flex justify={"space-between"}>
                            <span>
                                IBAN <sup>BT-84</sup>
                            </span>
                            {data.raw.supplier.IBAN}
                        </Flex>
                    )}
                    {data?.raw?.supplier?.BIC && (
                        <Flex justify={"space-between"}>
                            <span>
                                BIC <sup>BT-86</sup>
                            </span>
                            {data.raw.supplier.BIC}
                        </Flex>
                    )}
                    {data?.raw?.paymentReference && (
                        <Flex justify={"space-between"}>
                            <span>
                                Verwendungszweck <sup>BT-83</sup>
                            </span>
                            {data.raw.paymentReference}
                        </Flex>
                    )}
                    {data?.raw?.paymentType === "1" && <span>Zahlungsmethode wurde nicht angegeben</span>}
                </div>
            ),
        },
        {
            key: "payment_terms",
            span: 4,
            label: (
                <span>
                    Zahlungsbedingungen <sup>BT-20</sup>
                </span>
            ),
            children: data?.raw?.lines && (
                <div>
                    {data?.raw?.paymentDescription && <p>{data.raw.paymentDescription}</p>}
                    {data?.raw?.paymentDueDate && (
                        <Flex justify={"space-between"} gap={10}>
                            <div style={{ textWrap: "nowrap" }}>
                                Fälligkeitsdatum&nbsp;<sup>BT-9</sup>
                            </div>
                            <div>{data.raw.paymentDueDate}</div>
                        </Flex>
                    )}
                </div>
            ),
        },
        {
            key: "r_pos",
            span: 4,
            label: (
                <span>
                    Rechnungspositionen <sup>BG-25</sup>
                </span>
            ),
            children: data?.raw?.lines && (
                <div>
                    {data.raw.lines.map((l, index) => (
                        <Flex
                            justify={"space-between"}
                            key={"position" + index}
                            style={{ paddingBottom: 2, paddingTop: 2, borderBottom: "1px solid #ebebeb" }}
                        >
                            <span>{l.text}</span>
                            <code>
                                {formatDefault(l.netto)} | {l.tax}% | BU{l.bu} |{" "}
                                {formatDefault((l.netto * (100 + l.tax)) / 100)}
                            </code>
                        </Flex>
                    ))}
                </div>
            ),
        },
        {
            key: "brutto",
            span: 1,
            label: (
                <span>
                    <b>
                        <FormattedMessage id={"app.fields.brutto"} />{" "}
                    </b>
                    <sup>BT-112</sup> / <FormattedMessage id={"app.fields.currency"} /> <sup>BT-5</sup>
                </span>
            ),
            children: data?.generated?.record && (
                <p>
                    {formatDefault(data.generated.record.getBrutto())} {data.raw.currency ?? ""}
                </p>
            ),
        },
        {
            key: "rec_pos",
            span: 3,
            label: (
                <span>
                    <strong>Buchungspositionen</strong> <sup>BG-23</sup>
                </span>
            ),
            children: data?.generated?.record && (
                <div>
                    {data.generated?.record.items.map((i, index) => (
                        <Flex
                            justify={"space-between"}
                            key={"position_rec" + index}
                            style={{ paddingBottom: 2, paddingTop: 2, borderBottom: "1px solid #ebebeb" }}
                        >
                            <span>
                                {i.text}{" "}
                                <Tooltip
                                    title={
                                        <div>
                                            <Flex justify={"space-between"}>
                                                <span>
                                                    Netto <sup>BT-116</sup>
                                                </span>
                                                <div style={{ width: 10 }}></div>
                                                <span>{formatDefault(data.raw.taxItems[index].nettoAmount)}</span>
                                            </Flex>
                                            <Flex justify={"space-between"}>
                                                <span>
                                                    % <sup>BT-119</sup> / Steuer <sup>BT-117</sup>
                                                </span>
                                                <div style={{ width: 10 }}></div>
                                                <span>
                                                    {data.raw.taxItems[index].tax}% /{" "}
                                                    {formatDefault(data.raw.taxItems[index].taxAmount)}
                                                </span>
                                            </Flex>
                                        </div>
                                    }
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </span>
                            <code>
                                BU{i.bu} | {formatDefault(i.brutto)}
                            </code>
                        </Flex>
                    ))}
                </div>
            ),
        },
    ].filter(Boolean);
    return (
        <div className={styles.previewInvoice}>
            <Spin spinning={isLoading}>
                {data && (
                    <Space direction={"vertical"} style={{ width: "100%" }}>
                        <Flex justify={"space-between"} align={"center"}>
                            <div>
                                <b>
                                    <FormattedMessage id={"app.titles.Contact"} />:
                                </b>
                                <span>&nbsp;</span>
                                {data?.generated?.record?.partner ? (
                                    <span>{data.generated.record.partner.name}</span>
                                ) : (
                                    <span>N/A</span>
                                )}
                            </div>
                            <Button icon={<FileExcelOutlined />} onClick={() => setIsModalOpen(true)}>
                                E-Rechnung XML anzeigen
                            </Button>
                        </Flex>
                        <Descriptions items={items} layout="vertical" bordered size={"small"} column={{ xs: 4 }} />
                        <Modal
                            title={
                                <Space>
                                    <div>E-Rechnung XML</div>
                                    <Button
                                        onClick={() => {
                                            const enc = new TextEncoder();
                                            const uint8Array = enc.encode(data.rawXml);
                                            const blob = new Blob([uint8Array], { type: "application/xml" });
                                            const filename = [
                                                data.generated.document.documentDate.split(".").reverse().join("."),
                                                data.generated.document.documentNumber,
                                            ]
                                                .filter(Boolean)
                                                .join("_");
                                            saveAs(blob, filename + ".xml");
                                        }}
                                        icon={<DownloadOutlined />}
                                    >
                                        <FormattedMessage id={"app.button.download"} />
                                        XML
                                    </Button>
                                </Space>
                            }
                            footer={null}
                            width={1000}
                            onCancel={() => setIsModalOpen(false)}
                            open={isModalOpen}
                        >
                            <pre>{data.rawXml}</pre>
                        </Modal>
                    </Space>
                )}
                {!data && <Empty />}
            </Spin>
        </div>
    );
};
