import { GridOptions, ICellRendererParams } from "ag-grid-community";
import { baseOptions } from "@app/components/shared/AgGridTable/options/baseOptions";
import React from "react";
import { GQL } from "@binale-tech/shared";

export const gridOptions: GridOptions<GQL.IInvoiceNumberFormat> = {
    ...baseOptions,
    defaultColDef: {
        // ...baseOptions.defaultColDef,
        filter: "agTextColumnFilter",
        enableCellChangeFlash: true,
        suppressMovable: true,
        suppressHeaderFilterButton: true,
        suppressFloatingFilterButton: true,
        sortable: false,
        cellRenderer: ({ value }: ICellRendererParams<GQL.IInvoiceNumberFormat>) => {
            return <div>{value ?? <>&nbsp;</>}</div>;
        },
    },
    cellFlashDuration: 0,
    rowSelection: undefined,
    selectionColumnDef: null,
    suppressCellFocus: true,
    suppressScrollOnNewData: true,
    suppressMultiSort: true,
};
