import { DmsType, IDocumentType, IInitColumnsType, ITableColumns, TFilterConfigItem } from "@dms/types";
import { DmsTypeOptions } from "@dms/configs/constants";
import { DmsTableCols } from "@dms/modules/DocumentTableModule/consts";

export class AppConfigUtils {
    private static initEnabledColumns: (keyof ITableColumns)[] = [
        DmsTableCols.DRAG,
        DmsTableCols.ROW_NUMBER,
        DmsTableCols.IS_ATTACHED,
        DmsTableCols.DOCUMENT_DATE,
        DmsTableCols.PARTNER,
        DmsTableCols.DOCUMENT_NUMBER,
        DmsTableCols.DOCUMENT_AMOUNT,
        DmsTableCols.CURRENCY,
        DmsTableCols.DESCRIPTION,
        DmsTableCols.CREATED_AT,
        DmsTableCols.VAT_NB,
        DmsTableCols.HAS_XRECHNUNG,
        DmsTableCols.ACTIONS,
    ];

    public static getInitColumns(type: keyof IInitColumnsType): (keyof ITableColumns)[] {
        const customConfigs: Record<string, (keyof ITableColumns)[]> = {
            all_documents: [
                DmsTableCols.TYPE,
                DmsTableCols.SUB_TYPE,
                DmsTableCols.DOCUMENT_AMOUNT,
                DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT,
                DmsTableCols.DOCUMENT_AMOUNT_TYPE,
            ],
            [DmsType.new_documents]: [DmsTableCols.CREATED_BY],
        };
        const defaultExtras = [
            DmsTableCols.DOCUMENT_AMOUNT,
            DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT,
            DmsTableCols.DOCUMENT_AMOUNT_TYPE,
        ];

        return [...this.initEnabledColumns, ...(customConfigs[type] || defaultExtras)];
    }

    static getDefaultSubTypeItemKey = (typeKey: string, defaultSubTypeKey: string) => {
        return `${typeKey}-${defaultSubTypeKey}`;
    };

    private static defaultConfig: TFilterConfigItem = {
        modalModeConfig: {
            sortState: null,
            filterState: null,
        },
        year: "all",
        month: -1,
        isDraftSelected: false,
        sortState: null,
        filterState: null,
        paginationState: {
            pageSize: 50,
            currentPage: 0,
        },
    };
    static getDefaultUserConfig = (
        documentTypes: IDocumentType[]
    ): {
        defaultFilterConfig: Map<string, TFilterConfigItem>;
        defaultEnabledColumns: Map<string, (keyof ITableColumns)[]>;
    } => {
        const defaultFilterConfig = new Map();
        const defaultEnabledColumns = new Map();

        documentTypes.forEach(type => {
            const { id: typeId, subTypes } = type;

            if (subTypes && subTypes.length) {
                subTypes.forEach(subType => {
                    const { id: subTypeId } = subType;
                    defaultFilterConfig.set([subTypeId, typeId].toString(), this.defaultConfig);
                    defaultEnabledColumns.set([subTypeId, typeId].toString(), this.getInitColumns(typeId));
                });
            } else {
                defaultFilterConfig.set(typeId, this.defaultConfig);
                defaultEnabledColumns.set(typeId, this.getInitColumns(typeId));
            }
        });

        defaultFilterConfig.set("all_documents", this.defaultConfig);
        defaultEnabledColumns.set("all_documents", this.getInitColumns("all_documents"));

        DmsTypeOptions.forEach(el => {
            if (el.defaultSubtypes) {
                el.defaultSubtypes.forEach(defaultSubtype => {
                    if (el.value && defaultSubtype.value) {
                        defaultFilterConfig.set(
                            [this.getDefaultSubTypeItemKey(el.value, defaultSubtype.value), el.value].toString(),
                            this.defaultConfig
                        );
                        defaultEnabledColumns.set(
                            [this.getDefaultSubTypeItemKey(el.value, defaultSubtype.value), el.value].toString(),
                            this.getInitColumns(el.value)
                        );
                    }
                });
            }
        });
        return { defaultFilterConfig, defaultEnabledColumns };
    };

    static getDefaultConfig = (type: keyof IInitColumnsType) => {
        const tableCols = this.getInitColumns(type);

        return {
            defaultFilterConfig: this.defaultConfig,
            defaultColumnsConfig: tableCols,
        };
    };

    static concatColumnConfig = (
        documentTypes: IDocumentType[],
        persistedEnabledColumns?: Record<string, (keyof ITableColumns)[]>
    ): Map<string, (keyof ITableColumns)[]> => {
        const { defaultEnabledColumns } = this.getDefaultUserConfig(documentTypes);

        if (Object.keys(persistedEnabledColumns ?? {}).length === 0) {
            return defaultEnabledColumns;
        }

        const res = new Map<string, (keyof ITableColumns)[]>();
        defaultEnabledColumns.forEach((value, key) => {
            if (key in persistedEnabledColumns) {
                res.set(key, persistedEnabledColumns[key]);
            } else {
                res.set(key, [...value]);
            }
        });
        return res;
    };

    // static concatFilterConfig = (
    //     documentTypes: IDocumentType[],
    //     dbFilterConfig?: Record<string, TFilterConfigItem>
    // ): Map<string, TFilterConfigItem> => {
    //     const { defaultFilterConfig } = this.getDefaultUserConfig(documentTypes);
    //     if (Object.keys(dbFilterConfig ?? {}).length === 0) {
    //         return defaultFilterConfig;
    //     }
    //
    //     const updatedConfig = new Map(defaultFilterConfig);
    //     Object.entries(dbFilterConfig).forEach(([key, config]) => {
    //         if (updatedConfig.has(key)) {
    //             const currentValue = updatedConfig.get(key);
    //             if (currentValue) {
    //                 updatedConfig.set(key, {
    //                     ...currentValue,
    //                     paginationState: { ...config.paginationState },
    //                 });
    //             }
    //         }
    //     });
    //
    //     return updatedConfig;
    // };
}
