import AnsichtView from "../../../views/productSharedComponents/AnsichtView";
import Menu, { MenuProps } from "antd/es/menu";
import React from "react";
import { AppRoutes } from "scripts/routing/routeConstants";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { Link, useLocation } from "react-router-dom";
import { Modal } from "antd";

type Props = { menuProps: MenuProps };
export const ModulePOSLinks: React.FC<Props> = ({ menuProps }) => {
    const { pathname = "" } = useLocation();
    const [showSettings, setSettings] = React.useState(false);
    return (
        <>
            <Menu
                selectedKeys={[pathname]}
                {...menuProps}
                items={[
                    {
                        key: AppRoutes.pos,
                        label: (
                            <Link to={AppRoutes.pos}>
                                <FormattedMessage id="app.titles.POS" />
                            </Link>
                        ),
                    },
                    {
                        key: AppRoutes.posTemplates,
                        label: (
                            <Link to={AppRoutes.posTemplates}>
                                <FormattedMessage id="app.titles.templates" />
                            </Link>
                        ),
                    },
                    {
                        key: "settings",
                        onClick: () => setSettings(true),
                        label: <FormattedMessage id="app.titles.settings" />,
                    },
                ]}
            />
            <Modal
                open={showSettings}
                onCancel={() => setSettings(false)}
                footer={false}
                width={900}
                focusTriggerAfterClose={false}
                destroyOnClose
            >
                <AnsichtView pk={GQL.IProductKey.Pos} />
            </Modal>
        </>
    );
};
