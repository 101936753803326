import React, { FC, useCallback, useContext, useMemo, useRef, useState } from "react";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { gridOptions } from "./config/gridOptions";
import Container from "@app/components/shared/appearance/page/Container";
import type { ColDef, ColGroupDef } from "ag-grid-community/dist/types/core/entities/colDef";
import { GQL } from "@binale-tech/shared";
import { useInvoiceFormats } from "@inv/hooks/useInvoiceFormats";
import { ICellRendererParams, IHeaderParams } from "ag-grid-community";
import { FormattedMessage } from "react-intl";
import { Button, Flex, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import InvoiceFormatForm from "@inv/layouts/InvoiceFormatsListLayout/components/InvoiceFormatForm/InvoiceFormatForm";
import { gql } from "@apollo/client";
import { useGqlMutator } from "../../../../../scripts/graphql/useGqlMutator";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { AgGridReact } from "ag-grid-react";

type CellActionsProps = { onEdit: () => void; onDelete: () => void; disabled: boolean };

const HeaderActions: React.FC<IHeaderParams<GQL.IInvoiceNumberFormat>> = () => {
    return (
        <div>
            <FormattedMessage id={"app.fields.actions"} />
        </div>
    );
};

const CellActions: React.FC<ICellRendererParams<GQL.IInvoiceNumberFormat> & CellActionsProps> = ({
    onEdit,
    onDelete,
    disabled,
}) => {
    return (
        <Flex gap={5} align={"center"} style={{ height: "100%" }}>
            <Button icon={<EditOutlined />} shape={"circle"} onClick={onEdit} />
            <Popconfirm
                disabled={disabled}
                onConfirm={onDelete}
                title={<FormattedMessage id="app.confirmation.header" />}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
            >
                <Button icon={<DeleteOutlined />} shape={"circle"} disabled={disabled} />
            </Popconfirm>
        </Flex>
    );
};

const invoiceNumberFormatDelete = gql`
    mutation invoiceNumberFormatDelete($input: InvoiceNumberFormatDeleteInput!) {
        invoiceNumberFormatDelete(input: $input)
    }
`;

export const InvoiceFormatsList: FC<{ year: number }> = ({ year }) => {
    const { invoicesMap } = useContext(InvoicesDataContext);
    const { formatsList: rowData, refetch } = useInvoiceFormats(year);
    const mutator = useGqlMutator();
    const apiRef = useRef<AgGridReact>();
    const [formData, setFormData] = useState<GQL.IInvoiceNumberFormat>();
    const onClose = useCallback(() => {
        setFormData(undefined);
        setTimeout(() => refetch(), 500);
    }, [refetch]);

    const disabledIds = useMemo(() => {
        const set = new Set();
        for (const [, inv] of invoicesMap) {
            if (inv.invoiceNumberFormatId) {
                set.add(inv.invoiceNumberFormatId);
            }
        }
        return set;
    }, [invoicesMap]);

    const onEdit = useCallback((data: GQL.IInvoiceNumberFormat) => {
        setFormData(data);
    }, []);
    const onDelete = useCallback(
        (data: GQL.IInvoiceNumberFormat) => {
            const { id, companyId } = data;
            mutator
                .mutate<"invoiceNumberFormatDelete", GQL.IInvoiceNumberFormatDeleteInput>({
                    mutation: invoiceNumberFormatDelete,
                    input: { id, companyId },
                })
                .then(() => setTimeout(() => refetch(), 500));
        },
        [mutator, refetch]
    );

    const columns: (ColDef<GQL.IInvoiceNumberFormat> | ColGroupDef<GQL.IInvoiceNumberFormat>)[] = useMemo(() => {
        return [
            {
                headerName: "Jahr",
                field: "year",
                width: 80,
                sortable: true,
                comparator: (a, b) => a - b,
            },
            {
                headerName: "Length",
                field: "numberLen",
                width: 80,
                sortable: false,
            },
            {
                headerName: "Prefix",
                field: "prefix",
                width: 150,
                sortable: false,
            },
            {
                headerName: "Number",
                field: "number",
                width: 150,
                sortable: false,
            },
            {
                headerName: "Suffix",
                field: "suffix",
                width: 150,
                sortable: false,
            },
            {
                headerName: "CreatedAt",
                field: "createdAt",
                width: 200,
                sortable: true,
                comparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
            },
            {
                headerName: "Description",
                field: "description",
                width: 350,
                sortable: false,
            },
            {
                headerComponent: HeaderActions,
                cellRenderer: CellActions,
                cellRendererParams: (params: ICellRendererParams<GQL.IInvoiceNumberFormat>): CellActionsProps => ({
                    disabled: disabledIds.has(params.data.id),
                    onEdit: () => onEdit(params.data),
                    onDelete: () => onDelete(params.data),
                }),
                field: "actions" as any,
                floatingFilter: false,
                resizable: false,
                suppressMovable: true,
                suppressNavigable: true,
                width: 100,
                pinned: "right",
                sortable: false,
                lockPosition: true,
            },
        ] satisfies ColDef<GQL.IInvoiceNumberFormat>[];
    }, [disabledIds, onDelete, onEdit]);

    // const onGridReady = useCallback(({ api }: GridReadyEvent) => {
    //     api.applyColumnState({
    //         state: [{ colId: InvoicesListColumn.INVOICE_DATE, sort: "desc" }],
    //         defaultState: { sort: null },
    //     });
    // }, []);

    // const onRowDataUpdated = useCallback(({ api }: RowDataUpdatedEvent<TInvoiceTableItem>) => {
    //     const rowData = api.getRenderedNodes().map(node => node.data);
    //     const pinnedRow: Partial<TInvoiceTableItem> = { ...invoicesListTotal(rowData), id: "total" };
    //     api.updateGridOptions({
    //         pinnedBottomRowData: [pinnedRow],
    //     });
    // }, []);

    // const onSortChanged = useCallback(({ api }: SortChangedEvent<TInvoiceTableItem>) => {
    //     api.refreshCells({
    //         force: true,
    //         suppressFlash: true,
    //         columns: [InvoicesListColumn.INVOICE_ROW_NUMBER],
    //     });
    //     api.forEachNode(node => {
    //         if (node.data.isExpanded) {
    //             node.setData({ ...node.data, isExpanded: false });
    //         }
    //         if (node.data.isChildRow) {
    //             api.applyTransaction({ remove: [node.data] });
    //         }
    //     });
    // }, []);

    return (
        <>
            <Container absolute flex>
                {(w, h) => (
                    <AgGridTable
                        ref={apiRef}
                        columnDefs={columns}
                        gridOptions={gridOptions}
                        rowData={rowData}
                        // onRowDataUpdated={onRowDataUpdated}
                        // onGridReady={onGridReady}
                        // components={components}
                        // wrapperStyles={{ height: h }}
                        // getRowId={params => {
                        //     return params.data.id;
                        // }}
                        // onSortChanged={onSortChanged}
                    />
                )}
            </Container>
            <InvoiceFormatForm
                onClose={onClose}
                open={Boolean(formData)}
                data={formData}
                disabled={disabledIds.has(formData?.id)}
            />
        </>
    );
};
