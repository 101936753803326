import "pdfjs-dist/build/pdf.worker.entry";

import { GQL } from "@binale-tech/shared";
import { saveAs } from "file-saver";
import { deleteObject, getDownloadURL, getMetadata, ref as refStorage, uploadBytesResumable } from "firebase/storage";
import JSZip from "jszip";
import * as pdfjs from "pdfjs-dist";
import { Dispatch, SetStateAction } from "react";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { storage } from "../../../scripts/api/firebase/firebase";
import {
    documentCreate,
    documentsDelete,
    documentsTypeUpdate,
    documentsUpdate,
} from "../../../scripts/context/mutations/documentMutations.graphql";
import { logger } from "../../../scripts/infrastructure/logger";
import { PdfUtils } from "@dms/scripts/utils/PdfUtils";
import { DmsDefaultSubType, DmsType, IDocumentEnriched, IRemoverFile, TUploadFile, UploadFileStatus } from "@dms/types";
import { convertDocumentToUpdateInput } from "@dms/scripts/helpers/convertDocumentToUpdateInput";
import { ALL_DOCUMENTS } from "@dms/configs/constants";
import { enrichDocumentInput } from "@dms/scripts/helpers";
import { XRechnungResponse } from "../../../scripts/models/converters/DmsAccountingConverter";

export type TFileUploadEnrichedData = {
    fileData: Pick<TUploadFile, "file" | "hash" | "isUploaded">;
    documentType: { type: DmsType | typeof ALL_DOCUMENTS; subType?: string };
    companyData: GQL.ICompany;
    xRechnungData?: XRechnungResponse;
};

type DocumentsApiCreateInput = {
    fileUploadData: TFileUploadEnrichedData;
    mutator: ReturnType<typeof useGqlMutator>;
    setFileStatus: (hash: string, data: Pick<TUploadFile, "status" | "error" | "isUploaded">) => void;
};

export class DocumentsApi {
    private static readonly maxFileSize = 20971520;
    private static readonly NS = "files";
    private static readonly fileFolderName = "pdf";
    private static readonly previewFolderName = "preview";

    static createDocument = async (arg: DocumentsApiCreateInput) => {
        const { fileUploadData, mutator, setFileStatus } = arg;
        const { fileData, documentType } = fileUploadData;

        const inputTemplate = await this.uploadFile({
            fileUploadData,
            mutator,
            setFileStatus,
        }).catch(err => {
            setFileStatus(fileData.hash, {
                error: err.message,
                status: UploadFileStatus.ERROR,
                isUploaded: false,
            });
            return Promise.reject(err);
        });

        if (!inputTemplate) {
            return null;
        }

        const input: GQL.IDocumentCreateInput = {
            ...inputTemplate,
            type: documentType.type,
            subType: documentType.subType,
        };

        if (fileUploadData?.xRechnungData?.generated) {
            enrichDocumentInput(input, fileUploadData.xRechnungData.generated.document);
            input.hasXRechnung = true;
        }

        await mutator.mutate({
            mutation: documentCreate,
            input,
            hideMessages: true,
        });

        setFileStatus(fileData.hash, {
            error: false,
            status: UploadFileStatus.DONE,
            isUploaded: true,
        });

        return { fileRes: { ...fileData, isUploaded: true }, url: inputTemplate.fileUrl };
    };

    static uploadFile = async (arg: DocumentsApiCreateInput): Promise<GQL.IDocumentCreateInput> => {
        const { fileUploadData, setFileStatus } = arg;
        return new Promise((resolve, reject) => {
            const { file, hash } = fileUploadData.fileData;
            const { companyData } = fileUploadData;

            if (file.size > this.maxFileSize) {
                setFileStatus(hash, {
                    error: `Size limit ${this.maxFileSize} bytes`,
                    status: UploadFileStatus.ERROR,
                    isUploaded: false,
                });

                reject(new Error(`Size limit ${this.maxFileSize} bytes`));
                return;
            }

            const name = hash + ".pdf";
            const { id: companyId } = companyData;

            const fileRef = refStorage(storage, [this.NS, companyId, this.fileFolderName, name].join("/"));
            const uploadTask = uploadBytesResumable(fileRef, file, {
                contentType: "application/pdf",
            });

            setFileStatus(hash, {
                error: false,
                status: UploadFileStatus.PROGRESS,
                isUploaded: false,
            });

            uploadTask.on(
                "state_changed",
                () => {},
                (error: { message: string }) => {
                    setFileStatus(hash, {
                        error: error.message,
                        status: UploadFileStatus.ERROR,
                        isUploaded: false,
                    });

                    reject(error);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        const [previewDownloadURL, numPages]: any = await this.uploadPreview({
                            companyData,
                            file,
                            NS: this.NS,
                            name,
                        });

                        const inputTemplate: GQL.IDocumentCreateInput = {
                            key: hash,
                            fileName: file.name,
                            companyId,
                            numPages,
                            fileUrl: downloadURL,
                            previewUrl: previewDownloadURL,
                            type: undefined,
                        };

                        console.debug("file uploaded ", downloadURL);

                        resolve(inputTemplate);
                    } catch (err) {
                        reject(err);
                    }
                }
            );
        });
    };

    static uploadPreview = (arg: { companyData: GQL.ICompany; file: File; NS: string; name: string }) => {
        const { companyData, file, NS, name } = arg;
        return new Promise<(string | number)[]>((resolve, reject) => {
            const previewFilename = name.replace(".pdf", "-preview.png");
            let numPages: number;

            PdfUtils.getPdfDocument(file)
                .then(pdf => {
                    if (!pdf) {
                        reject(new Error("The file extension is not pdf"));
                        return;
                    }

                    numPages = pdf.numPages;
                    return PdfUtils.generatePreview(pdf);
                })
                .then(previewBlob => {
                    if (!previewBlob || !(previewBlob instanceof Blob)) {
                        reject(new Error("Failed to get blob"));
                        return;
                    }
                    const previewFile = new File([previewBlob], previewFilename, { type: "image/png" });
                    const companyId = companyData.id;

                    const previewFileRef = refStorage(
                        storage,
                        [NS, companyId, this.previewFolderName, previewFilename].join("/")
                    );
                    const uploadTask = uploadBytesResumable(previewFileRef, previewFile);
                    uploadTask.on(
                        "state_changed",
                        (snapshot: { bytesTransferred: number; totalBytes: number }) => {},
                        (error: { message: any }) => {
                            reject(`File upload error: ${error.message}`);
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref)
                                .then((downloadURL: string) => {
                                    console.debug("file uploaded ", downloadURL);
                                    resolve([downloadURL, numPages]);
                                })
                                .catch(error => {
                                    reject(error);
                                });
                        }
                    );
                });
        });
    };

    static updateDocuments = async (arg: {
        documents: IDocumentEnriched[];
        mutator: ReturnType<typeof useGqlMutator>;
    }): Promise<{ documentsUpdate: true | undefined }> => {
        const { documents, mutator } = arg;
        const documentInputs = documents.map(convertDocumentToUpdateInput);

        return (await mutator.mutate({
            mutation: documentsUpdate,
            input: documentInputs,
            hideMessages: true,
        })) as { documentsUpdate: true | undefined };
    };

    static changeDocumentsType = async (documents: GQL.IDocument[], mutator: ReturnType<typeof useGqlMutator>) => {
        const checkSubtype = (subType: string) => {
            if (!subType) {
                return false;
            }

            const disallowedSubTypes: string[] = [DmsDefaultSubType.no_subTypes, DmsDefaultSubType.all_subTypes];

            const hasSubstring =
                subType.includes(DmsDefaultSubType.no_subTypes) || subType.includes(DmsDefaultSubType.all_subTypes);

            return !disallowedSubTypes.includes(subType) && !hasSubstring;
        };

        const documentTypeInputs = documents.map(doc => {
            const subType = checkSubtype(doc.subType) ? doc.subType : null;
            return {
                id: doc.key,
                type: doc.type,
                subType,
                companyId: doc.companyId,
            };
        });

        await mutator.mutate({
            mutation: documentsTypeUpdate,
            input: documentTypeInputs,
            hideMessages: true,
        });
    };

    static deleteDocuments = async (arg: {
        documents: IDocumentEnriched[];
        recordsAssetsSet: Set<string>;
        mutator: ReturnType<typeof useGqlMutator>;
    }) => {
        const { documents, recordsAssetsSet, mutator } = arg;
        const toTrash: IDocumentEnriched[] = [];
        const toRemove: IDocumentEnriched[] = [];

        documents.forEach(document => {
            if (document.type === "new_documents" && !recordsAssetsSet.has(this.urlFormatter(document.fileUrl))) {
                return toRemove.push(document);
            }

            return toTrash.push(document);
        });

        await this.sendToTrash(toTrash, mutator);
        await this.removeDocuments(toRemove, mutator);
    };

    static sendToTrash = async (
        documents: IDocumentEnriched[],
        mutator: ReturnType<typeof useGqlMutator>
    ): Promise<void> => {
        if (documents.length === 0) {
            return;
        }

        const toTrashDocument = documents.map(document => {
            return {
                ...document,
                type: GQL.IDocumentStatus.Trash,
                subType: undefined,
            } as IDocumentEnriched;
        });
        await this.changeDocumentsType(toTrashDocument, mutator);
    };

    static async removeDocuments(
        documents: IDocumentEnriched[],
        mutator: ReturnType<typeof useGqlMutator>
    ): Promise<boolean> {
        const notInStorage: (IRemoverFile & { fileName: string })[] = [];
        const removedFiles: IRemoverFile[] = [];

        const removeArr = documents.map(async file => {
            return this.removeFile(file)
                .then((file: IRemoverFile) => {
                    removedFiles.push(file);
                })
                .catch(notInStorageFile => {
                    return notInStorage.push(notInStorageFile);
                });
        });

        await Promise.all(removeArr);

        if (notInStorage.length > 0) {
            for (const file of notInStorage) {
                console.debug(
                    `Document file ${file.fileName}  not exist in Storage but we try to delete document in Database`
                );
                const removeFiles: IRemoverFile[] = [{ key: file.key, companyId: file.companyId }];
                await mutator.mutate({
                    mutation: documentsDelete,
                    input: removeFiles,
                    hideMessages: true,
                });
                console.debug(`Document ${file.fileName} has been deleted from Database`);
            }
        }

        if (removedFiles.length > 0) {
            await mutator.mutate({
                mutation: documentsDelete,
                input: removedFiles,
                hideMessages: true,
            });
            console.debug("Documents has been deleted from Storage and Database");
        }
        return true;
    }

    static async removeFile(info: IDocumentEnriched): Promise<IRemoverFile> {
        return new Promise(
            (resolve: (arg: IRemoverFile) => void, reject: (arg: IRemoverFile & { fileName: string }) => void) => {
                const desertRef = refStorage(storage, info.fileUrl);

                deleteObject(desertRef)
                    .then(() => {
                        resolve({ key: info.key, companyId: info.companyId });
                    })
                    .catch(() => {
                        reject({ key: info.key, companyId: info.companyId, fileName: info.fileName });
                    });
            }
        );
    }

    static downloadFile = (url: string, fileName?: string) => {
        const storageRef = refStorage(storage, url);
        getMetadata(storageRef).then(value => {
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    saveAs(blob, fileName || value?.customMetadata?.name);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    };

    static bulkDownloadFiles = async (files: IDocumentEnriched[], setIsDownload: Dispatch<SetStateAction<boolean>>) => {
        setIsDownload(true);
        const zip = new JSZip();
        const usedFileNames = new Set<string>();

        try {
            for (const [index, file] of files.entries()) {
                const name = usedFileNames.has(file.fileName) ? `copy-${index}_${file.fileName}` : file.fileName;
                const response = await fetch(file.fileUrl);
                const blob = await response.blob();
                zip.file(name, blob, { binary: true });
                usedFileNames.add(name);
            }
            const content = await zip.generateAsync({ type: "blob", compression: "DEFLATE" });
            saveAs(content, "dms-documents");
            setIsDownload(false);
        } catch (error) {
            logger.crit(error);
        }
    };

    static urlFormatter = (url: string): string => {
        const indexQueryStart = url.indexOf("?");
        return url.slice(0, indexQueryStart);
    };

    static checkPDF = (file: File): Promise<boolean> => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    const data = reader.result as ArrayBuffer;
                    const loadingTask = pdfjs.getDocument({ data, isEvalSupported: false });
                    const pdf = await loadingTask.promise;
                    resolve(Boolean(pdf.numPages));
                } catch (error) {
                    resolve(false);
                }
            };
            reader.readAsArrayBuffer(file);
        });
    };
}
