import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import {
    MinimalButton,
    OpenFile,
    RenderPage,
    RenderPageProps,
    RotateDirection,
    ScrollMode,
    SpecialZoomLevel,
    Viewer,
    Worker,
} from "@react-pdf-viewer/core";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { selectionModePlugin } from "@react-pdf-viewer/selection-mode";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { pageNavigationPlugin, RenderCurrentPageLabelProps } from "@react-pdf-viewer/page-navigation";
import { RotateBackwardIcon, RotateForwardIcon, rotatePlugin } from "@react-pdf-viewer/rotate";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/selection-mode/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin, TransformToolbarSlot } from "@react-pdf-viewer/toolbar";
import { PDFDocument } from "pdf-lib";

type TProps = {
    url: string;
    appCheckToken: string;
    isModal?: true;
    fileName?: string;
    type?: string;
};

async function createPdfFromImage(imageUrl: string) {
    const isPng = imageUrl.includes(".png?");
    const imageBuffer = await fetch(imageUrl).then(response => response.arrayBuffer());
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const image = isPng ? await pdfDoc.embedPng(imageBuffer) : await pdfDoc.embedJpg(imageBuffer);
    page.setSize(image.width, image.height);
    page.drawImage(image, {
        x: 0,
        y: 0,
        width: image.width,
        height: image.height,
    });

    return pdfDoc.save();
}

export const PdfViewer: FC<TProps> = React.memo(({ url, type, isModal, fileName, appCheckToken }) => {
    const [file, setFile] = useState<string | Uint8Array>();
    const [viewerKey, setViewerKey] = useState<string>();
    const currentPageIndex = useRef(0);

    const selectionModePluginInstance = selectionModePlugin();
    const zoomPluginInstance = zoomPlugin();
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const rotatePluginInstance = rotatePlugin();
    const thumbnailPluginInstance = thumbnailPlugin();
    const toolbarPluginInstance = toolbarPlugin();
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            return fileName || file.name;
        },
    });

    const { renderDefaultToolbar } = toolbarPluginInstance;
    const { RotatePage } = rotatePluginInstance;
    const { CurrentPageLabel } = pageNavigationPluginInstance;

    const { DownloadButton } = getFilePluginInstance;

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Open: () => <></>,
        Download: () => <DownloadButton></DownloadButton>,
    });

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <>
            <RotatePage>
                {props => (
                    <MinimalButton
                        onClick={() => props.onRotatePage(currentPageIndex.current, RotateDirection.Forward)}
                    >
                        <RotateForwardIcon />
                    </MinimalButton>
                )}
            </RotatePage>
            <RotatePage>
                {props => (
                    <MinimalButton
                        onClick={() => props.onRotatePage(currentPageIndex.current, RotateDirection.Backward)}
                    >
                        <RotateBackwardIcon />
                    </MinimalButton>
                )}
            </RotatePage>
            <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
        </>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: defaultTabs => {
            if (isModal) {
                return [defaultTabs[0]];
            }
            return [];
        },
        setInitialTab: () => Promise.resolve(0),
        thumbnailPlugin: {
            thumbnailWidth: 150,
        },
        renderToolbar,
    });

    const renderPage: RenderPage = (props: RenderPageProps) => (
        <>
            {props.canvasLayer.children}
            {props.annotationLayer.children}
            {props.textLayer.children}
            <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => {
                    if (currentPageIndex.current !== props.currentPage) {
                        currentPageIndex.current = props.currentPage;
                    }
                    return <></>;
                }}
            </CurrentPageLabel>
        </>
    );

    useEffect(() => {
        if (!url) {
            return;
        }
        if (new URL(url).pathname.toLowerCase().endsWith(".pdf") || type === "application/pdf") {
            setFile(url);
            setViewerKey(url);
        } else {
            createPdfFromImage(url)
                .then(file => {
                    setFile(file);
                    setViewerKey(url);
                })
                .catch(error => {
                    throw new Error(error.message);
                });
        }
    }, [url]);

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <Worker workerUrl="pdfjs-dist/build/pdf.worker.entry">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    {file && (
                        <Viewer
                            key={viewerKey}
                            fileUrl={file}
                            plugins={[
                                selectionModePluginInstance,
                                zoomPluginInstance,
                                rotatePluginInstance,
                                pageNavigationPluginInstance,
                                thumbnailPluginInstance,
                                defaultLayoutPluginInstance,
                                getFilePluginInstance,
                            ]}
                            transformGetDocumentParams={options => ({
                                ...options,
                                isEvalSupported: false,
                                httpHeaders: {
                                    "X-Firebase-AppCheck": appCheckToken,
                                },
                            })}
                            scrollMode={ScrollMode.Vertical}
                            defaultScale={SpecialZoomLevel.PageWidth}
                            renderPage={renderPage}
                        />
                    )}
                </div>
            </Worker>
        </div>
    );
});
