import React from "react";
import { RecordFormStateContext } from "../context/RecordFormState";

export const useIsItemFieldDisabledOutsideModal = (isModalField: boolean) => {
    const { recordItems, isSplitCardOpen } = React.useContext(RecordFormStateContext);
    if (!isModalField) {
        if (isSplitCardOpen) {
            return true;
        }
        if (recordItems?.length > 1) {
            return true;
        }
    }
    return false;
};
