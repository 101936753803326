import React, { FC, useMemo } from "react";
import { InvoiceFormItemWrapper } from "@inv/components/InvoiceForm/components/InvoiceFormItemWrapper";
import { Col, Flex, Form, Input, Row, Select, Switch } from "antd";
import { InvoiceFormBlocksTranslate, InvoiceFormInputTranslate, InvoiceInputs, TInvoicesValues } from "@inv/types";
import { DateInput } from "@dms/components/DocumentForm/DocumentInputs/DateInput";
import { DeliverySwitch } from "@inv/components/InvoiceForm/components/DeliverySwitch";
import { FormattedMessage, useIntl } from "react-intl";
import { InvoiceNumberInput, InvoiceNumberLabel } from "@ui-components/RechnungInputBlock";
import { useInvoiceFormats } from "@inv/hooks/useInvoiceFormats";
import dayjs from "dayjs";
import { Utils } from "@binale-tech/shared";

export const InvoiceInputBlocks: FC<{
    isFormatSelected: boolean;
    setIsFormatSelected: (v: boolean) => void;
    isConfirmed: boolean;
}> = ({ isFormatSelected, setIsFormatSelected, isConfirmed }) => {
    const intl = useIntl();
    const form = Form.useFormInstance<TInvoicesValues>();
    const formatId = Form.useWatch([InvoiceInputs.INVOICE_NUMBER_FORMAT], form);
    const date = Form.useWatch([InvoiceInputs.DATE], form);
    const { formatsList } = useInvoiceFormats(date ? dayjs(date, "DD.MM.YYYY").year() : new Date().getFullYear());
    const format = useMemo(() => formatsList.find(v => v.id === formatId), [formatId, formatsList]);

    // console.log(formatId, invoiceNumber);

    const invoiceNumberBlock = (
        <Form.Item name={InvoiceInputs.INVOICE_NUMBER}>
            <InvoiceNumberInput
                disabled={isConfirmed}
                suffix={<InvoiceNumberLabel hideLabel />}
                placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.INVOICE_NUMBER })}
            />
        </Form.Item>
    );

    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.INVOICE_DETAILS })}>
            <Row gutter={[10, 0]}>
                <Col span={24}>
                    <Form.Item
                        name={InvoiceInputs.DATE}
                        label={intl.formatMessage({ id: InvoiceFormInputTranslate.DATE })}
                    >
                        <DateInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.DATE })} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Flex justify={"space-between"} style={{ width: "100%", marginBottom: 8 }}>
                        <Switch
                            checkedChildren={<FormattedMessage id={InvoiceFormInputTranslate.INVOICE_NUMBER_FORMAT} />}
                            unCheckedChildren={<FormattedMessage id={InvoiceFormInputTranslate.INVOICE_NUMBER} />}
                            value={isFormatSelected}
                            onChange={setIsFormatSelected}
                            disabled={isConfirmed}
                        />
                        {isFormatSelected && (
                            <>
                                {isConfirmed ? (
                                    <FormattedMessage id={InvoiceFormInputTranslate.INVOICE_NUMBER_FORMAT} />
                                ) : (
                                    "Beispiel"
                                )}
                            </>
                        )}
                    </Flex>

                    {isFormatSelected ? (
                        <Row gutter={[10, 0]}>
                            <Col span={12}>
                                <Form.Item name={InvoiceInputs.INVOICE_NUMBER_FORMAT}>
                                    <Select
                                        disabled={!date || isConfirmed}
                                        allowClear
                                        placeholder={intl.formatMessage({
                                            id: InvoiceFormInputTranslate.INVOICE_NUMBER_FORMAT,
                                        })}
                                        options={formatsList.map(v => ({
                                            value: v.id,
                                            label:
                                                Utils.Invoicing.getInvoiceNumber(v) +
                                                (v.description ? `(${v.description.substring(0, 100)})` : ""),
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                {isConfirmed ? (
                                    invoiceNumberBlock
                                ) : (
                                    <Input disabled value={format ? Utils.Invoicing.getInvoiceNumber(format) : ""} />
                                )}
                            </Col>
                        </Row>
                    ) : (
                        invoiceNumberBlock
                    )}
                </Col>
                <Col span={24}>
                    <DeliverySwitch />
                </Col>
            </Row>
        </InvoiceFormItemWrapper>
    );
};
