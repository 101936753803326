import { useCallback, useContext, useEffect, useRef } from "react";
import {
    DmsAccountingConverter,
    fetchAndGetInvoiceData,
} from "../../../../scripts/models/converters/DmsAccountingConverter";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { GQL } from "@binale-tech/shared";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { useFormHandlerDate } from "../hooks/handlers/useFormHandlerDate";
import { useFormProperties } from "./useFormProperties";
import { useFormHandlerBruttoTotal } from "../hooks/handlers/useFormHandlerBruttoTotal";
import { useFormHandlerItemBF1 } from "../hooks/handlers/useFormHandlerItemBF1";
import { useFormHandlerItemText } from "../hooks/handlers/useFormHandlerItemText";
import { useFormHandlerItemBF2 } from "../hooks/handlers/useFormHandlerItemBF2";
import { useFormHandlerRecordBF1 } from "../hooks/handlers/useFormHandlerRecordBF1";
import { useFormHandlerRecordContact } from "../hooks/handlers/useFormHandlerRecordContact";
import { useFormHandlerRecordTemplate } from "@app/components/recordform/hooks/handlers/useFormHandlerRecordTemplate";

export const useApplyDocumentProperties = () => {
    const { product } = useContext(TableViewContext);
    const { isUpdating, periodBound, yearBound, refsHtml, refsData } = useContext(RecordFormPropsContext);
    const { documentsKV } = useContext(DmsDataContext);

    const isDisabled = useFormIsRefDisabled();
    const { isContactFieldDisabled } = useFormProperties();
    const onDateChange = useFormHandlerDate();
    const onBruttoTotalChange = useFormHandlerBruttoTotal();
    const onItemBelegfeld1Change = useFormHandlerItemBF1();
    const onTextChange = useFormHandlerItemText();
    const onBF2Change = useFormHandlerItemBF2();
    const onRecordBelegfeld1Change = useFormHandlerRecordBF1();
    const onRecordContactChange = useFormHandlerRecordContact();
    const onTemplateChange = useFormHandlerRecordTemplate();

    const docsRef = useRef(documentsKV);
    useEffect(() => {
        docsRef.current = documentsKV;
    }, [documentsKV]);

    return useCallback(
        async (fileIds: string[]) => {
            const docs = fileIds.map(id => docsRef.current[id]).filter(Boolean);
            const xRechnungDoc = docs.find(d => d.hasXRechnung);
            const productKey = product.productKey() as GQL.IProductKey;
            if (![GQL.IProductKey.Er, GQL.IProductKey.ErA, GQL.IProductKey.Deb].includes(productKey)) {
                return;
            }
            console.log("useApplyDocumentProperties", productKey, fileIds, docs, xRechnungDoc);
            if (xRechnungDoc) {
                const xRechnungData = await fetchAndGetInvoiceData(
                    xRechnungDoc.fileUrl,
                    productKey,
                    refsData.contactsRef.current
                );
                if (xRechnungData?.generated?.record) {
                    onTemplateChange(xRechnungData.generated.record);
                    const hadContact = refsData?.formStateRef.current?.editableRecord?.recordContact?.id;
                    if (xRechnungData.generated.record.partner && !hadContact) {
                        setTimeout(() => {
                            onRecordContactChange(xRechnungData.generated.record.partner);
                        }, 100);
                    }
                    await new Promise(resolve => setTimeout(resolve, 150));
                }
            }

            const { editableRecordItem, editableRecord, recordItems } = refsData.formStateRef.current;
            const suggestions = DmsAccountingConverter.getRecordDocumentsSuggestion(
                product.productKey() as GQL.IProductKey,
                docs,
                { isUpdating, periodBound, yearBound, recordFormData: { editableRecordItem, editableRecord } }
            );

            // todo prevent from change if it was set by e rechnung
            if (suggestions.recordDate && !isDisabled(refsHtml.REF_rDATE) && !refsData.dateTouchedRef.current) {
                onDateChange(suggestions.recordDate.date);
            }
            if (
                suggestions.editableRecord.recordNum &&
                !editableRecord.recordNum &&
                !isDisabled(refsHtml.REF_rBELEGFELD1)
            ) {
                onRecordBelegfeld1Change(suggestions.editableRecord.recordNum);
            }
            if (
                suggestions.editableRecord.recordBrutto &&
                !editableRecord.recordBrutto &&
                !isDisabled(refsHtml.REF_rBRUTTO)
            ) {
                onBruttoTotalChange({
                    amount: suggestions.editableRecord.recordBrutto,
                    originalAmount: suggestions.editableRecord.recordOriginalAmount,
                    currency: suggestions.editableRecord.recordCurrency,
                });
            }
            if (suggestions.editableRecord.recordContact && !editableRecord.recordContact && !isContactFieldDisabled) {
                onRecordContactChange(suggestions.editableRecord.recordContact);
            }

            if (recordItems?.length > 0) {
                return;
            }

            if (
                suggestions.editableRecordItem.itemText &&
                !editableRecordItem.itemText &&
                !isDisabled(refsHtml.REF_iTEXT)
            ) {
                onTextChange(suggestions.editableRecordItem.itemText);
            }
            if (
                suggestions.editableRecordItem.itemBelegfeld1 &&
                !editableRecordItem.itemBelegfeld1 &&
                !isDisabled(refsHtml.REF_iBELEGFELD1)
            ) {
                onItemBelegfeld1Change(suggestions.editableRecordItem.itemBelegfeld1);
            }
            if (
                suggestions.editableRecordItem.itemBelegfeld2 &&
                !editableRecordItem.itemBelegfeld2 &&
                !isDisabled(refsHtml.REF_iBELEGFELD2)
            ) {
                onBF2Change(suggestions.editableRecordItem.itemBelegfeld2);
            }
        },
        [
            refsData,
            product,
            isUpdating,
            periodBound,
            yearBound,
            isDisabled,
            refsHtml,
            isContactFieldDisabled,
            onTemplateChange,
            onRecordContactChange,
            onDateChange,
            onRecordBelegfeld1Change,
            onBruttoTotalChange,
            onTextChange,
            onItemBelegfeld1Change,
            onBF2Change,
        ]
    );
};
