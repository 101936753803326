import React, { FC, useCallback, useState } from "react";
import { Button, Flex, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { YearSelect } from "@app/components/shared/Toolbar/YearSelect";
import { CompanyContext } from "../../../../../scripts/context/CompanyContext";
import InvoiceFormatForm from "../../components/InvoiceFormatForm/InvoiceFormatForm";

export const InvoiceFormatsListHeader: FC<{ year: number; setYear: (v: number) => void }> = ({ year, setYear }) => {
    const { companyGQL } = React.useContext(CompanyContext);
    const [isOpen, setIsOpen] = useState(false);
    const onClose = useCallback(() => setIsOpen(false), []);

    const years = new Set(companyGQL?.accountingYears || []);
    years.add(new Date().getFullYear());

    return (
        <Flex align="center" justify="space-between" style={{ width: "100%", marginBottom: "10px" }}>
            <Typography.Title level={3}>Formats</Typography.Title>
            <Flex align="center" justify="flex-end" gap={10}>
                <YearSelect years={[...years]} value={year} onChange={setYear} />

                <Button type={"primary"} icon={<PlusOutlined />} onClick={() => setIsOpen(true)}>
                    <FormattedMessage id={"app.invoice.createInvoiceFormat"} />
                </Button>
            </Flex>
            <InvoiceFormatForm open={isOpen} onClose={onClose} />
        </Flex>
    );
};
