import React from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "antd";
import type { BaseSelectRef } from "rc-select";
import { FieldLabel, FieldLabelProps } from "@ui-components/FieldLabel";
import { ContactSelect } from "../baseComponents/ContactSelect/ContactSelect";
import { GenericRecord } from "../../../../../scripts/models";

type Props = {
    disabled?: boolean;
    value: GenericRecord["partner"];
    onChange: (v: GenericRecord["partner"]) => void;
    hideFieldLabel?: boolean;
    labelProps?: FieldLabelProps;
};
export const ContactInputBlock = React.forwardRef<BaseSelectRef, Props>(function ContactInputBlock(props, ref) {
    const select = (
        <ContactSelect
            ref={ref}
            disabled={props.disabled}
            value={props.value ? { id: props.value.id, name: props.value.name } : undefined}
            onChange={value => {
                props.onChange(value?.id || value?.name ? { id: value.id, name: value.name } : undefined);
            }}
        />
    );
    if (props.hideFieldLabel) {
        return <Form.Item style={{ minWidth: 200 }}>{select}</Form.Item>;
    }
    return (
        <FieldLabel
            {...props.labelProps}
            label={<FormattedMessage id="app.fields.contact" />}
            style={{ minWidth: 180, maxWidth: 180 }}
        >
            {select}
        </FieldLabel>
    );
});
