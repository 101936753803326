import { useContext, useMemo } from "react";
import { DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { DmsTableColumns } from "@dms/modules/DocumentTableModule/DmsTableColumns";
import { AppConfigUtils } from "@dms/scripts/utils/AppConfigUtils";

import { ALL_DOCUMENTS, DmsTypeOptions } from "@dms/configs/constants";
import { DmsType, tableModeEnum } from "@dms/types";

export const useDocumentTableConfig = (mode: tableModeEnum, activeType: string[]) => {
    const { userConfig } = useContext(DmsUserSettingsContext);

    const { type } = DmsUtils.getActiveTypeAndSubType(activeType);

    const modalConfig = useMemo(() => {
        switch (type) {
            case DmsType.new_documents:
                return "newDocumentsTableColumns";
            case ALL_DOCUMENTS:
                return "allDocumentsTableColumns";
        }
        return "defaultDocumentsTableColumns";
    }, [type]);

    const enabledColumns = useMemo(() => {
        if (mode === tableModeEnum.modal) {
            const isParentType = DmsTypeOptions.find(t => {
                return t.value === activeType.toString() && t.isGroup;
            });

            if (isParentType) {
                return userConfig.enabledColumns.get([`${activeType[0]}-all_subTypes`, ...activeType].toString()) ?? [];
            }
        }

        return userConfig.enabledColumns.get(activeType.toString()) ?? [];
    }, [mode, userConfig.enabledColumns, activeType]);

    const columnDefs = useMemo(() => {
        if (mode === tableModeEnum.modal) {
            const initEnabledColumns = AppConfigUtils.getInitColumns(type);
            return DmsTableColumns.getColumns(mode, initEnabledColumns, modalConfig).filter(Boolean);
        }

        if (!enabledColumns) {
            return [];
        }

        return DmsTableColumns.getColumns(mode, enabledColumns).filter(Boolean);
    }, [mode, enabledColumns, type, modalConfig]);

    return useMemo(() => {
        return {
            enabledColumns,
            columnDefs,
        };
    }, [enabledColumns, columnDefs]);
};
