import { AgGridReact } from "ag-grid-react";
import { Button, Flex, Form } from "antd";
import React, { FC, useCallback, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import {
    CellValueChangedEvent,
    GridApi,
    GridReadyEvent,
    RowDataUpdatedEvent,
    RowDragEndEvent,
} from "ag-grid-community";
import {
    InvoiceColumns,
    InvoiceFormInputTranslate,
    InvoiceInputs,
    TInvoiceFormLineItem,
    TInvoicesValues,
} from "@inv/types";
import { useConfig } from "@inv/modules/InvoiceTableModule/config";
import { FormattedMessage } from "react-intl";
import { useColumnConfig } from "@inv/modules/InvoiceTableModule/hooks/useColumnConfig";
import { InvoiceTotal } from "@inv/components/InvoiceTableBlock/components/InvoiceTotal";
import { emptyLineItem } from "@inv/modules/InvoiceFormModule/consts/consts";

export const InvoiceTableBlock: FC = () => {
    const gridApi = useRef<AgGridReact>();
    const gridOptions = useConfig();
    const form = Form.useFormInstance<TInvoicesValues>();
    const values = form.getFieldsValue();
    const { lineItems } = values;

    const columns = useColumnConfig();
    const refreshDynamicColumns = useCallback((api: GridApi<TInvoiceFormLineItem>) => {
        api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [InvoiceColumns.TOTAL_NETTO, InvoiceColumns.ACTIONS],
        });
    }, []);

    const onAddRow = useCallback(() => {
        if (!gridApi.current) {
            return;
        }
        gridApi.current.api.applyTransaction({ add: [{ ...emptyLineItem }] });
        refreshDynamicColumns(gridApi.current.api);
        setTimeout(() => {
            const nodes = gridApi.current.api.getRenderedNodes();
            gridApi.current.api.startEditingCell({
                rowIndex: nodes.length - 1,
                colKey: InvoiceColumns.PRODUCTS_SERVICES,
            });
        }, 100);
    }, [refreshDynamicColumns]);

    const backSyncLineItems = useCallback(
        (api: GridApi<TInvoiceFormLineItem>, source: string) => {
            const nodes = api.getRenderedNodes();
            const newLineItems = nodes.map(node => {
                const { productsServices, unit, quantity, price, tax, discount } = node.data;
                return { productsServices, unit, quantity, price, tax, discount };
            });
            // console.log("sendLineItems", source, newLineItems);
            form.setFieldValue(InvoiceInputs.LINE_ITEMS, newLineItems);
        },
        [form]
    );

    const onGridReady = useCallback(
        ({ api }: GridReadyEvent<TInvoiceFormLineItem>) => {
            api.applyTransaction({ add: lineItems });
        },
        [lineItems]
    );

    const onCellValueChanged = useCallback(
        ({ data, node, colDef, api }: CellValueChangedEvent<TInvoiceFormLineItem>) => {
            backSyncLineItems(api, "onCellValueChanged");
            refreshDynamicColumns(api);
        },
        [backSyncLineItems, refreshDynamicColumns]
    );

    const onRowDataUpdated = useCallback(
        ({ api }: RowDataUpdatedEvent<TInvoiceFormLineItem>) => {
            // handles change of row add/removal
            refreshDynamicColumns(api);

            backSyncLineItems(api, "onRowDataUpdated");
        },
        [backSyncLineItems, refreshDynamicColumns]
    );

    const onRowDragEnd = useCallback(
        ({ api }: RowDragEndEvent<TInvoiceFormLineItem>) => {
            api.refreshCells({
                force: true,
                suppressFlash: true,
                columns: [InvoiceColumns.POSITION, InvoiceColumns.ACTIONS],
            });
            backSyncLineItems(api, "onRowDragEnd");
        },
        [backSyncLineItems]
    );

    if (Object.keys(values).length === 0) {
        return null;
    }

    return (
        <Flex vertical={true} gap={20} style={{ width: "100%", paddingBottom: 20 }}>
            <AgGridTable
                ref={gridApi}
                wrapperStyles={{ width: "100%", minHeight: "60px" }}
                gridOptions={gridOptions}
                columnDefs={columns}
                onGridReady={onGridReady}
                onCellValueChanged={onCellValueChanged}
                onRowDataUpdated={onRowDataUpdated}
                onRowDragEnd={onRowDragEnd}
            />

            <Flex align="start" justify="space-between" style={{ width: "100%" }}>
                <Button type="dashed" icon={<PlusOutlined />} onClick={onAddRow}>
                    <FormattedMessage id={InvoiceFormInputTranslate.NEW_LINE} />
                </Button>
                <InvoiceTotal />
            </Flex>
        </Flex>
    );
};
