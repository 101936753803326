import { Base, Bu, BuMNF, GQL, Utils } from "@binale-tech/shared";
import Category from "../Category";

type IstMNFConfig = {
    skipInVatExport: boolean;
    useUstMNFKonto: boolean;
    useMNFBalancing: boolean;
    switchUStBalancingSign?: boolean;
};
const configOff: IstMNFConfig = Object.freeze({
    useUstMNFKonto: false,
    skipInVatExport: false,
    useMNFBalancing: false,
});
export class IstMNFUtils {
    static getLogic(
        productKey: GQL.IProductKey,
        yearConfig: Pick<GQL.ICompanyAccountingYear, "taxation" | "kontoExt" | "skr">,
        recordKonto: Base.IExtNum,
        itemKonto: Base.IExtNum,
        bu: Bu.Bu
    ): IstMNFConfig {
        if (yearConfig.taxation !== "IST") {
            return configOff;
        }
        if (!recordKonto?.num || !itemKonto?.num) {
            return configOff;
        }
        const isRecordKontoMNF = BuMNF.isMNFLogicForBu(yearConfig, bu, {
            extNum: recordKonto,
        });
        const isItemKontoMNF = BuMNF.isMNFLogicForBu(yearConfig, bu, {
            extNum: itemKonto,
        });
        if (!isItemKontoMNF && !isRecordKontoMNF) {
            return configOff;
        }
        if (Utils.PaymentUtils.isRecordPaymentSource(productKey)) {
            return { useUstMNFKonto: isRecordKontoMNF, skipInVatExport: isRecordKontoMNF, useMNFBalancing: false };
        }
        if (productKey === GQL.IProductKey.Fe) {
            return {
                useUstMNFKonto: isRecordKontoMNF,
                skipInVatExport: isRecordKontoMNF,
                useMNFBalancing: this.isFNR10(itemKonto),
            };
        }
        if (Utils.PaymentUtils.isProductPaymentRepresentation(productKey)) {
            return {
                useUstMNFKonto: isItemKontoMNF,
                skipInVatExport: false,
                useMNFBalancing: this.isFNR10(recordKonto),
                switchUStBalancingSign: this.isFNR10(recordKonto),
            };
        }
        return configOff;
    }

    protected static isFNR10(v: Base.IExtNum) {
        return v instanceof Category && v.FNR === 10;
    }
}
