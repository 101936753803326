import { useContext, useMemo } from "react";
import { TInvoicesListColumnsConfig } from "@inv/types";
import { InvoicesListTableColumns } from "@inv/layouts/InvoicesListLayout/components/InvoicesList/config/tableColumns";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";

export const useColumnConfig = () => {
    const { invoiceListColumnsConfig } = useContext(InvoicesDataContext);
    const activeKeys = useMemo(
        () =>
            Object.keys(invoiceListColumnsConfig).filter(
                (key: keyof TInvoicesListColumnsConfig) => invoiceListColumnsConfig[key]
            ),
        [invoiceListColumnsConfig]
    );

    return InvoicesListTableColumns.getColumns(activeKeys);
};
