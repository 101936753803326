import { useContext } from "react";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";

export const useInvoiceLines = (invoiceId: string) => {
    const { invoicesMap } = useContext(InvoicesDataContext);
    if (!invoiceId) {
        return null;
    }
    const invoice = invoicesMap.get(invoiceId);

    return invoice.lineItems.map(lineItem => {
        const formatAmount = (amount: number) => {
            const str = String(amount).padStart(3, "0");
            return str.slice(0, str.length - 2) + "." + str.slice(str.length - 2);
        };
        const getUnit = () => {
            if (lineItem.unit === "PIECE") {
                return "piece";
            }
            if (lineItem.unit === "HOUR") {
                return "h";
            }
            if (lineItem.unit === "MONTH") {
                return "mon";
            }
            return "piece";
        };
        const getTax = () => {
            if (!invoice.isTaxIncluded) {
                return undefined;
            }
            return [
                {
                    cat: "VAT",
                    percent: `${lineItem.tax}%`,
                    rate: lineItem.tax === 0 ? "zero" : undefined,
                    ext: { "untdid-tax-category": lineItem.tax === 0 ? "Z" : "S" },
                },
            ];
        };
        const getDiscounts = () => {
            if (lineItem.discount === lineItem.price) {
                return undefined;
            }
            return [
                {
                    amount: formatAmount(lineItem.price - lineItem.discount),
                    ext: {
                        "untdid-allowance": "100",
                    },
                },
            ];
        };
        return {
            quantity: lineItem.quantity,
            item: {
                name: lineItem.productsServices,
                price: formatAmount(lineItem.price),
                unit: getUnit(),
            },
            taxes: getTax(),
            discounts: getDiscounts(),
        };
    });
};
