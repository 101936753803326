import GenericTableViewCtxWrapper from "../../productSharedComponents/GenericTableViewCtxWrapper";
import React, { useEffect } from "react";
import { ColumnOverrides } from "../../../columns/ColumnConfig";
import { CommonAuswertungToolbar } from "../../productSharedComponents/auswertung/auswertungToolbars/CommonAuswertungToolbar";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { RecordsControlContext } from "scripts/context/recordsContext/RecordsControlCtx";
import { GenericRecord, RecordKB } from "scripts/models/GenericRecord";
import { GenericRecordProperties, ProductCommonAuswertung, TableProperties } from "scripts/core/Product";
import { GenericTableView } from "../../productSharedComponents/GenericTableView";
import { ProductKeys } from "scripts/models/Product";
import { RecordsTableBlock } from "../../productSharedComponents/RecordsTableBlock";
import { TableExportType } from "../../../../scripts/exporters/Exporters";
import { TableItem } from "../../../components/shared/Table/Table";
import {
    TableViewContext,
    TableViewContextProvider,
    useDefaultYearPeriodFilters,
} from "scripts/context/tableViewContext/tableViewContext";
import { logger } from "scripts/infrastructure/logger";

class CommonAuswertungViewClass extends GenericTableView {
    protected getInlineForm(): React.ReactNode {
        return null;
    }

    protected getHeader(): React.ReactNode {
        return null;
    }

    protected getToolbarBlock() {
        return <CommonAuswertungToolbar tableColumns={this.tableColumns} onDownload={this.handleDownload} />;
    }

    protected handleDownload = (a: TableExportType, b: string[], c: { from?: Date; to?: Date }) => {
        logger.log(a, b, c);
        return Promise.resolve();
    };

    protected getBlockTableView(h: number): React.ReactNode {
        const { onSort, sortColumn, canWrite, focusIndex, onSetFocus, product, view, tableRef, tableItems } =
            this.getTableLegacyCommonProps();
        return (
            <RecordsTableBlock
                view={view}
                tableRef={tableRef}
                tableItems={tableItems}
                tableHeight={h}
                tableSaldoHeader={undefined}
                selectedPeriodEditBound={null}
                tableRowClassFunc={this.rowClassFunc}
                onSort={onSort}
                sortColumn={sortColumn}
                product={product}
                canWrite={canWrite}
                onPayments={tableItem => this.handleZahlungTableItem(tableItem)}
                focusIndex={focusIndex}
                onSetFocus={onSetFocus}
                tableConfigFooters={undefined}
                columnConfig={this.tableColumns}
                itemActions={{
                    handleEditItem: (v, e) => this.handleEditItem(v, e),
                    handleCopyItem: (v, e) => this.handleCopyItem(v, e),
                    handleDeleteItems: (v, e) => this.handleDeleteItems(v, e),
                    handleCancelItems: (v, e) => this.handleCancelItems(v, e),
                    handleUpdateItems: (v, e) => this.handleUpdateItems(v, e),
                    handleColorTableItems: (v, c) => this.handleColorTableItems(v, c),
                    handleAvisTableItems: (v, avis) => this.handleAvisTableItems(v, avis),
                    handleBulkEditItems: (v, e) => this.handleBulkEditItems(v, e),
                    handleReviewItems: (vs, review) => this.handleReviewItems(vs, review),
                }}
            />
        );
    }

    protected tableConfigGetters() {
        const getters: ColumnOverrides<GenericRecord>["getters"] = {
            [GenericRecordProperties.RecordCategoryCreditorNum]: (
                tableItem: TableItem<GenericRecord>,
                kontoExt?: number
            ) => tableItem.item.getRecordCategoryCreditor().getExtNumPrint(kontoExt),
            [GenericRecordProperties.ItemCategoryCreditorNum]: (
                tableItem: TableItem<GenericRecord>,
                kontoExt?: number
            ) => tableItem.item.getItemCategoryCreditor().getExtNumPrint(kontoExt),
            [TableProperties.ControlLog]: (tableItem: TableItem<GenericRecord>) => this.getControlLogButton(tableItem),
            [TableProperties.ControlAction]: (tableItem: TableItem<RecordKB>) => this.getActionButtons(tableItem),
        };
        return getters;
    }

    render() {
        return this.getPageWithoutLeftTable();
    }
}

export const CommonAuswertungView: React.FC = () => {
    const defaultFilters = useDefaultYearPeriodFilters();
    const { period, onChangePeriod } = React.useContext(YearPeriodContext);
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);

    useEffect(() => {
        if (period !== null) {
            onChangePeriod(null);
        }
    }, []);

    const allRecords = React.useContext(RecordsContext);
    const allActions = React.useContext(RecordsControlContext);
    const tableCtx = React.useMemo<React.ContextType<typeof TableViewContext>>(
        () => ({
            view: "commonAuswertung",
            product: new ProductCommonAuswertung(yearConfig.skr, companyGQL),
            productKey: ProductKeys.AccountingCommon,
            moduleRecords: allRecords.allRecords.list || [],
            moduleActions: allActions.blackhole,
            moduleLogLister: () => Promise.reject("not available for global overview"),
        }),
        [allActions.blackhole, allRecords.allRecords, yearConfig, companyGQL]
    );

    return (
        <TableViewContextProvider tableCtx={tableCtx} defaultFilters={defaultFilters}>
            <GenericTableViewCtxWrapper Component={CommonAuswertungViewClass} />
        </TableViewContextProvider>
    );
};
