import { useCallback, useContext, useEffect, useMemo } from "react";
import { IGenericItem, IGenericRecord } from "../../../../scripts/models/Interfaces";
import {
    getDefaultItem,
    getDefaultRecord,
    RecordFormStateContext,
    RecordFormStateControlContext,
} from "../context/RecordFormState";
import RecordFormState from "../types/RecordFormState";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { GenericRecordUtils } from "../../../../scripts/models/utils/GenericRecordUtils";
import { focusFirstFormField } from "../../../../scripts/infrastructure/helpers/focus";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { logger } from "../../../../scripts/infrastructure/logger";
import { useFormRecordComposer } from "../hooks/useFormRecordComposer";
import { useFormValidators } from "../hooks/useFormValidators";
import { useFormRefsData } from "@app/components/recordform/hooks/useFormRefsData";

export const useFormRecordLifecycle = () => {
    const { composeFormRecord } = useFormRecordComposer();
    const { validateInlineForm } = useFormValidators();

    const { product } = useContext(TableViewContext);
    const { isTemplate, refsData } = useContext(RecordFormPropsContext);
    const formState = useContext(RecordFormStateContext);
    const {
        setIsNettoMode,
        setSelectedPayment,
        setEditableRecordItem,
        setRecordVirtualNetto,
        setRecordItems,
        setEditableRecord,
        setRecordDate,
        setRecordValidationStates,
        setItemValidationStates,
        setIsSplitCardOpen,
        setIsDocumentsCardOpen,
        setIsModalTemplateOpen,
    } = useContext(RecordFormStateControlContext);
    const { onClear: onClearExternal, onSave: onSaveExternal } = useContext(RecordFormPropsContext);

    const resetRecord = useCallback(() => {
        setIsModalTemplateOpen(false);
        setRecordVirtualNetto(null);
        setSelectedPayment(null);
        setRecordItems([]);
        setIsNettoMode(false);
        setIsDocumentsCardOpen(false);
        setIsSplitCardOpen(false);
        setEditableRecord(prevState => ({
            ...getDefaultRecord(),
            recordDraft: isTemplate ? true : (prevState?.recordDraft ?? false),
        }));
    }, [
        setIsNettoMode,
        setIsModalTemplateOpen,
        setIsSplitCardOpen,
        setIsDocumentsCardOpen,
        setRecordVirtualNetto,
        setSelectedPayment,
        setRecordItems,
        setEditableRecord,
        isTemplate,
    ]);

    const resetItem = useCallback(
        (overrides: Partial<RecordFormState["editableRecordItem"]> = {}) => {
            setEditableRecordItem({ ...getDefaultItem(), ...overrides });
        },
        [setEditableRecordItem]
    );

    const receiveItem = useCallback(
        (i: IGenericItem, opts?: { fromTemplate?: boolean; nettoConfig?: { netto: number } }) => {
            const converted = GenericRecordUtils.convertRecordItemToForm(i, product);
            if (opts?.nettoConfig) {
                converted.itemBrutto = opts?.nettoConfig.netto;
            }

            setEditableRecordItem(prevState => {
                if (!opts?.fromTemplate) {
                    return converted;
                }

                Object.keys(converted).forEach((key: keyof RecordFormState["editableRecordItem"]) => {
                    if (prevState[key] && !converted[key]) {
                        converted[key] = prevState[key] as never;
                    }
                });
                return converted;
            });
        },
        [product, setEditableRecordItem, setSelectedPayment]
    );

    // zero deps effectively (only product from setItem)
    const receiveRecord = useCallback(
        (r: IGenericRecord, fromTemplate?: boolean) => {
            setRecordValidationStates(new Map());
            setItemValidationStates(new Map());

            if (!fromTemplate) {
                resetRecord();
                resetItem();
                setRecordItems([]);
            }

            if (!r) {
                return;
            }
            if (!fromTemplate) {
                setRecordDate({ date: r.date, period: r.period });
            } else {
                const recordDate = refsData.formStateRef.current.recordDate;
                if (recordDate.period === r.period && recordDate.date.getFullYear() === r.date.getFullYear()) {
                    setRecordDate({ date: r.date, period: r.period });
                }
            }
            setEditableRecord(prevState => {
                const converted = GenericRecordUtils.convertRecordToForm(r);
                if (fromTemplate) {
                    const preservedFields: Set<keyof RecordFormState["editableRecord"]> = new Set([
                        "recordKey",
                        "recordDocuments",
                        "recordDraft",
                    ]);
                    Object.keys(converted).forEach((key: keyof RecordFormState["editableRecord"]) => {
                        if (preservedFields.has(key)) {
                            // those fields we never take from the template
                            converted[key] = prevState[key] as never;
                        } else {
                            // the rest fields we update with the original form input if they are empty in the template
                            if (prevState[key]) {
                                converted[key] = prevState[key] as never;
                            }
                        }
                    });
                }
                return converted;
            });

            if (r.items?.length > 0) {
                if (r.items.length === 1) {
                    setRecordItems(prevState => {
                        // receiving new record: standard behaviour
                        if (!fromTemplate) {
                            receiveItem(r.items[0]);
                            return [];
                        }
                        // applying template for the record without split: merging data
                        if (!prevState.length) {
                            receiveItem(r.items[0], { fromTemplate: true });
                            return [];
                        }
                        // applying template for the record with split: do nothing
                        return prevState;
                    });
                } else {
                    setRecordItems(prevState => {
                        // receiving new record: standard behaviour
                        if (!fromTemplate) {
                            resetItem();
                            return r.items;
                        }
                        // if there are no split (only editableItem in the form) and it's empty, then we replace it with provided template
                        if (!prevState.length) {
                            const { itemBrutto, ...formItem } = refsData.formStateRef.current.editableRecordItem;
                            if (Object.values(formItem).every(v => !v)) {
                                return r.items;
                            }
                        }
                        return prevState;
                    });
                }
            }
        },
        [
            setRecordValidationStates,
            setItemValidationStates,
            setRecordDate,
            setEditableRecord,
            resetRecord,
            resetItem,
            setRecordItems,
            receiveItem,
        ]
    );

    const onClearForm = useCallback(() => {
        resetRecord();
        resetItem();

        setRecordValidationStates(new Map());
        setItemValidationStates(new Map());

        if (onClearExternal) {
            onClearExternal();
        }
        setTimeout(focusFirstFormField, 100);
    }, [onClearExternal, resetItem, resetRecord, setItemValidationStates, setRecordValidationStates]);

    const validateAndSave = useCallback(() => {
        if (!validateInlineForm()) {
            return;
        }
        const composedRecord = composeFormRecord();

        if (composedRecord.items?.length === 0) {
            logger.error(composedRecord);
            throw new Error("attempt to save a record without items");
        }

        onSaveExternal(composedRecord, formState.selectedPayment);

        resetItem();
        resetRecord();
        setIsSplitCardOpen(false);
        setTimeout(focusFirstFormField, 100);
    }, [
        validateInlineForm,
        composeFormRecord,
        onSaveExternal,
        formState.selectedPayment,
        resetItem,
        resetRecord,
        setIsSplitCardOpen,
    ]);

    return useMemo(
        () => ({ receiveItem, resetRecord, resetItem, receiveRecord, onClearForm, validateAndSave }),
        [receiveItem, resetRecord, resetItem, receiveRecord, onClearForm, validateAndSave]
    );
};
