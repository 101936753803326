import {
    DmsDefaultSubType,
    DmsTaxeSubType,
    DmsType,
    DmsTypeOption,
    IDocumentEnriched,
    TDmsTaxesOptions,
} from "@dms/types";
import { GQL } from "@binale-tech/shared";

export const DmsTypeOptions: DmsTypeOption[] = Object.freeze([
    {
        value: DmsType.new_documents,
        translationKey: `app.titles.${DmsType.new_documents}`,
    },
    {
        value: DmsType.uncategorized,
        translationKey: `app.titles.${DmsType.uncategorized}`,
    },
    { isDivider: true },
    {
        value: DmsType.Deb,
        translationKey: `app.titles.${DmsType.Deb}`,
    },
    {
        value: DmsType.ER,
        translationKey: `app.titles.${DmsType.ER}`,
    },
    {
        value: DmsType.Bank,
        translationKey: `app.titles.${DmsType.Bank}`,
        isGroup: true,
        defaultSubtypes: [
            {
                value: DmsDefaultSubType.all_subTypes,
                translationKey: "app.components.all",
            },
            {
                value: DmsDefaultSubType.no_subTypes,
                translationKey: "app.titles.uncategorized",
            },
        ],
    },
    {
        value: DmsType.KB,
        translationKey: `app.titles.${DmsType.KB}`,
        isGroup: true,
        defaultSubtypes: [
            {
                value: DmsDefaultSubType.all_subTypes,
                translationKey: "app.components.all",
            },
            {
                value: DmsDefaultSubType.no_subTypes,
                translationKey: "app.titles.uncategorized",
            },
        ],
    },
    {
        value: DmsType.FE,
        translationKey: `app.titles.${DmsType.FE}`,
    },
    {
        value: DmsType.POS,
        translationKey: `app.titles.${DmsType.POS}`,
    },
    {
        value: DmsType.LA,
        translationKey: `app.titles.${DmsType.LA}`,
    },
    { isDivider: true },
    {
        value: DmsType.taxes,
        translationKey: `app.titles.${DmsType.taxes}`,
        isGroup: true,
        defaultSubtypes: [
            {
                value: DmsDefaultSubType.all_subTypes,
                translationKey: "app.components.all",
            },
            {
                value: DmsDefaultSubType.no_subTypes,
                translationKey: "app.titles.uncategorized",
            },
        ],
    },
    {
        value: DmsType.contracts,
        translationKey: `app.titles.${DmsType.contracts}`,
        isGroup: true,
        defaultSubtypes: [
            {
                value: DmsDefaultSubType.all_subTypes,
                translationKey: "app.components.all",
            },
            {
                value: DmsDefaultSubType.no_subTypes,
                translationKey: "app.titles.uncategorized",
            },
        ],
    },
    {
        value: DmsType.company,
        translationKey: `app.titles.${DmsType.company}`,
        isGroup: true,
        defaultSubtypes: [
            {
                value: DmsDefaultSubType.all_subTypes,
                translationKey: "app.components.all",
            },
            {
                value: DmsDefaultSubType.no_subTypes,
                translationKey: "app.titles.uncategorized",
            },
        ],
    },
    { isDivider: true },
    {
        value: DmsType.trash,
        translationKey: `app.titles.${DmsType.trash}`,
    },
]) as DmsTypeOption[];

export const isGroupType = (type: DmsType) => DmsTypeOptions.find(t => t.value === type)?.isGroup;

export const DmsTaxesOptions: TDmsTaxesOptions[] = Object.freeze([
    {
        value: DmsTaxeSubType.vat,
        translationKey: `app.dms.${DmsTaxeSubType.vat}`,
    },
    {
        value: DmsTaxeSubType.corporation_tax,
        translationKey: `app.dms.${DmsTaxeSubType.corporation_tax}`,
    },
    {
        value: DmsTaxeSubType.business_tax,
        translationKey: `app.dms.${DmsTaxeSubType.business_tax}`,
    },
    {
        value: DmsTaxeSubType.statement,
        translationKey: `app.dms.${DmsTaxeSubType.statement}`,
    },
    {
        value: DmsTaxeSubType.income_tax,
        translationKey: `app.dms.${DmsTaxeSubType.income_tax}`,
    },
    {
        value: DmsTaxeSubType.other_taxes,
        translationKey: `app.dms.${DmsTaxeSubType.other_taxes}`,
    },
    {
        value: DmsTaxeSubType.tax_office,
        translationKey: `app.dms.${DmsTaxeSubType.tax_office}`,
    },
]) as TDmsTaxesOptions[];

export const necessaryFieldsForDocumentTypes: Readonly<
    Partial<Record<DmsType, readonly (keyof Partial<IDocumentEnriched>)[]>>
> = Object.freeze({
    [DmsType.Deb]: Object.freeze([
        "documentDate",
        "documentNumber",
        "documentAmount",
        "partner",
    ] as (keyof Partial<IDocumentEnriched>)[]),
    [DmsType.ER]: Object.freeze([
        "documentDate",
        "documentNumber",
        "documentAmount",
        "partner",
    ] as (keyof Partial<IDocumentEnriched>)[]),
    [DmsType.Bank]: Object.freeze(["documentDate", "documentAmount"] as (keyof Partial<IDocumentEnriched>)[]),
    [DmsType.KB]: Object.freeze(["documentDate", "documentAmount"] as (keyof Partial<IDocumentEnriched>)[]),
    [DmsType.FE]: Object.freeze(["documentDate", "documentAmount"] as (keyof Partial<IDocumentEnriched>)[]),
    [DmsType.taxes]: Object.freeze(["documentDate", "partner"] as (keyof Partial<IDocumentEnriched>)[]),
    [DmsType.contracts]: Object.freeze(["documentDate", "partner"] as (keyof Partial<IDocumentEnriched>)[]),
    [DmsType.company]: Object.freeze(["documentDate", "partner"] as (keyof Partial<IDocumentEnriched>)[]),
    [DmsType.POS]: Object.freeze(["documentDate"] as (keyof Partial<IDocumentEnriched>)[]),
    [DmsType.LA]: Object.freeze(["documentDate"] as (keyof Partial<IDocumentEnriched>)[]),
});

export const needToCheckIsAmountReady: readonly DmsType[] = Object.freeze([
    DmsType.Bank,
    DmsType.KB,
    DmsType.FE,
    DmsType.ER,
    DmsType.Deb,
]);

export const canBeZeroFields: readonly Partial<keyof GQL.IDocumentUpdateInput>[] = Object.freeze([
    "deadlineDays",
    "discountDays",
    "discountAmount",
    "discountPercent",
]);

export enum DmsPaths {
    ROOT = "documents",
    EDITING = "editing",
    EDITING_GROUP = "editing_group",
    DOCUMENT_ID = "documentId",
}

export const ALL_DOCUMENTS = "all_documents";
