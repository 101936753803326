import { AFRelationship, PDFDocument } from "pdf-lib";
import { ApolloClient, gql } from "@apollo/client";
import { GQL } from "@binale-tech/shared";
import { calculateHashArrayBuffer, hashCalculate } from "../../../scripts/infrastructure/helpers/hashCalculate";
import dayjs from "dayjs";

const invoiceXRechnung = gql`
    query invoiceXRechnung($input: InvoiceXRechnung!) {
        invoiceXRechnung(input: $input)
    }
`;

type Keys = {
    companyId: string;
    invoiceId: string;
};
export const createXRechnungPdfData = async (
    client: ApolloClient<unknown>,
    keys: Keys,
    pdfBuffer: Buffer,
    xRechnungData: Record<string, any>
): Promise<{
    url: string;
    filename: string;
    type: string;
    file: File;
    xmlBlob: Blob;
    hash: string;
}> => {
    const { companyId, invoiceId } = keys;
    const xmlUint8Array = await client
        .query<Pick<GQL.IQuery, "invoiceXRechnung">, GQL.IQueryInvoiceXRechnungArgs>({
            query: invoiceXRechnung,
            fetchPolicy: "network-only",
            variables: {
                input: {
                    companyId,
                    invoiceId,
                    json: JSON.stringify(xRechnungData),
                },
            },
        })
        .then(res => {
            const dataXml = res.data.invoiceXRechnung.replace("#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0", "");
            console.log(dataXml);
            // .replace("<ram:ApplicableHeaderTradeDelivery></ram:ApplicableHeaderTradeDelivery>", "");
            const enc = new TextEncoder();
            return enc.encode(dataXml);
        });
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const date = dayjs(xRechnungData.issue_date, "YYYY-MM-DD").toDate();

    pdfDoc.setModificationDate(date);
    pdfDoc.setCreationDate(date);
    await pdfDoc.attach(xmlUint8Array, "factur-x.xml", {
        mimeType: "application/xml",
        creationDate: date,
        modificationDate: date,
        afRelationship: AFRelationship.Alternative,
        description: "Factur-x invoice",
    });

    const pdfBytes = await pdfDoc.save();

    const type = "application/pdf";
    const blob = new Blob([pdfBytes], { type });
    const url = URL.createObjectURL(blob);
    const filename = [xRechnungData.issue_date, invoiceId].filter(Boolean).join("_");
    const file = new File([blob], filename, { lastModified: date.getTime() });
    const hash = await hashCalculate(file);
    return { hash, url, filename, type, file, xmlBlob: new Blob([xmlUint8Array], { type: "application/xml" }) };
};
