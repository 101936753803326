import React, { type FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider } from "antd";
import { FileUploaderContext, FileUploaderControlContext } from "../../context/FileUploaderContext";
import { FormattedMessage } from "react-intl";
import { NotUniqFile } from "./NotUniqFile";
import { TUploadFile } from "@dms/types";

export const NotUniqFileList: FC = () => {
    const [processedFiles, setProcessedFiles] = useState<Record<string, number>>({});

    const { allFilesInfo } = useContext(FileUploaderContext);
    const { deleteAllNotUniq, confirmChoiceNotUniq, removeNotUniqGroup } = useContext(FileUploaderControlContext);

    const allFilesInfoLength = allFilesInfo.length;
    const processedFilesLength = Object.keys(processedFiles).length;

    const notUniqFiles = useMemo(() => {
        if (!allFilesInfo.length) {
            return null;
        }

        const arr = allFilesInfo.filter(el => el.status === "not uniq");
        if (!arr.length) {
            return null;
        }

        const obj: Record<string, TUploadFile[]> = {};
        arr.forEach(el => {
            if (!obj[el.hash]) {
                return (obj[el.hash] = [el]);
            }
            obj[el.hash] = [...obj[el.hash], el];
        });

        return obj;
    }, [allFilesInfoLength, processedFilesLength]);

    useEffect(() => {
        setProcessedFiles(prev => {
            const copyPrev = { ...prev };
            Object.keys(notUniqFiles || {}).forEach(key => {
                if (prev[key]) {
                    return;
                }

                copyPrev[key] = 0;
            });

            return copyPrev;
        });
    }, [notUniqFiles]);

    const handleSelectNotUniqFiles = (key: string, idx: number) => {
        if (idx === -1) {
            removeNotUniqGroup(key, notUniqFiles);

            return setProcessedFiles(prev => {
                const copyPrev = { ...prev };
                delete copyPrev[key];
                return { ...copyPrev };
            });
        }

        setProcessedFiles(prev => {
            return { ...prev, [key]: idx };
        });
    };

    const handleConfirm = () => {
        confirmChoiceNotUniq(processedFiles, notUniqFiles);
        setProcessedFiles({});
    };

    const handleDeleteAll = () => {
        deleteAllNotUniq(processedFiles, notUniqFiles);
        setProcessedFiles({});
    };

    if (!notUniqFiles) {
        return null;
    }

    return (
        <div>
            <Divider />
            {Object.keys(notUniqFiles).map(hash => {
                return (
                    <NotUniqFile
                        key={hash}
                        hash={hash}
                        notUniqFiles={notUniqFiles ? notUniqFiles[hash] : []}
                        handleSelectNotUniqFiles={handleSelectNotUniqFiles}
                    ></NotUniqFile>
                );
            })}
            <div style={{ width: "100%", marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                <Button type={"primary"} onClick={handleConfirm}>
                    <FormattedMessage id="app.dms.confirmResolving" />
                </Button>
                <Button style={{ marginLeft: "10px" }} onClick={handleDeleteAll}>
                    <FormattedMessage id="app.dms.deleteAll" />
                </Button>
            </div>
            <Divider />
        </div>
    );
};
