import Menu, { MenuProps } from "antd/es/menu";
import React from "react";
import { AppRoutes } from "scripts/routing/routeConstants";
import { Link, useLocation } from "react-router-dom";
import { PdfToolsPaths } from "@pdf-tools/types";

type Props = { menuProps: MenuProps };
const ModulePDFLinks: React.FC<Props> = ({ menuProps }) => {
    const { pathname = "" } = useLocation();

    const link = AppRoutes.ocr + "/" + PdfToolsPaths.Stats;

    return (
        <Menu
            selectedKeys={[pathname]}
            {...menuProps}
            items={[{ key: link, label: <Link to={link}>Stats</Link> }]}
        ></Menu>
    );
};

export default React.memo(ModulePDFLinks);
