import React, { useCallback, useContext, useMemo } from "react";
import { CategoryCreditorModes } from "../../../../scripts/core/Product";
import { Category, Creditor, Tag } from "../../../../scripts/models";
import { logger } from "../../../../scripts/infrastructure/logger";
import { BuTaxesSKR } from "../../../../scripts/models/BuTaxUtils";
import { Bu } from "@binale-tech/shared";
import { Validator } from "../../shared/Validator";
import { BuContext } from "../../../../scripts/context/BuContext";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import RecordFormUtils from "../utils/RecordFormUtils";
import { useFormConfig } from "./useFormConfig";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { RecordFormStateContext, RecordFormStateControlContext } from "../context/RecordFormState";
import { FieldError } from "react-hook-form";

const getErrorStateUpdate = (
    prevState: Map<React.RefObject<any>, FieldError>,
    ref: React.RefObject<any>,
    isError: boolean
): Map<React.RefObject<any>, FieldError> => {
    if (isError) {
        prevState.set(ref, { type: "error", message: " " });
    } else {
        prevState.delete(ref);
    }
    return new Map(prevState);
};

export const useFormValidators = () => {
    const productFormConfig = useFormConfig();
    const { refsHtml, isTemplate } = useContext(RecordFormPropsContext);
    const formState = useContext(RecordFormStateContext);
    const { setRecordValidationStates, setItemValidationStates } = useContext(RecordFormStateControlContext);
    const { companyBuTimeframes } = React.useContext(BuContext);
    const { yearConfig } = React.useContext(CompanyContext);
    const { recordValidator, itemValidator } = useMemo(() => {
        const recordValidator: Validator = new Validator();
        const itemValidator: Validator = new Validator();
        const catCredCheck = (v: unknown, allowEmpty: boolean) => {
            if (allowEmpty) {
                if (v === null || v === undefined) {
                    return true;
                }
            }
            return v instanceof Creditor || v instanceof Category;
        };

        const isRCCHidden = () =>
            !productFormConfig.recordAccountMode ||
            productFormConfig.recordAccountMode === CategoryCreditorModes.RESOLVER;
        if (isRCCHidden()) {
            recordValidator.addRule<Creditor>(refsHtml.REF_rCATEGORYCREDITOR, v => catCredCheck(v, true));
        } else {
            recordValidator.addRule<Creditor>(refsHtml.REF_rCATEGORYCREDITOR, (v, state) => {
                const allowEmpty = state.editableRecord.recordDraft;
                return catCredCheck(v, allowEmpty);
            });
        }
        recordValidator.addRule<string>(refsHtml.REF_rBELEGFELD1, (v, state) => {
            if (!productFormConfig.isRecordRechnungRequired) {
                return true;
            }
            if (state.editableRecord.recordDraft) {
                return true;
            }
            v = v || "";
            return v.length > 0;
        });
        recordValidator.addRule<string>(refsHtml.REF_rCONTACT, (v, state) => {
            if (!productFormConfig.useContact) {
                return true;
            }
            if (isTemplate) {
                return Boolean(v);
            }
            return true;
        });
        recordValidator.addRule<number>(refsHtml.REF_rBRUTTO, (v, state) => {
            if ((v || 0) === 0) {
                return isTemplate;
            }
            if (state.recordItems.length > 0) {
                const { isSaveActive } = RecordFormUtils.getFormStateDetails(state);
                return isSaveActive;
            }
            return true;
        });

        itemValidator.addRule<string>(refsHtml.REF_iBELEGFELD1, (v, state) => {
            if (!productFormConfig.useItemBelegfeld1) {
                return true;
            }
            if (state.editableRecord.recordDraft) {
                return true;
            }
            v = v || "";
            return v.length > 0;
        });
        itemValidator.addRule<Category | Creditor>(refsHtml.REF_iCATEGORYCREDITOR, (v, state) => {
            const allowEmpty = state.editableRecord.recordDraft;
            return catCredCheck(v, allowEmpty);
        });
        itemValidator.addRule<number>(refsHtml.REF_iBRUTTO, v => (v || 0) !== 0);
        itemValidator.addRule<Bu.Bu>(refsHtml.REF_iBU, (v, state) => {
            if (companyBuTimeframes?.length === 0) {
                logger.crit(new Error(`bu timeframe fallback`));
            }
            const { date, period } = state.recordDate;
            const buTaxList = BuTaxesSKR.listBuTaxesForYearPeriod(
                yearConfig.skr,
                date.getFullYear(),
                period,
                companyBuTimeframes
            );
            const filtered = buTaxList.filter(item => item.bu === v);
            if (!filtered.length) {
                logger.crit(new Error(`bu not found >${v}<, taxList ${buTaxList.length}`), {
                    buTaxList,
                    companyBuTimeframes,
                    date: date?.toDateString(),
                });
                return false;
            }
            const { itemUSt13b } = state.editableRecordItem;
            const ust13bOptions = Bu.getUst13bData(v).options;

            if (ust13bOptions && itemUSt13b) {
                return true;
            }
            if (!ust13bOptions && !itemUSt13b) {
                return true;
            }
            return false;
        });
        itemValidator.addRule<Tag>(refsHtml.REF_iTAG, v => {
            return v === null || v === undefined || v instanceof Tag;
        });
        itemValidator.addRule<string>(refsHtml.REF_iTEXT, v => v === null || v === undefined || v.length >= 0);
        itemValidator.addRule<string>(refsHtml.REF_iTEXT2, v => v === null || v === undefined || v.length >= 0);

        return {
            recordValidator,
            itemValidator,
        };
    }, [productFormConfig, refsHtml, companyBuTimeframes, yearConfig]);

    const validateInlineForm = useCallback(() => {
        const { isSplitCardOpen, recordItems } = formState;
        const { itemCategoryCreditor, itemBu, itemTag, itemText, itemText2 } = formState.editableRecordItem;
        const { recordCategoryCreditor, recordNum, recordBrutto, recordContact } = formState.editableRecord;
        const fieldsRecord = new Map([
            [refsHtml.REF_rCATEGORYCREDITOR as any, recordCategoryCreditor as any],
            [refsHtml.REF_rBELEGFELD1, recordNum],
            [refsHtml.REF_rBRUTTO, recordBrutto],
        ]);
        if (productFormConfig.useItemBelegfeld1) {
            fieldsRecord.delete(refsHtml.REF_rBELEGFELD1);
        }
        if (productFormConfig.useContact) {
            fieldsRecord.set(refsHtml.REF_rCONTACT, recordContact);
        }
        const fieldsItem = new Map();
        if (recordItems.length <= 1 && !isSplitCardOpen) {
            fieldsItem.set(refsHtml.REF_iBU, itemBu as any);
            fieldsItem.set(refsHtml.REF_iTAG, itemTag);
            fieldsItem.set(refsHtml.REF_iTEXT, itemText);
            fieldsItem.set(refsHtml.REF_iTEXT2, itemText2);
            if (productFormConfig.useItemAccount) {
                fieldsItem.set(refsHtml.REF_iCATEGORYCREDITOR, itemCategoryCreditor as any);
            }
        }

        const errorsRecord = recordValidator.validate(fieldsRecord, formState);
        const errorsItem = itemValidator.validate(fieldsItem, formState);
        const recordValidationStates = new Map();
        const itemValidationStates = new Map();
        errorsRecord.forEach(field => recordValidationStates.set(field, "error"));
        errorsItem.forEach(field => itemValidationStates.set(field, "error"));
        setRecordValidationStates(new Map(recordValidationStates));
        setItemValidationStates(new Map(itemValidationStates));

        return errorsItem.length + errorsRecord.length === 0;
    }, [
        formState,
        refsHtml.REF_rCATEGORYCREDITOR,
        refsHtml.REF_rBELEGFELD1,
        refsHtml.REF_rBRUTTO,
        refsHtml.REF_iBU,
        refsHtml.REF_iTAG,
        refsHtml.REF_iTEXT,
        refsHtml.REF_iTEXT2,
        refsHtml.REF_iCATEGORYCREDITOR,
        productFormConfig.useItemBelegfeld1,
        productFormConfig.useItemAccount,
        recordValidator,
        itemValidator,
        setRecordValidationStates,
        setItemValidationStates,
    ]);

    const setRecordFieldErrorState = useCallback(
        (ref: React.RefObject<any>, isError: boolean) => {
            setRecordValidationStates(prevState => {
                return getErrorStateUpdate(prevState, ref, isError);
            });
        },
        [setRecordValidationStates]
    );
    const setItemFieldErrorState = useCallback(
        (ref: React.RefObject<any>, isError: boolean) => {
            setItemValidationStates(prevState => {
                return getErrorStateUpdate(prevState, ref, isError);
            });
        },
        [setItemValidationStates]
    );

    return useMemo(
        () => ({
            validateInlineForm,
            itemValidator,
            recordValidator,
            setRecordFieldErrorState,
            setItemFieldErrorState,
        }),
        [validateInlineForm, itemValidator, recordValidator, setRecordFieldErrorState, setItemFieldErrorState]
    );
};
