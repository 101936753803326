import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Flex, Form, Space, Spin, Tooltip } from "antd";
import { CheckCircleOutlined, ExclamationCircleTwoTone, ExclamationOutlined, LoadingOutlined } from "@ant-design/icons";
import { InvoiceFormInputTranslate, TInvoicesValues } from "@inv/types";

interface IProps {
    success: boolean;
    isLoading: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    isConfirmed: boolean;
}

export const InvoicesControlBlock: FC<IProps> = ({ success, isLoading, onCancel, onConfirm, isConfirmed }) => {
    const form = Form.useFormInstance<TInvoicesValues>();
    const formValues = Form.useWatch([], form);
    const isSaveEnabled = useMemo(() => {
        if (!formValues?.date) {
            return false;
        }
        if (formValues?.lineItems?.length >= 1) {
            return formValues.lineItems.every(item => item.productsServices && item.price);
        }
        return false;
    }, [formValues]);
    const isConfirmEnabled = useMemo(() => {
        if (isConfirmed) {
            return false;
        }
        if (!isSaveEnabled) {
            return false;
        }
        if (!formValues?.invoiceNumberFormatId && !formValues?.invoiceNumber) {
            return false;
        }
        return true;
    }, [isConfirmed, isSaveEnabled, formValues]);

    return (
        <Flex justify={"space-between"} style={{ width: "100%", padding: 10 }}>
            <div>
                <Button onClick={onCancel}>
                    <FormattedMessage id={InvoiceFormInputTranslate.CANCEL} />
                </Button>
            </div>
            <Space>
                {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
                {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                <Button
                    icon={<ExclamationOutlined />}
                    disabled={!isConfirmEnabled}
                    onClick={onConfirm}
                    style={
                        isConfirmEnabled
                            ? { borderColor: "#52c41a", backgroundColor: "#52c41a", color: "white" }
                            : undefined
                    }
                >
                    Fertigstellen
                </Button>
                <Tooltip
                    title={isSaveEnabled ? undefined : <FormattedMessage id={InvoiceFormInputTranslate.SAVE_HINT} />}
                >
                    <Button
                        shape={"circle"}
                        icon={<ExclamationCircleTwoTone />}
                        type={"text"}
                        style={isSaveEnabled ? { opacity: 0, cursor: "default" } : undefined}
                    />
                </Tooltip>
                <Button type="primary" htmlType="submit" disabled={!isSaveEnabled}>
                    <FormattedMessage id={InvoiceFormInputTranslate.SAVE} />
                </Button>
            </Space>
        </Flex>
    );
};
