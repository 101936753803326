import { useContext } from "react";
import { TGoBLOrgParty } from "@inv/types";
import { CompanyContext } from "../../scripts/context/CompanyContext";

export const useInvoiceSupplier = (): TGoBLOrgParty => {
    const { companyGQL } = useContext(CompanyContext);
    const { legalName, invoiceAddress, invoiceReceiver } = companyGQL;

    const getTaxId = (): TGoBLOrgParty["tax_id"] => {
        if (companyGQL.invoiceVat) {
            return {
                country: companyGQL.invoiceVat.countryCode,
                code: companyGQL.invoiceVat.vatNumber,
                type: "VA",
            };
        }
        if (companyGQL.invoiceTax) {
            return {
                country: "DE",
                code: companyGQL.invoiceTax.stnr,
                type: "FC",
            };
        }
        return null;
    };

    return {
        name: `${legalName ?? ""}`.trim(),
        tax_id: getTaxId(),
        addresses: [
            {
                num: invoiceAddress?.house,
                street: invoiceAddress?.street,
                locality: invoiceAddress?.city,
                code: invoiceAddress?.postCode,
                country: invoiceAddress?.countryCode,
                street_extra: invoiceAddress?.addressLine2,
            },
        ],
        emails: [
            {
                addr: companyGQL.invoiceEmail,
            },
        ],
        people: [
            {
                name: invoiceReceiver
                    ? {
                          given: invoiceReceiver,
                      }
                    : undefined,
                emails: [
                    {
                        addr: companyGQL.invoiceEmail,
                    },
                ],
            },
        ],
        telephones: [],
    };
};
