import { useContext } from "react";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { ContactsContext } from "../../scripts/context/ContactsContext";
import { Contacts, GQL } from "@binale-tech/shared";
import { TGoBLOrgParty } from "@inv/types";

export const useInvoiceCustomer = (invoiceId?: string): TGoBLOrgParty => {
    const { invoicesMap } = useContext(InvoicesDataContext);
    const { contactsMap } = useContext(ContactsContext);
    if (!invoiceId) {
        return null;
    }
    const invoice = invoicesMap.get(invoiceId);
    const contact = contactsMap.get(invoice.contactId);
    const getCustomerName = () => {
        if (invoice.customerName) {
            return invoice.customerName;
        }
        return contact ? Contacts.getLabelName(contact) : undefined;
    };

    return {
        name: getCustomerName(),
        tax_id: invoice.customerVat
            ? { country: invoice.customerVat.countryCode, code: invoice.customerVat.vatNumber }
            : {
                  country: contact?.legalInfo?.vat?.countryCode,
                  code: contact?.legalInfo?.vat?.vatNumber,
              },
        addresses: [
            {
                num: invoice.houseNumber,
                street: invoice.street,
                locality: invoice.city,
                code: invoice.zipCode,
                country: invoice.countryCode,
                street_extra: invoice.address,
            },
        ],
        emails: invoice.customerEmail
            ? [{ addr: invoice.customerEmail }]
            : (contact?.communications || [])
                  .filter(c => c.type === GQL.ICommunicationType.Email)
                  .map(c => ({ addr: c.value })),
        telephones: (contact?.communications || [])
            .filter(c => c.type === GQL.ICommunicationType.Phone)
            .map(c => ({ num: c.value })),
    };
};
