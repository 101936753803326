import React, { FC, forwardRef, useEffect, useRef, useState } from "react";
import { Col, Form, type InputProps, InputRef, Row, Typography } from "antd";
import { BaseInput } from "../../../../appearance/components/shared/form/baseComponents";
import { ICountriesOption } from "../../../../appearance/views/productContacts/components/CountriesCombobox";
import { Contacts } from "@binale-tech/shared";
import { validateInput } from "../../../../scripts/infrastructure/helpers/validateVatInput";
import cn from "classnames";

import styles from "./documetInputs.module.scss";
import { useIsMounted } from "../../../../scripts/infrastructure/hooks";

interface IProps extends Omit<InputProps, "onChange" | "value"> {
    onChange?: (val: string) => void;
    value?: string;
    options: ICountriesOption[];
    disabled?: boolean;
}
export const VatInput: FC<IProps> = forwardRef<InputRef, IProps>(
    ({ options, value, onChange, disabled, ...restProps }, ref) => {
        const form = Form.useFormInstance();
        const landCode = form.getFieldValue("landCode");
        const landCodeRef = useRef(landCode);
        const [error, setError] = useState<string>("");
        const [focused, setFocused] = useState(false);

        const maxLength = landCode ? Contacts.ContactConstants.CountriesEuVatLength[landCode] : 10;

        const handleFocus = () => {
            setFocused(true);
        };

        const handleBlur = () => {
            setFocused(false);
            if (!value && landCode) {
                form.setFields([
                    {
                        name: "UStIdNr",
                        errors: ["Incorrect data format"],
                    },
                ]);
            }
        };

        const handleInputChange = (val: string) => {
            onChange(val);
            const isValid = validateInput(landCode, val);

            if (isValid) {
                setError("");
                form.setFields([
                    {
                        name: "UStIdNr",
                        errors: [],
                    },
                ]);
            } else {
                setError("Incorrect data format");
                form.setFields([
                    {
                        name: "UStIdNr",
                        errors: ["Incorrect data format"],
                    },
                ]);
            }
        };

        useEffect(() => {
            if (disabled) {
                return;
            }
            if (!landCodeRef.current || landCodeRef.current === landCode) {
                landCodeRef.current = landCode;
                return;
            }
            setError("");
            form.setFields([
                {
                    name: "UStIdNr",
                    value: "",
                    errors: [],
                },
            ]);
            landCodeRef.current = landCode;
        }, [form, landCode, disabled]);

        return (
            <Row align="middle" gutter={10}>
                <Col lg={10} xl={11} xxl={12}>
                    <BaseInput
                        {...restProps}
                        disabled={disabled}
                        ref={ref}
                        id="vatNbId"
                        maxLength={maxLength}
                        value={value}
                        status={error ? "error" : undefined}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    <div
                        className={cn({
                            [styles.charCounter]: true,
                            [styles.show]: focused,
                        })}
                    >
                        {value?.length} / {maxLength}
                    </div>
                </Col>
                <Col lg={14} xl={13} xxl={12}>
                    <Typography style={{ whiteSpace: "nowrap" }}>
                        {options.find(option => option.value === landCode)?.name || ""}
                    </Typography>
                </Col>
            </Row>
        );
    }
);
