import React from "react";
import { Navigate, Route } from "react-router-dom";

import AuthActionsView from "appearance/views/auth/AuthActionsView";
import AuthWrapper from "./AuthWrapper";
import HomeView from "appearance/views/HomeView";
import VerifyEmailView from "appearance/views/auth/VerifyEmailView";
import { AGBView } from "appearance/views/static/AGBView";
import { ERAnzView } from "@app/views/productER/erfassung/ERAnzView";
import { AppRoutes, getRoute, ModuleSubGroups } from "./routeConstants";
import { AuswertungAvisViewER, AuswertungAvisViewErA } from "appearance/views/productER/auswertung/AuswertungAvisView";
import {
    AuswertungFalligkeitER,
    AuswertungFalligkeitViewErA,
} from "appearance/views/productER/auswertung/AuswertungFalligkeitView";
import { AuswertungOposViewER, AuswertungOposViewErA } from "appearance/views/productER/auswertung/AuswertungOposView";
import { BankView } from "@app/views/productBank/BankView";
import { BanksManageView } from "appearance/views/productBank/BanksManageView";
import { CommonAuswertungView } from "appearance/views/productCommon/overviews/CommonAuswertungView";
import { CompaniesView } from "appearance/views/companies/CompaniesView";
import { DatenschutzView } from "appearance/views/static/DatenschutzView";
import { DebView } from "@app/views/productDeb/DebView";
import { FestschreibungAZView, FestschreibungERView } from "appearance/views/productER/FestschreibungView";
import { FEView } from "@app/views/productFE/FEView";
import { HaftungsausschlussView } from "appearance/views/static/HaftungsausschlussView";
import { ImpressumView } from "appearance/views/static/ImpressumView";
import { KBManageView } from "@app/views/productKB/KBManageView";
import { KBView } from "@app/views/productKB/KBView";
import { KontoAnsichtView } from "appearance/views/productCommon/overviews/KontenAnsicht/KontoAnsichtView";
import { LAView } from "appearance/views/productLA/LAView";
import { LoginView } from "appearance/views/auth/LoginView";
import { NewPasswordView } from "appearance/views/auth/NewPasswordView";
import { NotFoundView } from "appearance/views/static/ErrorViews";
import { RegisterView } from "appearance/views/auth/RegisterView";

import EditCategoryView from "appearance/views/productCommon/items/EditCategoryView";
import EditCreditorView from "appearance/views/productCommon/items/EditCreditorView";
import EditDebView from "appearance/views/productCommon/items/EditDebView";
import EditTagView from "appearance/views/productCommon/items/EditTagView";
import { EditContactView } from "appearance/views/productContacts";

import DatevExportView from "appearance/views/productCommon/importExport/DatevExportView";
import DatevImportView from "appearance/views/productCommon/importExport/DatevImportView";
import { DMS } from "@dms/index";

import PdfStatsContainer from "pdf-tools/PdfStatsContainer";
import PdfToolContainer from "pdf-tools/PdfToolContainer";
import { AdminView } from "@app/views/admin/AdminView";
import { AuswertungAvisViewDeb } from "@app/views/productDeb/auswertung/AuswertungAvisViewDeb";
import { AuswertungOposViewDeb } from "@app/views/productDeb/auswertung/AuswertungOposViewDeb";
import { KbBankHomePage } from "appearance/components/company/CompanyDetailRequest";
import { POSView } from "@app/views/productPOS/POSView";
import { PasswordResetView } from "appearance/views/auth/PasswordResetView";
import { ProductKeys } from "../models/Product";
import { ProfileView } from "appearance/views/profile/ProfileView";
import { ERView } from "@app/views/productER/erfassung/ERView";
import { SusaView } from "appearance/views/productCommon/overviews/SuSa/SusaView";
import { TestView } from "appearance/views/TestView";
import VatView from "appearance/views/productCommon/overviews/UStVA";
import { OAuthCallback } from "@app/components/oauth/OAuthCallback";
import { BWAView } from "@app/views/productCommon/overviews/BWA/BWAView";
import { Banks } from "../../banks";
import { Invoices } from "@inv/index";
import { ERTemplatesView } from "@app/views/productER/templates/ERTemplatesView";
import { KBTemplatesView } from "@app/views/productKB/templates/KBTemplatesView";
import { BankTemplatesView } from "@app/views/productBank/templates/BankTemplatesView";
import { FETemplatesView } from "@app/views/productFE/templates/FETemplatesView";
import { POSTemplatesView } from "@app/views/productPOS/templates/POSTemplatesView";
import { DebTemplatesView } from "@app/views/productDeb/templates/DebTemplatesView";
import { LATemplatesView } from "@app/views/productLA/templates/LATemplatesView";
import { GQL } from "@binale-tech/shared";
import PdfToolsApp from "@pdf-tools/PdfToolsApp";

export const routeSwitch = (
    <React.Fragment>
        <Route path="/" element={<Navigate to={AppRoutes.home} />} />
        <Route
            index
            path={AppRoutes.home}
            element={
                <AuthWrapper.AuthenticatedRoute>
                    <HomeView />
                </AuthWrapper.AuthenticatedRoute>
            }
        />
        <Route
            path={AppRoutes.manageCompanies}
            element={
                <AuthWrapper.AuthenticatedRoute>
                    <CompaniesView />
                </AuthWrapper.AuthenticatedRoute>
            }
        />
        <Route
            path={AppRoutes.profile}
            element={
                <AuthWrapper.AuthenticatedRoute>
                    <ProfileView />
                </AuthWrapper.AuthenticatedRoute>
            }
        />
        {/* ER*/}
        {/* ER=>Rechnungen */}
        <Route
            path={AppRoutes.invoice}
            element={<Navigate to={getRoute(AppRoutes.invoiceModule, { subGroup: ModuleSubGroups.ER })} />}
        />
        <Route
            path={getRoute(AppRoutes.invoiceModule, { subGroup: ModuleSubGroups.ER })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <ERView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceAnalysisOpos, { subGroup: ModuleSubGroups.ER })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <AuswertungOposViewER />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceAnalysisAvis, { subGroup: ModuleSubGroups.ER })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <AuswertungAvisViewER />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceAnalysisFalligkeit, { subGroup: ModuleSubGroups.ER })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <AuswertungFalligkeitER />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceFestschreibung, { subGroup: ModuleSubGroups.ER })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <FestschreibungERView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceTemplates, { subGroup: ModuleSubGroups.ER })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <ERTemplatesView />
                </AuthWrapper.ProductRoute>
            }
        />

        {/* ER=>Anzahlungen */}
        <Route
            path={getRoute(AppRoutes.invoiceModule, { subGroup: ModuleSubGroups.ErA })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <ERAnzView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceAnalysisOpos, { subGroup: ModuleSubGroups.ErA })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <AuswertungOposViewErA />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceAnalysisAvis, { subGroup: ModuleSubGroups.ErA })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <AuswertungAvisViewErA />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceAnalysisFalligkeit, { subGroup: ModuleSubGroups.ErA })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <AuswertungFalligkeitViewErA />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceFestschreibung, { subGroup: ModuleSubGroups.ErA })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <FestschreibungAZView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={getRoute(AppRoutes.invoiceTemplates, { subGroup: ModuleSubGroups.ErA })}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Er}>
                    <ERTemplatesView isAZ />
                </AuthWrapper.ProductRoute>
            }
        />

        {/* KB */}
        <Route
            path={AppRoutes.kassenbuch}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Kb}>
                    <KbBankHomePage productKey={GQL.IProductKey.Kb} />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.kassenbuchTemplates}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Kb}>
                    <KBTemplatesView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.kassenbuchOverview}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Kb}>
                    <KBView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.kassenbuchManage}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Kb}>
                    <KBManageView />
                </AuthWrapper.ProductRoute>
            }
        />

        {/* Bank */}
        <Route
            path={AppRoutes.bank}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Bank}>
                    <KbBankHomePage productKey={GQL.IProductKey.Bank} />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.bankTemplates}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Bank}>
                    <BankTemplatesView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.bankOverview}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Bank}>
                    <BankView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.manageBanks}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Bank}>
                    <BanksManageView />
                </AuthWrapper.ProductRoute>
            }
        />

        {/* FE */}
        <Route
            path={AppRoutes.freieErfassung}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Fe}>
                    <FEView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.freieErfassungTemplates}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Fe}>
                    <FETemplatesView />
                </AuthWrapper.ProductRoute>
            }
        />

        {/* POS */}
        <Route
            path={AppRoutes.pos}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Pos}>
                    <POSView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.posTemplates}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Pos}>
                    <POSTemplatesView />
                </AuthWrapper.ProductRoute>
            }
        />

        {/*  Deb  */}
        <Route
            path={AppRoutes.debitor}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Deb}>
                    <DebView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.debitorTemplates}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Deb}>
                    <DebTemplatesView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.debitorAnalysisOpos}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Deb}>
                    <AuswertungOposViewDeb />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.debitorAnalysisAvis}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Deb}>
                    <AuswertungAvisViewDeb />
                </AuthWrapper.ProductRoute>
            }
        />

        {/* LA */}
        <Route
            path={AppRoutes.paySlip}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.La}>
                    <LAView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.paySlipTemplates}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.La}>
                    <LATemplatesView />
                </AuthWrapper.ProductRoute>
            }
        />

        {/*  Common Overviews  */}
        <Route
            path={AppRoutes.SuSa}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon}>
                    <SusaView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.BWA}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon}>
                    <BWAView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route path={AppRoutes.UStVA} element={<Navigate to={AppRoutes.UStVA + AppRoutes.UStVAOverview} />} />
        <Route
            path={AppRoutes.UStVA + "/*"}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon} disableTransition>
                    <VatView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.accountsView}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon}>
                    <KontoAnsichtView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.filtersAndSearchView}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon}>
                    <CommonAuswertungView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route path={AppRoutes.manageAccount} element={<Navigate to={AppRoutes.manageAccountCategories} />} />
        <Route
            path={AppRoutes.manageAccountCategories}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon} verticalTransition>
                    <EditCategoryView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.manageAccountCreditors}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon} verticalTransition>
                    <EditCreditorView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.manageAccountDebitor}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon} verticalTransition>
                    <EditDebView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.manageAccountTag}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon} verticalTransition>
                    <EditTagView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.contacts}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Contacts} verticalTransition>
                    <EditContactView />
                </AuthWrapper.ProductRoute>
            }
        />

        <Route path={AppRoutes.recordImportExport} element={<Navigate to={AppRoutes.recordImport} />} />
        <Route
            path={AppRoutes.recordImport}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon}>
                    <DatevImportView />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.recordExport}
            element={
                <AuthWrapper.ProductRoute productKey={ProductKeys.AccountingCommon} role={GQL.IUserRole.DatevExporter}>
                    <DatevExportView />
                </AuthWrapper.ProductRoute>
            }
        />

        {/* Pdf Tool, we can allow partial company setup (without SKR and other accounting data) */}
        <Route
            path={AppRoutes.ocr + "/*"}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Ocr} skipCompanySetup>
                    <PdfToolsApp />
                </AuthWrapper.ProductRoute>
            }
        />
        <Route
            path={AppRoutes.dms + "/*"}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Dms} skipCompanySetup disableTransition>
                    <DMS />
                </AuthWrapper.ProductRoute>
            }
        />

        {/*Banks*/}
        <Route
            path={AppRoutes.banks + "/*"}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Banks} skipCompanySetup disableTransition>
                    <Banks />
                </AuthWrapper.ProductRoute>
            }
        />

        {/*Invoices*/}
        <Route
            path={AppRoutes.invoicing + "/*"}
            element={
                <AuthWrapper.ProductRoute productKey={GQL.IProductKey.Invoices} skipCompanySetup disableTransition>
                    <Invoices />
                </AuthWrapper.ProductRoute>
            }
        />

        <Route
            path={`${AppRoutes.admin}`}
            element={
                <AuthWrapper.AdminRoute>
                    <AdminView />
                </AuthWrapper.AdminRoute>
            }
        />

        <Route
            path={AppRoutes.authLogin}
            element={
                <AuthWrapper.GuestOnlyRoute>
                    <LoginView />
                </AuthWrapper.GuestOnlyRoute>
            }
        />
        <Route
            path={AppRoutes.authPasswordReset}
            element={
                <AuthWrapper.GuestOnlyRoute>
                    <PasswordResetView />
                </AuthWrapper.GuestOnlyRoute>
            }
        />
        <Route
            path={AppRoutes.authNewPassword}
            element={
                <AuthWrapper.GuestOnlyRoute>
                    <NewPasswordView />
                </AuthWrapper.GuestOnlyRoute>
            }
        />
        <Route
            path={AppRoutes.authRegister}
            element={
                <AuthWrapper.GuestOnlyRoute>
                    <RegisterView />
                </AuthWrapper.GuestOnlyRoute>
            }
        />
        <Route path={AppRoutes.authView} element={<AuthActionsView />} />
        <Route path={AppRoutes.authVerifyEmail} element={<VerifyEmailView />} />

        <Route path="/test" element={<TestView />} />
        <Route path={AppRoutes.datevCallback} element={<OAuthCallback />} />
        <Route path={AppRoutes.agb} element={<AGBView />} />
        <Route path={AppRoutes.impressum} element={<ImpressumView />} />
        <Route path={AppRoutes.datenschutz} element={<DatenschutzView />} />
        <Route path={AppRoutes.haftungsausschluss} element={<HaftungsausschlussView />} />
        <Route path="*" element={<NotFoundView />} />
    </React.Fragment>
);
