import React, { type FC, useCallback, useContext } from "react";
import { IHeaderParams } from "ag-grid-community";
import { ViewColumnsControl } from "@inv/components/ViewColumnsControl";
import { InvoicesListColumn, InvoicesListColumnTranslate } from "@inv/types";
import { InvoicesListTableColumns } from "../../config/tableColumns";
import { Flex, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { InvoicesDataContext, InvoicesListControlContext } from "@inv/context/InvoicesDataProvider";
import { SearchOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const ActionControlColumns: FC<IHeaderParams> = ({ api }) => {
    const { invoiceListColumnsConfig, isOpenSettingsColumns } = useContext(InvoicesDataContext);
    const { setColumnConfig, setViewConfig } = useContext(InvoicesListControlContext);
    const [openSearch, setOpenSearch] = React.useState(false);

    const toggleFloatingFilter = useCallback(() => {
        const allColumns = api.getColumnDefs();

        if (openSearch) {
            api.setFilterModel({});
        }

        const newColModel = allColumns.map(column => {
            return {
                ...column,
                floatingFilter: !openSearch,
            };
        });
        api.updateGridOptions({ columnDefs: newColModel });
        setOpenSearch(prev => !prev);
    }, [openSearch]);

    return (
        <Flex align="center" justify="space-between" style={{ width: "100%" }}>
            <Text>
                <FormattedMessage id={InvoicesListColumnTranslate[InvoicesListColumn.ACTION]} />
            </Text>
            <Flex align="center" gap={10} justify={"flex-end"} style={{ width: "100%" }}>
                <SearchOutlined style={{ zIndex: 10 }} onClick={toggleFloatingFilter} />
                <ViewColumnsControl
                    columnsConfig={invoiceListColumnsConfig}
                    setColumnsConfig={setColumnConfig}
                    columnTranslations={InvoicesListColumnTranslate}
                    setIsOpenSettingsCol={setViewConfig}
                    isOpenSettingsCol={isOpenSettingsColumns}
                    requiredColumns={InvoicesListTableColumns.requiredColumnsConfig}
                />
            </Flex>
        </Flex>
    );
};
