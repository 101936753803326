import React, { FC } from "react";
import { Flex } from "antd";

import { InvoicesListHeader } from "./components/InvoicesListHeader";
import { InvoicesList } from "./components/InvoicesList";

export const InvoicesListLayout: FC = () => {
    return (
        <Flex
            vertical
            style={{
                height: "100%",
            }}
        >
            <InvoicesListHeader />
            <InvoicesList />
        </Flex>
    );
};
