import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import { Base, GQL } from "@binale-tech/shared";
import { notification } from "antd";
import { onAuthStateChanged } from "firebase/auth";
import { useApolloClient } from "@apollo/client";

import { AppError, AppSettingsContext } from "./AppSettingsProvider";
import { auth } from "../api/firebase/firebase";
import { child, onValue, set } from "firebase/database";
import { getIPFingerprint } from "../infrastructure/helpers/browser";
import { getPrivateToken, getUserData } from "./queries/ctxQueries.graphql";
import { refUsersSessions } from "../api/firebase/firebaseRootRefs";
import { useGqlMutator } from "../graphql/useGqlMutator";
import { userToggle2fa } from "./mutations/userMutation.graphql";
import { ApolloQueryResult } from "@apollo/client/core/types";

type Controller = {
    onSelectCompany: (companyId: string) => Promise<unknown>;
    savePrivateToken: (token: string) => Promise<unknown>;
    removePrivateToken: () => Promise<unknown>;
    getPrivateToken: () => Promise<string>;
    fetchUserData: () => Promise<GQL.IUser>;
};

export const UserControlContext = createContext<Controller>({
    onSelectCompany: (companyId: string) => Promise.reject(companyId),
    savePrivateToken: (token: string) => Promise.reject(token),
    removePrivateToken: () => Promise.reject(),
    getPrivateToken: () => Promise.reject(),
    fetchUserData: () => Promise.reject(),
});

export type UserProviderCompany = Pick<GQL.ICompany, "id" | "name" | "datevNrCompany" | "datevNrConsultant">;

export const UserContext = createContext<Base.UserInterface>({ isLoading: true, fireUser: null });
export const UserCompaniesContext = createContext<UserProviderCompany[]>([]);
const getStorageKey = (uid: string) => `selectedCompany:${uid}`;

export const UserProvider: React.FC<React.PropsWithChildren> = props => {
    const client = useApolloClient();
    const [isLoading, setLoading] = useState(true);
    const [fireUser, setFireUser] = useState<Base.UserInterface["fireUser"]>(null);
    const [claims, setClaims] = useState<Pick<Base.UserInterface, "isAdmin" | "isAdminWrite">>({});
    const [userData, setUserData] = useState<Pick<Base.UserInterface, "is2faEnabled">>({});
    const [companies, setCompanies] = useState<UserProviderCompany[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>();
    const Mutator = useGqlMutator();
    const { setAppError } = useContext(AppSettingsContext);

    const fetchUserData = useCallback(async () => {
        const userRes = await client.query<Pick<GQL.IQuery, "me">>({
            query: getUserData,
            fetchPolicy: "network-only",
        });
        const { isAdmin, isAdminWrite, is2faEnabled, companies: userCompanies } = userRes.data.me;
        setClaims({ isAdmin, isAdminWrite });
        setUserData({ is2faEnabled });
        setCompanies(userCompanies);
        return userRes.data.me;
    }, [client]);

    useEffect(() => {
        const reset = () => {
            Sentry.setUser(null);
            setLoading(false);
            setFireUser(null);
            setClaims({});
            setUserData({});
            setCompanies([]);
            setSelectedCompany(null);
        };
        const showAuthNotification = () => {
            notification.open({
                onClose: () => {
                    auth.signOut();
                },
                message: <span>Simultaneous login detected</span>,
                description: (
                    <div>
                        <b>Somebody logged in with your credentials</b>
                        <br />
                        <span>
                            Unfortunately, this is impossible to work on multiple computers using the same credentials.
                            You will be logged out in 5 seconds.
                        </span>
                    </div>
                ),
            });
        };
        let unsubscribeUser: () => void = () => {};

        onAuthStateChanged(auth, async fireUserData => {
            if (fireUserData) {
                setLoading(true);
                setFireUser(fireUserData);
                Sentry.setUser({
                    email: fireUserData.email,
                });
                try {
                    const { isAdmin, companies: userCompanies } = await fetchUserData();
                    const storageKey = getStorageKey(fireUserData.uid);
                    const persistedSavedCompany = localStorage.getItem(storageKey);
                    const hasPersistedCompany = userCompanies.map(c => c.id).includes(persistedSavedCompany);
                    const companyId = hasPersistedCompany ? persistedSavedCompany : userCompanies?.[0]?.id;
                    setSelectedCompany(companyId);
                    localStorage.setItem(storageKey, companyId);

                    setLoading(false);

                    const userSessionsRef = child(refUsersSessions, fireUserData.uid);

                    const fingerprint = await getIPFingerprint();
                    set(userSessionsRef, fingerprint).then(() => {
                        const unsubscribe = onValue(userSessionsRef, data => {
                            if (!isAdmin && data.val() !== fingerprint) {
                                showAuthNotification();
                            }
                        });
                        unsubscribeUser = () => {
                            unsubscribe();
                        };
                    });
                } catch (e) {
                    console.log("AppError.FirebaseConnectionError");
                    console.error(e);
                    setAppError(AppError.FirebaseConnectionError);
                }
            } else {
                reset();
                unsubscribeUser();
            }
        });
        // return () => reset();
    }, [client, fetchUserData]);

    const isAuthenticated = Boolean(fireUser) && !isLoading;
    const value: Base.UserInterface = useMemo(
        () => ({
            isAuthenticated,
            isLoading,
            fireUser,
            selectedCompany,
            ...claims,
            ...userData,
            isUserDataLoaded: !isLoading && isAuthenticated && Boolean(fireUser),
        }),
        [isAuthenticated, isLoading, fireUser, claims, selectedCompany, userData]
    );

    const controller: Controller = useMemo(
        () => ({
            fetchUserData,
            onSelectCompany: id => {
                setSelectedCompany(id);
                localStorage.setItem(getStorageKey(fireUser?.uid), id);
                return Promise.resolve();
            },
            savePrivateToken: async token => {
                await Mutator.mutate<"userToggle2fa", GQL.IUserToggle2faInput>({
                    mutation: userToggle2fa,
                    input: {
                        enable: true,
                        token,
                    },
                });
                setUserData({ is2faEnabled: true });
            },
            removePrivateToken: async () => {
                await Mutator.mutate<"userToggle2fa", GQL.IUserToggle2faInput>({
                    mutation: userToggle2fa,
                    input: {
                        enable: false,
                    },
                });
                setUserData({ is2faEnabled: false });
            },
            getPrivateToken: async () => {
                const privateToken = await client.query<Pick<GQL.IQuery, "privateToken">>({
                    query: getPrivateToken,
                    fetchPolicy: "network-only",
                });
                if (privateToken.data) {
                    return privateToken.data.privateToken.token;
                }
                return null;
            },
        }),
        [fireUser, Mutator, client, fetchUserData]
    );

    return (
        <UserContext.Provider value={value}>
            <UserControlContext.Provider value={controller}>
                <UserCompaniesContext.Provider value={companies}>{props.children}</UserCompaniesContext.Provider>
            </UserControlContext.Provider>
        </UserContext.Provider>
    );
};
